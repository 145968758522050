import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ContactExpert extends Component {
    render() {
        if(this.props.isSmall){
            return (
                <div className='contact-expert' style={{width:'100%',textAlign:'center', padding:'10px 20px',backgroundColor:'white',border:'solid 2px gainsboro'}}>
                    <div>
                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjU2IDI1NiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjU2IDI1NiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8bWV0YWRhdGE+IFN2ZyBWZWN0b3IgSWNvbnMgOiBodHRwOi8vd3d3Lm9ubGluZXdlYmZvbnRzLmNvbS9pY29uIDwvbWV0YWRhdGE+DQo8Zz48Zz48Zz48cGF0aCBmaWxsPSIjMDAwMDAwIiBkPSJNMTI4LDI0LjhjNTYuOSwwLDEwMy4zLDQ2LjMsMTAzLjMsMTAzLjNjMCw1Ni45LTQ2LjMsMTAzLjItMTAzLjMsMTAzLjJjLTU2LjksMC0xMDMuMi00Ni4zLTEwMy4yLTEwMy4zQzI0LjgsNzEuMSw3MS4xLDI0LjgsMTI4LDI0LjggTTEyOCwxMEM2Mi44LDEwLDEwLDYyLjgsMTAsMTI4YzAsNjUuMiw1Mi44LDExOCwxMTgsMTE4YzY1LjIsMCwxMTgtNTIuOCwxMTgtMTE4QzI0Niw2Mi44LDE5My4yLDEwLDEyOCwxMEwxMjgsMTB6IE0xOTMuOCwxNjcuNGwtMC43LTJjLTEuNi00LjYtNi43LTkuNS0xMS40LTEwLjhsLTE3LjQtNC44Yy00LjctMS4zLTExLjUsMC41LTE0LjksMy45bC02LjMsNi4zYy0yMi45LTYuMi00MC45LTI0LjItNDcuMS00Ny4xbDYuMy02LjNjMy41LTMuNSw1LjItMTAuMiwzLjktMTQuOWwtNC43LTE3LjVjLTEuMy00LjctNi4xLTkuOC0xMC44LTExLjRsLTItMC43Yy00LjYtMS42LTExLjMsMC0xNC44LDMuNWwtOS40LDkuNGMtMS43LDEuNy0yLjgsNi41LTIuOCw2LjVjLTAuMywzMCwxMS40LDU4LjksMzIuNiw4MC4xYzIxLjEsMjEuMSw0OS45LDMyLjksNzkuOCwzMi42YzAuMiwwLDUuMS0xLjEsNi44LTIuN2w5LjQtOS40QzE5My44LDE3OC43LDE5NS4zLDE3Mi4xLDE5My44LDE2Ny40eiIvPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L2c+PC9nPg0KPC9zdmc+" width="32" height="32">
                         </img>
                        <h4 style={{margin:'0px'}}>Kontakt mit einem Experten</h4>
                    </div>
                    <br/>
                    <div style={{display:'flex'}}>
                        <Link>info@medcomply.ch</Link>
                        <div style={{width:'20px'}}></div>
                        <Link>+41 44 521 51 30</Link>
                        <br/>
                    </div>
                    <br/>
                </div>
            );
        }else{
            return (
                <div className='contact-expert' style={{width:this.props.fullWidth?'100%':'200px',borderRadius:this.props.borderRadius!=null?this.props.borderRadius:'', textAlign:'center', padding:'30px 20px',backgroundColor:'white',border:'solid 2px gainsboro'}}>
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjU2IDI1NiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjU2IDI1NiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8bWV0YWRhdGE+IFN2ZyBWZWN0b3IgSWNvbnMgOiBodHRwOi8vd3d3Lm9ubGluZXdlYmZvbnRzLmNvbS9pY29uIDwvbWV0YWRhdGE+DQo8Zz48Zz48Zz48cGF0aCBmaWxsPSIjMDAwMDAwIiBkPSJNMTI4LDI0LjhjNTYuOSwwLDEwMy4zLDQ2LjMsMTAzLjMsMTAzLjNjMCw1Ni45LTQ2LjMsMTAzLjItMTAzLjMsMTAzLjJjLTU2LjksMC0xMDMuMi00Ni4zLTEwMy4yLTEwMy4zQzI0LjgsNzEuMSw3MS4xLDI0LjgsMTI4LDI0LjggTTEyOCwxMEM2Mi44LDEwLDEwLDYyLjgsMTAsMTI4YzAsNjUuMiw1Mi44LDExOCwxMTgsMTE4YzY1LjIsMCwxMTgtNTIuOCwxMTgtMTE4QzI0Niw2Mi44LDE5My4yLDEwLDEyOCwxMEwxMjgsMTB6IE0xOTMuOCwxNjcuNGwtMC43LTJjLTEuNi00LjYtNi43LTkuNS0xMS40LTEwLjhsLTE3LjQtNC44Yy00LjctMS4zLTExLjUsMC41LTE0LjksMy45bC02LjMsNi4zYy0yMi45LTYuMi00MC45LTI0LjItNDcuMS00Ny4xbDYuMy02LjNjMy41LTMuNSw1LjItMTAuMiwzLjktMTQuOWwtNC43LTE3LjVjLTEuMy00LjctNi4xLTkuOC0xMC44LTExLjRsLTItMC43Yy00LjYtMS42LTExLjMsMC0xNC44LDMuNWwtOS40LDkuNGMtMS43LDEuNy0yLjgsNi41LTIuOCw2LjVjLTAuMywzMCwxMS40LDU4LjksMzIuNiw4MC4xYzIxLjEsMjEuMSw0OS45LDMyLjksNzkuOCwzMi42YzAuMiwwLDUuMS0xLjEsNi44LTIuN2w5LjQtOS40QzE5My44LDE3OC43LDE5NS4zLDE3Mi4xLDE5My44LDE2Ny40eiIvPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L2c+PC9nPg0KPC9zdmc+" width="64" height="64">
                     </img>
                    <h4>Kontakt mit einem Experten</h4>
                    <br/><br/><br/>
                    <Link>info@medcomply.ch</Link>
                    <br/><br/><br/>
                    <Link>+41 44 521 51 30</Link>
                    <br/><br/>
                    <br/>
                </div>
            );
        }
       
    }
}

export default ContactExpert;