import React from 'react'
import './App.css'
import history from './history'
import { LABELS } from './Labels';
import {Helmet} from 'react-helmet';
import { getAuth } from "firebase/auth"
import AuthRouter from './AuthRouter.jsx'
import MasterDataPage from './pages/MasterDataPage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import LoginBypass from './pages/LoginBypass'
import AGBTerms from './pages/readfiles/AGBTerms'
import DSTerms from './pages/readfiles/DSTerms'
import {USERS_TABLE} from './firebase/constants'
import CacheService from './service/CacheService'
import StripeService from './firebase/StripeService'
import DashboardPage from './main/DashboardPage'
import LandingPage from './pages/LandingPage'
import { getDatabase, ref, get, child} from "firebase/database"
import { Routes, Route } from "react-router-dom"
import ChangeEmailPage from './pages/ChangeEmailPage'
import Header from './components/Header'
import ModalController, {MODAL} from './main/ModalController'
import { EmailActionPageWrapper } from './pages/loginsignup/EmailActionPageWrapper'
import DatacheckerUtil from './util/DatacheckerUtil';
// import {PaymentInfoPageWrapper} from './pages/payment/PaymentInfoPageWrapper';
import {SubscriptionInfoPageWrapper} from './pages/subscription/SubscriptionInfoPageWrapper';

const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';
 
const dbRef = ref(getDatabase());
const auth = getAuth();

class App extends React.Component {

  constructor(props) {
    super(props);
    let cache = CacheService.getSession();
    this.state = {
       user:null
      ,onAuth:true
      ,user:cache!=null?cache.user:null
      ,modal:null
      ,browserTitle:'Medcomply | Compliance with Ease'
      ,stripeObject:{}
    }

    this.authenticateUser(); 
  }


  async componentDidMount(){
 
  } 

  async authenticateUser(){
    try{ 
      this.authenticateWithFirebase();
    }catch(error){
        console.log('authenticateUser error',error)
        this.setState({onAuth:false});
    }
  }

  authenticateWithFirebase=async()=>{
    auth.onAuthStateChanged((firebaseUser)=>{
      if (firebaseUser!=null && firebaseUser.emailVerified) {
          this.getFirebaseUserDetails(firebaseUser);
      }else{
        this.setState({onAuth:false});
      }
    });
  }

  getFirebaseUserDetails = async (firebaseUser) => {
    get(child(dbRef, USERS_TABLE + '/' + firebaseUser.uid)).then((snapshot)=>{
      if (snapshot.exists()) {
          let user = snapshot.val();
          this.setSessionUser(firebaseUser,user);
          this.setState({firebaseUser:firebaseUser, user:user, onAuth:false, onCheckSubscription:true},
            ()=>StripeService.getStripeObject(auth.currentUser.uid,this.checkSubCallback)
          );
      }
    });
  }

  reloadFirebaseUser = async (callback) => {
    get(child(dbRef, USERS_TABLE + '/' + this.state.firebaseUser.uid)).then((snapshot)=>{
      if (snapshot.exists()) {
          let user = snapshot.val();
          this.setState({user:user},callback);
      }
    });
  }

  checkSubCallback=async(stripeObject)=>{ 
    if(stripeObject.subscription!=null && stripeObject.subscription.status === 'active') {
      await StripeService.getSubscription(stripeObject.subscription.items[0].subscription).then(res=>{
        stripeObject.fullSubscriptionObject = res.data
        this.setState({onCheckSubscription:false, stripeObject:stripeObject})
      })
    }else{
      this.setState({onCheckSubscription:false, stripeObject:stripeObject})
    }
  }

  setSessionUser=(firebaseUser,user)=>{
    CacheService.storeSession(user, ()=>{
        this.setState({firebaseUser:firebaseUser, storedSession:CacheService.getSession()},()=>{
              this.setState({onAuth:false});
        });
    });
  }

  isLoading=()=>{
    return this.state.onAuth || this.state.onCheckSubscription
  }

  setModal=(modal)=>{
    this.setState({modal:modal})
  }
  
  setStripeObject=(sObj)=>{
    this.setState({stripeObject:sObj})
  }

  setBrowserTitle=(browserTitle)=>{
    this.setState({browserTitle:browserTitle})
  }

  render(){
    let isLoading = this.isLoading()
    let language = 'de';

    let exchange = {
       user:this.state.user
      ,stripeObject:this.state.stripeObject
      ,reloadFirebaseUser:this.reloadFirebaseUser
      ,setModal:this.setModal
      ,removeModal:()=>{this.setState({modal:null,browserTitle:'Medcomply | Compliance with Ease'})}
      ,currentModal:this.state.modal
      ,setBrowserTitle: this.setBrowserTitle
      ,setStripeObject: this.setStripeObject
    }
 
    let isAuthenticated = this.state.user !=null;
    if(isAuthenticated){
      if(this.state.user.settings!=null && this.state.user.settings.lang!=null){
        language = this.state.user.settings.lang
        exchange.language = language
      }
      exchange.data =
      {
        isComplete: DatacheckerUtil.isDataComplete(this.state.user)
      }
    }
    exchange.language = 'de'
    let browserTitle = 'Medcomply | Compliance with Ease';
    switch(this.state.modal){
      case MODAL.LOGIN: browserTitle = LABELS.LOGIN[language] + ' | Medcomply'; break;
      case MODAL.REGISTER: browserTitle = LABELS.REGISTER[language] + ' | Medcomply'; break;
      case MODAL.FORGOT_PASSWORD: browserTitle = LABELS.FORGOT_PASS[language] + ' | Medcomply'; break;
      default: browserTitle = this.state.browserTitle; break;
    }

    return (this.state.onAuth===false&&
      <div className="app dp-doc-web" style={isLoading?{}:{}}>
        {ENV!=='prod'&&
        <div style={{height:'30px',width:'100%',backgroundColor:'rgb(0, 163, 204)',textAlign:'center',padding:'4px',color:'white'}}>
          {'You are using the development version'}
        </div>}
          <Helmet>
            <title>{browserTitle}</title>
            <style>{'body { background-color:  rgb(240,242,245); }'}</style>
          </Helmet>
        <Header exchange={exchange}  />
        <ModalController modal={this.state.modal} exchange={exchange} />
        <Routes history={history}>
            <Route path='/' element={isAuthenticated?
                 <DashboardPage exchange={exchange}/>
                :<LandingPage exchange={exchange}/>
              }/>
            
            <Route path='/terms/agb' element={<AGBTerms/>}/>
            <Route path='/terms/dse' element={<DSTerms/>}/>
            <Route path='/admin/update' element={<MasterDataPage exchange={exchange} isFullScreen={true}/>}/>
            <Route path='/admin/change-password' element={<ChangePasswordPage exchange={exchange}/>}/>
            {/* <Route path='/62d086cc-90ca-4fd1-b050-9c830a8f2a8c' element={<LoginBypass exchange={exchange}/>}/> */}
            {/* <Route path='/admin/change-email' element={<ChangeEmailPage exchange={exchange}/>}/> */}
            {/* <Route path='/admin/pay-info/:par1?/:par2?' element={<PaymentInfoPageWrapper exchange={exchange}/>}/> */}
            <Route path='/admin/subscription-info/:par1?/:par2?' element={<SubscriptionInfoPageWrapper exchange={exchange}/>}/>
            <Route path='/email-action' element={<EmailActionPageWrapper exchange={exchange}/>}/>
        </Routes>
        <AuthRouter exchange={exchange}/>
      </div>
    )
  }
}

export default App;