import React, { Component } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import DashboardPage from './main/DashboardPage.jsx';
import FirstPaymentPage from './main/FirstPaymentPage.jsx';
import DocumentQuestionaire from './main/document-generator/DocumentQuestionaire';
import { DocQueWrapper } from './main/document-generator/DocQueWrapper';

class AuthRouter extends Component {
    render() {

        let exchange = this.props.exchange;
        let isAuthenticated = exchange.user !=null;
   
          
        const PrivateRoute = ({ children }) => {
            return isAuthenticated ? children : <Navigate to="/" />;
          };

        return (
            <Routes>
                <Route path='/dashboard' element={<PrivateRoute><DashboardPage exchange={exchange}/></PrivateRoute>}/>
                <Route path='/pay' element={<PrivateRoute><FirstPaymentPage exchange={exchange}/></PrivateRoute>}/>
                <Route path='/data-collection/:par1/:par2?/:par3?' element={<PrivateRoute><DocQueWrapper exchange={exchange}/></PrivateRoute>}/>
            </Routes>
        );
    }
}

export default AuthRouter;