const SESSION_KEY = "_SESSION";
class CacheService {

    storeSession = (user, callback) => {
        let session_obj = {
             user: user
        };
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }
    
    removeSession = () => {
        this.storeSession(null,null)
    }


    getSession = () => {
        let storedSessionRaw = localStorage.getItem(SESSION_KEY);
        let storedSession = null;
        if(storedSessionRaw!=null){
            storedSession = JSON.parse(storedSessionRaw);
        }
        return storedSession;
    }
    
}
export default new CacheService()