import React, { Component } from 'react';
import { MODAL } from '../../main/ModalController';
import { LABELS } from '../../Labels';

class VerificationEmailSent extends Component {

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='verf-email-div' style={{width:'400px',backgroundColor:'white',padding:'28px 28px'}}>
                <br/>
                <div style={{textAlign:'center'}}>
                    <h2>{LABELS.VERIF_EMAIL[lang]}</h2>
                    <p>{LABELS.WE_SENT_VERIF[lang]}</p>
                </div>
                <br/>
                <button className='btn1' 
                    onClick={()=>this.props.exchange.setModal(MODAL.LOGIN)}>
                    {LABELS.GO_TO_LOGIN[lang]}
                </button>
            </div>
        );
    }
}

export default VerificationEmailSent;