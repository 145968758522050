import React, { Component } from 'react';
import { LABELS } from '../../Labels';
import StripeService from '../../firebase/StripeService.js';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import moment from "moment";
import ErrorIcon from '@mui/icons-material/Error';
import landingbg from '../../assets/landingbg.png'

import { getAuth } from "firebase/auth"; 
const auth = getAuth()
class SubscriptionInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
             onLoad:true
            , paymentMethods:[]
        }
    }

    componentDidMount() {
        console.log('hey', this.props.exchange)
        if(this.props.exchange.stripeObject!=null && this.props.exchange.stripeObject.stripeId!=null) {
           this.loadPayments(this.props.exchange.stripeObject.stripeId)
        }else{
            StripeService.getStripeObject(this.props.exchange.user.uuid, (sObj)=>{
                console.log('sObj', sObj)
                this.props.exchange.setStripeObject(sObj);
                this.loadPayments(sObj.stripeId)
            })
            // this.setState({onLoad:false})
        }
    }

    loadPayments=(stripeId)=>{
        StripeService.retrieveAllCustomerPaymentMethod(stripeId).then(res=> {
            if(res.status===200 && res.data.data!=null) {
                this.setState({onLoad:false, paymentMethods:res.data.data})
            } else {
                this.setState({onLoad:false})
            }
        })
        .catch(err=>{
            this.setState({onLoad:false})
        })
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } 

    cancelSubscriptionClicked=async (subscriptionId)=>{
        if(this.state.onCancelClick){
            return null
        }
        this.setState({onCancelClick: true},()=>{
            StripeService.cancelSubscription(this.props.exchange.stripeObject,subscriptionId ).then(res=>{
                console.log('res.data', res.data)
                console.log('res', res.data.url)
                window.location.assign(res.data.url);
            })
        });
    }

    showSubscriptionStatus=(lang)=>{
        let subscription = this.props.exchange.stripeObject.subscription;
        console.log('subscription.items[0]', subscription.items[0])
        let content = ( 
            <div>
                <p style={{fontWeight:'300',fontSize:'0.8rem',marginBottom:'4px'}}>{LABELS.SUBSCRIPTION[lang]}</p>
               
                <div style={{display:'flex',alignItems:'center'}}>
                    <ErrorIcon/><p style={{marginLeft:'4px'}}>{LABELS.YOU_HAVE_NO_ACTIVE_SUBS[lang]}</p>
                </div>
                
                <br/>
                <Link className='btn1 red-btn1' to='/pay' style={{ width:'86vw',maxWidth:'470px'}}>{LABELS.CONT_PAY_OP[lang]}</Link>
            </div>);
            console.log('subscription', subscription)
        if(subscription!=null){
            content = (
            <div style={{textAlign: 'left'}}>
                <h3>{subscription.items[0].price.product.name}</h3>
                <div style={{fontSize:'0.8rem'}}>
                    <p>{LABELS.SUBSCRIPTION_PERIOD[lang] + ': '
                    + moment(subscription.current_period_start.seconds*1000).format('DD.MM.YYYY') + ' ' + LABELS.DATE_TO[lang]+ ' '
                    + moment(subscription.current_period_end.seconds*1000).format('DD.MM.YYYY')}</p>
                    <p>{LABELS.PLAN[lang] + ': ' + this.getPlanLabel(subscription.items[0].plan.interval,lang)}</p>
                    <p>{LABELS.AMOUNT[lang] + ': ' + subscription.items[0].plan.amount_decimal/100 + ' ' + subscription.items[0].plan.currency.toUpperCase()}</p>

                    {subscription.canceled_at&&
                    <p style={{color:'red',marginTop:'8px'}}>{LABELS.SUBS_CANCELLED_NA[lang] }</p>}
                </div>
              
                {!subscription.canceled_at?
                <button style={{marginTop:'12px'}} className='btn1' onClick={()=>this.cancelSubscriptionClicked(subscription.items[0].canceled_at)}>
                        {this.state.onCancelClick?
                        <CircularProgress sx={{color:'white'}}/>:
                        <p>{LABELS.CANCEL_SUBSCRIPTION[lang]}</p>}
                </button>:
                <button style={{marginTop:'12px'}} className='btn1' onClick={()=>this.renewSubscriptionClicked(lang)}>
                        {this.state.onCancelClick?
                        <CircularProgress sx={{color:'white'}}/>:
                        <p>{LABELS.RENEW_SUBS[lang]}</p>}
                </button>
                }
                
            </div>)
        } else if(typeof subscription==='undefined'){
            content = (
                <div style={{display:'flex',justifyContent:'center'}}>
                    <CircularProgress size={'26px'} sx={{color:'gray'}}/>
                </div>
            )
        }
        
        return(
            <div className={isMobile?'page-box-1-mobile':'page-box-1'} style={{borderRadius:'10px',paddingLeft:'1.1em',backgroundImage:'url('+landingbg+')'
                                               ,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
             {content}
            </div>)
    }

    renewSubscriptionClicked=async (lang)=>{
        if(this.state.onRenew || this.state.onCancelClick){
            return null
        }
        this.setState({onRenew: true},()=>{
            StripeService.createCustomerPortal(this.props.exchange.stripeObject, null,  window.location.origin+'/admin/subscription-info', lang).then(res=>{
                console.log('res.data', res.data)
                console.log('res', res.data.url)
                window.location.assign(res.data.url);
            })
        });
    }
    
    getPlanLabel=(plan,lang)=>{
        switch(plan){
            case 'year':
                if(lang==='de') {
                    return 'Jährlich'
                }else {
                    return 'Yearly'
                }
            default: return ''
        }
    }

    
    renderContent=(lang)=>{
        let content = null
        switch(this.props.pageId){
            case 'c': 
            default:
                content = (
                    <div style={{textAlign:'center',width:'100%', maxWidth:'700px', paddingBottom:'10px'}}>
                        <h2>{LABELS.UPDATE_SUBSCRIPTION_INFO[lang]}</h2>
                        <br/> 
                         {this.state.onLoad?
                            <CircularProgress sx={{color:'black'}}/>:
                            this.showSubscriptionStatus(lang)}
                    </div>
                )
        }
        return content;
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='payinfo-page page' style={{justifyContent:'center',width:'100%', pointerEvents:this.state.onLoad?'none':'',paddingBottom:'20px'}}>
                <div className='page-content page-box-1' style={{display:'flex',justifyContent:'center'}}>
                  {this.renderContent(lang)}
                
                </div>
            </div>
        );
    }
}

export default SubscriptionInfo;