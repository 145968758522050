import React, { Component } from 'react';

import { applyActionCode, getAuth } from "firebase/auth"
import { MODAL } from '../../main/ModalController';
const auth = getAuth();
class FirebaseEmailAction extends Component {
    
     componentDidMount(){
        // make sure this is the custom action URL in firebase aut: https://develop.d21ivfw2w1vl6r.amplifyapp.com/email-action
        switch(this.props.mode){
            case 'verifyEmail': this.verifyEmail(); break;
            default: break;
        }
    }

    verifyEmail=()=>{
        window.history.replaceState(null, "Login", "/")
        this.props.exchange.setModal(MODAL.LOGIN)
        applyActionCode(auth, this.props.oobCode).then((res)=>{
           this.props.exchange.setModal(MODAL.LOGIN)
        }).catch((error) => {
            console.log('FEA0001 Error: ', error)
        });
    }
    

    renderBody=()=>{

        switch(this.props.mode){
            case 'verifyEmail':
                return (
                    <div style={{textAlign:'center'}}>
                        <br/>
                        <h3>Verifying email...</h3>
                        <br/>
                    </div>
                ) 
            default: break;
        }
    }


    render() {
        ///http://localhost:3000/?mode=verifyEmail&oobCode=SGsNKzfYvpP00fUOxkVXbTcXYZ2aVMGYhiYHTWtg_T0AAAGMuoiVFQ&apiKey=AIzaSyAMGJQYVBbTA9xrga7oVnuWpYUc0KTaitg&lang=en
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='email-action-div' style={{width:'400px',backgroundColor:'white',padding:'28px 28px'}}>
               {this.renderBody()}
            </div>
        );
    }
}

export default FirebaseEmailAction;