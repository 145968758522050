import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import history from '../history';
import {isMobile} from 'react-device-detect';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment";
import './Dashboard.css';
import { LABELS } from '../Labels';
import { MODAL } from './ModalController';
import Tooltip from '@mui/material/Tooltip';
import landingbg from '../assets/landingbg.png'
import ContactExpert from '../components/ContactExpert.jsx';
import { CircularProgress } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import ErrorIcon from '@mui/icons-material/Error';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StripeService from '../firebase/StripeService.js';
import HtmlToDoc from '../util/HtmlToDoc.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { datenschutzerklärungPatient, datenschutzerklärungWebsite } from './document-generator/pdf_generator';
import DocQuestionaireService from './document-generator/DocQuestionaireService';
class DashboardPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onLoadDatenschutzerklarung:false
            ,onLoadPatientDatenschutz:false
            ,width:window.innerWidth
            ,height:window.innerHeight
            ,scrollY:0
        }
    }
    componentDidMount(){ 
        let lang = this.props.exchange.language || 'de';
        this.props.exchange.setBrowserTitle('Dashboard | Medcomply');
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.exchange.user.lastUpdated==null && this.props.exchange.currentModal==null){
            this.props.exchange.setModal(MODAL.SET_INITIAL_INFO);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }
    updateDimensions = () => {
         this.setState({width:window.innerWidth, height:window.innerHeight});
    }
    handleScroll = () => {
        const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
    
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
    
      const scrolled = winScroll / height
    
      this.setState({scrollY: winScroll })
    }
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    getPatientWordFile=async()=>{
        HtmlToDoc.convert(this.props.exchange.user);
    }

    showSubscriptionStatus=(lang)=>{
        let subscription = this.props.exchange.stripeObject.subscription;
        let fullSubscription = this.props.exchange.stripeObject.fullSubscriptionObject;
        
        let content = ( 
            <div>
                <p style={{fontWeight:'300',fontSize:'0.8rem',marginBottom:'4px'}}>{LABELS.SUBSCRIPTION[lang]}</p>
               
                <div style={{display:'flex',alignItems:'center'}}>
                    <ErrorIcon/><p style={{marginLeft:'4px'}}>{LABELS.YOU_HAVE_NO_ACTIVE_SUBS[lang]}</p>
                </div>
                
                <br/>
                <Link className='btn1 red-btn1' to='/pay' style={{ width:'86vw',maxWidth:'470px'}}>{LABELS.CONT_PAY_OP[lang]}</Link>
            </div>);
            
        if(subscription!=null){
            content = (
            <div>
                <p style={{fontWeight:'300',fontSize:'0.8rem',marginBottom:'4px'}}>{LABELS.SUBSCRIPTION[lang]}</p>
                <h3>{subscription.items[0].price.product.name}</h3>
                <div style={{fontSize:'0.8rem'}}>
                    <p>{LABELS.SUBSCRIPTION_PERIOD[lang] + ': '
                    + moment(fullSubscription.current_period_start*1000).format('DD.MM.YYYY') + ' ' + LABELS.DATE_TO[lang]+ ' '
                    + moment(fullSubscription.current_period_end*1000).format('DD.MM.YYYY')}</p>
                    <p style={{fontSize:'0.7rem'}}>
                        {'(Ihr Abonnement wird am '+ moment(fullSubscription.current_period_end*1000).format('DD.MM.YYYY')+' verlängert)'}
                    </p>
                </div>
            </div>)
        } else if(typeof subscription==='undefined'){
            content = (
                <div style={{display:'flex',justifyContent:'center'}}>
                    <CircularProgress size={'26px'} sx={{color:'gray'}}/>
                </div>
            )
        }
        
        let isSmall = isMobile || this.state.width<750;
        return(
            <div className={isSmall?'page-box-1-mobile':'page-box-1'} style={{borderRadius:'10px',paddingLeft:'1.1em',backgroundImage:'url('+landingbg+')'
                                               ,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
             {content}
            </div>)
    }

    downloadDatenschutzerklarung=async()=>{
        this.setState({onLoadDatenschutzerklarung:true},()=>{
            let docData = {
                id:'doc-0'
            }
            let inputs = []
            DocQuestionaireService.getQuestionaireAnswer(this.props.exchange,docData).then(snapshot=>{
                if(snapshot.exists()){
                    let answers = snapshot.val().answers;
                    for (var key in answers) {
                        if (answers.hasOwnProperty(key)) {
                            inputs[key] = answers[key];
                        }
                    }
                    let docuProps={
                        user:this.props.exchange.user,
                        inputs:inputs
                    }
                    datenschutzerklärungWebsite(docuProps,()=>{
                    this.setState({onLoadDatenschutzerklarung:false})
                   })
                  
                }else{
                    // this.setState({onLoadDatenschutzerklarung:false},()=>{
                    //     history.push('/data-collection/doc-0')
                    //     history.go()
                    // })

                    let docuProps={
                        user:this.props.exchange.user,
                        inputs:[]
                    }
                    datenschutzerklärungWebsite(docuProps,()=>{
                    this.setState({onLoadDatenschutzerklarung:false})
                   })
                }
            })
        })
    }
    downloadPatientDatenschutz=async()=>{
        console.log('downloadPatientDatenschutz')
        this.setState({onLoadPatientDatenschutz:true},()=>{
            let docData = {
                id:'doc-0'
            }
            let inputs = []
            DocQuestionaireService.getQuestionaireAnswer(this.props.exchange,docData).then(snapshot=>{
                if(snapshot.exists()){
                    let answers = snapshot.val().answers;
                    for (var key in answers) {
                        if (answers.hasOwnProperty(key)) {
                            inputs[key] = answers[key];
                        }
                    }
                    let docuProps={
                        user:this.props.exchange.user,
                        inputs:inputs
                    }
                    datenschutzerklärungPatient(docuProps,()=>{
                    this.setState({onLoadPatientDatenschutz:false})
                   })
                  
                }else{
                    // this.setState({onLoadPatientDatenschutz:false},()=>{
                    //     history.push('/data-collection/doc-0')
                    //     history.go()
                    // })

                    let docuProps={
                        user:this.props.exchange.user,
                        inputs:[]
                    }
                    datenschutzerklärungPatient(docuProps,()=>{
                    this.setState({onLoadPatientDatenschutz:false})
                   })
                }
            })
        })
    }

    cancelSubsciptionClicked=async()=>{
        this.props.exchange.setModal(MODAL.CANCEL_SUBSCRIPTION);
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        let hasSubscription = this.props.exchange.stripeObject.subscription != null
        let DashboardAction = (props) =>{
            let ssstyle=props.style;
            if(props.locked){
                ssstyle={cursor:'not-allowed',...ssstyle, backgroundColor:'rgba(0,0,0,0.05)',opacity:'0.8', pointerEvents:'none', pointer:'',cursor:'not-allowed'}
            }
            if(isMobile){
                ssstyle={...ssstyle,height:'fit-content' }
            }
            if(props.noPointer){
                ssstyle={...ssstyle,cursor:'default'}
            }
            let isSmall = isMobile || this.state.width<750;
            return (
                <Link className={isSmall?'dashobard-action-m':'dashobard-action'} to={props.to} style={ssstyle} tabIndex={-1} onClick={props.onClick}>
                    <div style={{height:'100px'}}>
                        {isSmall?
                            <h3>{props.title}</h3>:
                            <h2>{props.title}</h2>}
                        <p style={{marginTop:'8px',fontSize:isMobile?'0.75rem':'1rem'}}>{props.subtitle}</p>
                    </div>
                    <div style={{display:'flex',flexDirection:'column-reverse',height:'120px'}}>
             
                        <div style={{display:'flex',justifyContent: 'flex-end'}}>
                            {props.subinfo}
                        </div>
                    </div>
                </Link>
            )
        }

        let isSmall = isMobile || this.state.width<750;
        return (
            <div className='dashboard-page page'>
                <br/>
                <div style={{display:this.state.width>754?'flex':''}}>
                    <div className='dcontent-wrap '>
                        {this.showSubscriptionStatus(lang)} <br/>
                        <div className={isSmall?'dashboard-content page-box-1-mobile':'dashboard-content page-box-1'}>
                            <div className='dashboard-section'>
                                <h2>{LABELS.WELCOME[lang]}</h2><br/>
                                { hasSubscription&&!this.props.exchange.data.isComplete&&
                                <div style={{display:'flex',alignItems:'center',backgroundColor:'rgb(247,247,247)',width:'fit-content',padding:'4px 8px',borderRadius:'8px'}}>
                                    <Tooltip title={LABELS.DATA_COL_INCOMPLETE[lang]}>
                                        <ErrorIcon/>
                                    </Tooltip>
                                    <p style={{marginLeft:'6px'}}>
                                        <span>{LABELS.DATA_COL_INCOMPLETE[lang]}</span>
                                        <Link to='/admin/update'>{LABELS.UPDATE_NOW[lang]}</Link>
                                    </p>
                                </div>}
                                <DashboardAction 
                                    title={LABELS.DATA_COLLECTION[lang]} 
                                    subtitle={LABELS.CHOOSE_DATA_MSG[lang]}
                                    locked={!hasSubscription || !this.props.exchange.data.isComplete || typeof this.props.exchange.stripeObject.subscription==='undefined'}
                                    style={this.props.exchange.data.isComplete?{}:{cursor:'not-allowed',pointerEvents:'none'}}
                                    subinfo={hasSubscription?
                                                <div>
                                                    {this.props.exchange.data.isComplete?
                                                    null
                                                    : <BlockIcon sx={{color:'gray'}}/>}
                                                </div>
                                                :(typeof this.props.exchange.stripeObject.subscription==='undefined')?
                                                    <CircularProgress size={'26px'} sx={{color:'gray'}}/>:
                                                    <p style={{color:'red',textAlign:'left'}}>{LABELS.NO_ACTIVE_SUBS[lang]}</p>
                                                }
                                    to={'/data-collection/doc-0'}
                                    />
                            </div>
                        
                            <br/><br/>
                            <div className='dashboard-section'>
                            {/* style={hasSubscription?{}:{backgroundColor:'rgb(247,247,247)',borderRadius:'8px',padding:'12px',opacity:'0.7'}}> */}
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <h2 style={{marginRight:'6px',minWidth:'fit-content'}}>{LABELS.DOCUMENT_DOWNLOAD[lang]}</h2>
                                    {/* <div style={{width:'100%', display:'flex',justifyContent:'flex-end'}}>
                                        <div className='btn-5' onClick={()=>{
                                            history.push('/data-collection/doc-0')
                                            history.go()
                                        }}>
                                            <ModeEditIcon/>
                                            <p style={{fontSize:'0.9rem',marginLeft:'5px',marginTop:'3px'}}>Update Questionaire</p>
                                        </div>
                                    </div> */}
                                    {!hasSubscription && 
                                        <div>
                                            <Tooltip title={LABELS.NO_ACTIVE_SUBS[lang]+", " + LABELS.PAY_NOW[lang]}>
                                                <ErrorIcon/>
                                            </Tooltip>
                                        </div>}
                                    {hasSubscription&&!this.props.exchange.data.isComplete&&
                                        <div>
                                            <Tooltip title={LABELS.DATA_COL_INCOMPLETE[lang]}>
                                                <ErrorIcon/>
                                            </Tooltip>
                                        </div>}
                                </div>

                                <br/>
                                <div className='dsection-options'>
                                    <DashboardAction 
                                        title={LABELS.PATIENT_FORM[lang]}
                                        subtitle={'Mit dem Patientenformular erhalten Sie die essentiellen Informationen für die Eröffnung der Patientenkartei, und die Zustimmung zu den Datenbearbeitungen gemäss der Datenschutzerklärung Patient.\n\n'}
                                        style={{height:'260px',minWidth:'30%'}}
                                        noPointer={true}
                                        locked={!this.props.exchange.data.isComplete}
                                        subinfo={
                                            <div style={{display:'flex', justifyContent:'right',justifyContent:'right',width:'100%'}}>
                                                {hasSubscription?null:
                                                    <div style={{ width:'100%', display:'flex', alignItems:'center'}}>
                                                        <p style={{color:'red',marginTop:'4px'}}>{LABELS.NO_ACTIVE_SUBS[lang]}</p>
                                                    </div>
                                                }
                                                <div className='px-r5' style={{ width:'100%',display:'flex',height:'100%', justifyContent:'right'}}> 
                                                    <div className='zzzz-r5'  className='btn1 dashboard-btn' onClick={hasSubscription?this.getPatientWordFile:null}>
                                                    {this.state.onLoadPatientDatenschutz?
                                                        <CircularProgress sx={{color:'white'}} size={'26px'}/>
                                                        :LABELS.DOWNLOAD[lang]}
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        />
                                    <DashboardAction 
                                        title={LABELS.DATA_PROTECTION_PATIENTS[lang]}
                                        subtitle={'Damit informieren Sie die Patienten und Patientinnen über die Datenbearbeitungen in Ihrer Praxis.'}
                                        style={{height:'260px',minWidth:'30%'}}
                                        noPointer={true}
                                        locked={!this.props.exchange.data.isComplete}
                                        subinfo={
                                            <div style={{display:'flex', justifyContent:'center',justifyContent:'right',width:'100%'}}>
                                                {hasSubscription?null:
                                                    <div style={{ width:'100%', display:'flex', alignItems:'center'}}>
                                                        <p style={{color:'red',marginTop:'4px'}}>{LABELS.NO_ACTIVE_SUBS[lang]}</p>
                                                    </div>
                                                }
                                                <div style={{ width:'100%',display:'flex',justifyContent:'right'}}>
                                                    <div className='btn1 dashboard-btn' onClick={hasSubscription?this.downloadPatientDatenschutz:null}>
                                                    {this.state.onLoadPatientDatenschutz?
                                                        <CircularProgress sx={{color:'white'}} size={'26px'}/>
                                                        :LABELS.DOWNLOAD[lang]}
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        />
                                    <DashboardAction 
                                        title={LABELS.DATA_PROTECTION_WEBSITE[lang]}
                                        subtitle={'Damit informieren Sie die Websitebesucher über die Datenbearbeitungen, falls Sie eine Webseite betreiben.'}
                                        noPointer={true}
                                        locked={!this.props.exchange.data.isComplete}
                                        style={{height:'260px',minWidth:'30%'}}
                                        subinfo={
                                            <div style={{display:'flex', justifyContent:'center',justifyContent:'right',width:'100%'}}>
                                                {hasSubscription?null:
                                                    <div style={{ width:'100%', display:'flex', alignItems:'center'}}>
                                                        <p style={{color:'red',marginTop:'4px'}}>{LABELS.NO_ACTIVE_SUBS[lang]}</p>
                                                    </div>
                                                }
                                                <div style={{ width:'100%',display:'flex',justifyContent:'right'}}>
                                                <div className='btn1 dashboard-btn' onClick={hasSubscription?this.downloadDatenschutzerklarung:null}>
                                                    {this.state.onLoadDatenschutzerklarung?
                                                        <CircularProgress sx={{color:'white'}} size={'26px'}/>
                                                        :LABELS.DOWNLOAD[lang]}
                                                </div>
                                                </div>
                                            </div>
                                        }
                                        />
                                </div>
                                {/* {!hasSubscription && 
                                <p style={{textAlign:'right',fontSize:'0.9rem'}}>*{LABELS.NO_ACTIVE_SUBS[lang]}</p>} */}
                            </div>

                            <br/><br/>
                            <div className='dashboard-section'>
                                <h2>{LABELS.ADMINISTRATION[lang]}</h2><br/>
                                <div className='dsection-options'>
                                    <DashboardAction 
                                        title={LABELS.UPDATE_PAYMENT_SUB_INFO[lang]}
                                        subtitle={LABELS.BASE_INFO[lang]}
                                        locked={!hasSubscription}
                                        onClick={()=>{
                                            StripeService.createCustomerPortal(this.props.exchange.stripeObject, null,  window.location.origin, lang).then(res=>{                                           
                                                window.location.assign(res.data.url);
                                            })
                                        }}
                                        // subinfo={<CheckCircleIcon sx={{color:'gray'}}/>}
                                        // to='/admin/pay-info'
                                        />
                                    {/* <DashboardAction 
                                        title={LABELS.UPDATE_SUBSCRIPTION_INFO[lang]}
                                        subtitle={LABELS.SUBSCRIPTION[lang]}
                                        locked={!hasSubscription}
                                        to='/admin/subscription-info'
                                        /> */}
                                    <DashboardAction 
                                        title={LABELS.CHANGE_PASSWORD[lang]}
                                        subtitle={LABELS.BASE_INFO[lang]}
                                        // subinfo={<CheckCircleIcon sx={{color:'gray'}}/>}
                                        to='/admin/change-password'
                                        />
                                    {/* <DashboardAction 
                                        title={LABELS.EMAIL_ADRRESS_UPD[lang]} 
                                        subtitle={LABELS.BASE_INFO[lang]}
                                        subinfo={
                                            (this.props.exchange.user!=null 
                                            && this.props.exchange.user.requests!=null 
                                            && this.props.exchange.user.requests.emailChange)?
                                                <Tooltip title={LABELS.EXISTING_EMAIL_UPD_PENDING[lang]}>
                                                    <ScheduleIcon sx={{color:'gray'}}/>
                                                </Tooltip>
                                                :null
                                        }
                                        to='/admin/change-email'
                                        /> */}
                                    <DashboardAction 
                                        title={LABELS.UPDATE_INFO[lang]}
                                        subtitle={LABELS.DESC_UPD_1[lang]}
                                        subinfo={
                                            <div>{this.props.exchange.data.isComplete?null:
                                                    <Tooltip title={LABELS.DATA_COL_INCOMPLETE[lang]}>
                                                        <ErrorOutlineIcon sx={{color:'gray'}}/>
                                                    </Tooltip>}
                                            </div>}
                                        to='/admin/update'
                                        />
                                </div>
                            </div>


                                                    

                        {/* <br/> */}
                        {/* {hasSubscription&&
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Link className='cancel-sub-b'
                            style={{textDecoration:'none', color:'black'}}
                            //  onClick={this.cancelSubsciptionClicked}
                            to='/admin/subscription-info'
                             >
                            <CancelIcon style={{opacity:'0.7'}} size={10}/>
                            <p style={{fontSize:'1rem',fontWeight:'600',marginLeft:'8px',marginTop:'2px'}}>{LABELS.CANCEL_SUBSCRIPTION[lang]}</p>
                            </Link>
                        </div>} */}
                       
                        <br/>
                        
                        </div>

                    </div>
                    {this.state.width>754?
                    <div className='con-expert-wrap' style={{marginRight:'12px'}}>
                        <div style={{positions:'absolute',paddingTop:this.state.scrollY }}>
                        <ContactExpert borderRadius='16px'/>
                        </div>
                    </div>:
                    <div style={{padding:'16px',width:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
                    <ContactExpert borderRadius='16px'/>
                    </div>}
                </div>

                <br/>
            </div>
        );
    }
}

export default DashboardPage;