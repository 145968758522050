
import { TemplateHandler } from 'easy-template-x';
// import axios from 'axios'
// import Patientenformular from '../../../public/Patientenformular.docx'

class HtmlToDoc {

    convert=async(user)=>{
        console.log('1')
        var templatePatienFormular =  window.location.origin + '/Patientenformular.docx'
        console.log('1a', templatePatienFormular)
        var oReq = new XMLHttpRequest();    
        console.log('2')
        oReq.open('get', templatePatienFormular , true);
        console.log('3')
        oReq.responseType = 'blob';
        let blobby = null
        let arztpraxis = user.company.name // COMPANY NAME
        let strasse    = user.company.streetNr // street
        let plz        = user.company.zip // ZIP
        let stadt      = user.company.city // CITY
        console.log('4')
        oReq.onload = async function () {
            console.log('5a')
            var blob = oReq.response;  
            console.log('5b', blob)
            
            const data = {
                top: [
                    { address: arztpraxis + ', ' + strasse + ' ' + plz +' ' + stadt }
                ],
                bottom: [
                    { arztpraxis: arztpraxis }
                ]
            };
            console.log('5c', data)
            const handler = new TemplateHandler();
            console.log('6', handler)
            const doc = await handler.process(blob, data);
            console.log('7')
 
            const blobUrl = URL.createObjectURL(doc);

            console.log('8')
            let link = document.createElement("a");
            link.download = 'Patientenformular.docx';
            link.href = blobUrl;

            console.log('9')
            document.body.appendChild(link);
            console.log('10')
            link.click();
            console.log('11')
            setTimeout(() => {
                console.log('12')
                link.remove();
                console.log('13')
                window.URL.revokeObjectURL(blobUrl);
                console.log('14')
                link = null;
            }, 0);

            console.log('16')
        };
        console.log('17')
        oReq.send();
        console.log('18')
    }


}
export default new HtmlToDoc()