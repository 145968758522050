// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FIREBASE_CONFIG = {
    dev:{
      apiKey: "AIzaSyAMGJQYVBbTA9xrga7oVnuWpYUc0KTaitg",
      authDomain: "dp-doc-dev.firebaseapp.com",
      databaseURL: "https://dp-doc-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "dp-doc-dev",
      storageBucket: "dp-doc-dev.appspot.com",
      messagingSenderId: "913117908827",
      appId: "1:913117908827:web:643d9f56d8e219d2b5d47e",
      measurementId: "G-132H7WQHN5"
    }
    ,prod:{
      apiKey: "AIzaSyAU-05Wkg3sXRMBS-_ZJO6vR3wZWyLJHjY",
      authDomain: "medcomply-by-transcendis.firebaseapp.com",
      databaseURL: "https://medcomply-by-transcendis-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "medcomply-by-transcendis",
      storageBucket: "medcomply-by-transcendis.appspot.com",
      messagingSenderId: "602018490747",
      appId: "1:602018490747:web:1f58421ba34ade988df5de",
      measurementId: "G-94PH8JSRH5"
    }
  }

export const STRIPE_CONFIG = {
    DEV: {
        PUBLIC_KEY: 'pk_test_51Q25sJ054NSlw8ZUzG9uSFtGVr6pZNrx1i3jVM1o0dI1FHf7H5KuwTvJ8sPrqDqakG6zvPUNxTsqWWiJV1W3hr0300LkkpmfsJ'
       ,SECRET_KEY: 'sk_test_51Q25sJ054NSlw8ZUg9yfcZ44mrLdJv5Qbzt6CucebTCowgri8YRTG3LX530Wa8JxdSPo1urdXrz0WhKARF3XMpPQ00X82bu0jW'
    },
    PROD: {
        PUBLIC_KEY: 'pk_live_51Q25sJ054NSlw8ZUbn6FQMyZoGLPbN5SYgHEGWwWc5R75H5xbiRJOp6fcIzo6zUi4kEznBDGPj3VENJX7FHL1XIE00CDLrV8LM',
        SECRET_KEY: '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'
    }
}