class DatacheckerUtil {

    isDataComplete=(user)=>{
        return (
                // user.company.address!=null && user.company.address!=''//optional
               user.company.city!=null && user.company.city!=''
            && user.company.legalForm!=null && user.company.legalForm!=''
            && user.company.name!=null && user.company.name!=''
            && user.company.phone!=null && user.company.phone!=''
            && user.contact.email!=null && user.contact.email!=''
            && user.contact.firstname!=null && user.contact.firstname!=''
            && user.contact.lastname!=null && user.contact.lastname!=''
            && user.contact.phone!=null && user.contact.phone!=''
            && user.contact.position!=null && user.contact.position!=''
            && user.contact.title!=null && user.contact.title!=''
            )
    }
}

export default new DatacheckerUtil()