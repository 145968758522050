import React, { Component } from 'react';
import MasterDataPage from '../MasterDataPage';
import { isMobile } from 'react-device-detect';
import { LABELS } from '../../Labels';
import ConstructionIcon from '@mui/icons-material/Construction';

class CommingSoon extends Component {
    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='init-master-data-modal' style={{display:'flex',overflow:'hidden', maxHeight:isMobile?'95vh':'90vh', width:isMobile?'95vw':'70vw', maxWidth:'600px', minWidth:'480px'}}>
                <div className='master-data-page-wrap' style={{width:'100%',padding:'30px',overflowY:'scroll'}}>
                    <h1 style={{fontWeight:'300'}}><ConstructionIcon  size={'300px'}/>{LABELS.WEBSITE_COMING[lang]}</h1>
                    <p style={{fontWeight:'300'}}>{LABELS.LAUNCHING_SOON[lang]}</p>
                    <div style={{borderBottom:'solid 1px gainsboro',marginTop:'12px'}}></div>
                    
                </div>
            </div>
        );
    }
}

export default CommingSoon;