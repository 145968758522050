class Util {

    getURLParameter=(sParam)=>{
        var sPageURL = window.location.search;
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterSet = sURLVariables[i].split('=');
            let paramID = sParameterSet[0].replace('?','');
            let paramVal = sParameterSet[1];

            if (paramID == sParam) 
            {
                return paramVal;
            }
        }
    }

   openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
}
export default new Util()