
export const LABELS=Object.freeze({
    
    LOGIN:{de:"Anmelden",en:"Login"},
    PASSWORD:{de:'Passwort', en:'Password'},
    LOGOUT:{de:'Ausloggen', en:'Sign out'},
    USER_EMAIL:{de:'Benutzername / Email', en:'Username / Email'},
    REGISTER:{de:'Registrieren', en:'Register' },
    OPEN_ACCOUNT:{de:'Account Eröffnen', en:'Register' },
    INCORRECT:{de:'Benutzername oder Passwort sind ungültig. Bitte korrigieren Sie Ihre Eingabe.',en:'Incorrect username or password.'},
    NOT_CONFIRMED:{de:'Sie haben Ihre E-Mail Adresse noch nicht bestätigt. Bitte prüfen Sie ihre Mailbox.',  en:'You have not yet confirmed your email address. Please check your mailbox.' },
    NO_ACCOUNT_YET:{de:'Sie haben noch kein  Konto? ', en:'No account yet? '},
    LOGIN_MSG: {de:'Bitte loggen Sie sich mit Benutzername und Passwort ein', en:'Login with Username and Password'},
    SLOGAN_1:{de:'Ihr Tool für den Datenschutz in der Arztpraxis', en:"Your Tool for Data Protection"},
    SUB_SLOGAN_1:{de:'Mit dem [xxx] erhalten Sie Zugang zu einem Datenschutz Tool, welches Ih', en:'With the [xxx] you get access to a data protection tool that you'},
    
    EMAIL:{de:'E-Mail', en:'E-Mail'},
    CONFIRM_PASSWORD:{de:'Bestätigung Passwort', en:'Confirm Password'},
    PROCEED:{de:'Weiter', en:'Proceed'},
    SAVE_AND_PROCEED:{de:'Seichern und Weiter', en:'Save and Proceed'},
    
    COMPANY:{de:'Firmenname', en:'Company name'},
    STREET:{de:'Strasse', en:'Street'},
    STREET_NUM:{de:'Haus Nr.', en:'Street Number'},
    ADDRESS:{de:'Adresszusatz', en:'Full address'},
    ZIP:{de:'PLZ', en:'Zip'},
    CITY:{de:'Stadt', en:'City'},
    STATE:{de:'Kanton', en:'State'},
    COUNTRY:{de:'Land', en:'Country'},

    PLAN: {de:'Planen', en:'Plan'},
    INPUT_REQ:{de:'Eingabe erforderlich',en:'Input required'},
    ENTER_CONFRIM_EMAIL:{de:'Bitte geben Sie Ihre E-Mail-Adresse wiederholen ein', en:'Please enter confirm email'},
    EMAIL_NOT_MATCH:{de:'Die E-mail Adressen sind nicht identisch. Bitte stellen Sie sicher, dass die E-mail Adressen identisch sind.', en:'The email addresses are not identical. Please make sure that the email addresses are identical.'},
    EMAIL_ADRRESS_UPD:{de:'E-Mail Adresse ändern', en:'Update E-mail'},

    CHANGE_PASSWORD:{de:'Passwort ändern', en:'Change Password'},
    UPDATE_PAYMENT_SUB_INFO:{de:'Zahlung/Abonnement aktualisieren',en:'Update Payment/Subscription'},
    UPDATE_SUBSCRIPTION_INFO:{de:'Abonnement aktualisieren',en:'Update Subscription'},
    ADMINISTRATION: {de:'Administration',en:'Administration'},

    ENTER_PASSWORD:{de:'Bitte Passwort eingeben', en:'Please enter a password'},
    ENTER_CONFIRM_PASSWORD:{de:'Bitte geben Sie das Bestätigungspasswort ein', en:'Please enter confirm password'},
    PASSWORD_NOT_MATCH:{de:'Die Passwörter sind nicht identisch. Bitte stellen Sie sicher dass die Passwörter identisch sind.', en:'Password does not match'},
    EMAIL_EXIST:{de:'Diese E-Mail Adresse ist bereits im System registriert. Bitte verwenden Sie eine andere E-Mail Adresse oder setzen Sie das Passwort zurück falls Sie das Passwort vergessen haben.', en:'Email already exist! Please use a different email address or reset password.'}

   ,ACCEPT_TERMS_1:{de:'Einverstanden mit den ',en:'I agree to the '},
    ACCEPT_TERMS_2:{de:'Allgemeinen Geschäftsbedingungen',en:'General terms and conditions'},
    ACCEPT_DATA_PROTECTION_1:{de:'Kenntnisnahme der ', en:'I acknowledge the '},
    ACCEPT_DATA_PROTECTION_2:{de:'Datenschutzerklärung', en:'data protection policy'}
    ,DATA_PROTECTION_PATIENTS: {de:'Datenschutzerklärung Patient (PDF)', en:'Patient Data Protection (PDF)'}
    ,DATA_PROTECTION_WEBSITE: {de:'Datenschutzerklärung Website (PDF)', en:'Website Data Protection (PDF)'}
    ,AMOUNT:{de:'Menge', en:'Amount'}
    ,PRACTICE_DETAILS:{de:'Details zur Praxis',en:'Practice Details'}
    ,COMPANY_NAME:{de:'Arztpraxis',en:'Company Name'}
    ,DOCTOR_PHONE: {de:'Telefonnummer (Praxis)',en:'Doctor Phone Number'}
    ,PATIENT_FORM:{de:'Patientenformular (Word)', en:'Patient Form'}
    ,TITLE: {de:'Anrede',en:'Title'}
    ,POSITION: {de:'Funktion',en:'Position'}
    ,TELEPHONE_QUERIES: {de:'Telefonnummer (für Rückfragen)',en:'Telephone number '}
    ,CONTACT_EMAIL: {de:'E-mail für Korrespondenz',en:'Email Address'}
    ,CANCEL:{de:'Stornieren',en:'Cancel'}
    ,CANCEL_2:{de:'Abbrechen',en:'Cancel'}
    ,RESET:{de:'Zurücksetzen',en:'Reset'}
    ,NEW_PASSWORD:{de:'Passwort',en:'New password'}
    ,CONFIRM_PASSWORD:{de:'Bestätigung Passwort', en:'Confirm Password'}
    ,INVALID_EMAIL:{de:'Bitte geben Sie eine gültige Email Adresse ein', en:'Please enter a valid email address'}
    ,EMAIL_REQUIRED:{de:'E-Mail Adresse eingeben',en:'Enter email address'}
    ,CHANGE_PASSWORD_SUBMIT:{de:'Speichern',en:'Change password'}
    ,SOMETHING_WRONG:{de:'Etwas ist schief gelaufen. Bitte versuche es erneut.',en:'Something went wrong. Please try again.'}
   ,INSTRUCTION:{de:'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Code zum Zurücksetzen des Passworts.',en:'Enter your email address and we will send a password reset code.'}
   ,NOT_FOUND:{de:'Die von Ihnen eingegebene Email Addresse ist in unserm System nicht. Registriert. Bitte überprüfen Sie Ihre Eingabe.',en:'Email not found.'}

   ,PASSWORD_NOT_MATCH:{de:'Die Passwörter sind nicht identisch. Bitte stellen Sie sicher dass die Passwort Bestätigung identisch ist.', en:'Password does not match'}
   ,CHECK_MAIL:{de:'Überprüfen Sie jetzt Ihre E-Mails',en:'Check your email'}
   ,WE_SENT_CODE_1:{de:'Wir haben einen Link zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet', en:'We have sent a password reset link to your email'}
   ,WE_SENT_CODE_2:{de:' gesendet. Geben Sie den untenstehenden Code ein, um Ihr neues Passwort festzulegen.',en:'. Enter the code below to set your new password.'}
   ,FORGOT_PASS:{de:'Passwort vergessen?', en:'Forgot your password?' },
   DATA_ACCESS:{de:'Zugangsdaten', en:'Data Access' },
   EMAIL:{de:'E-Mail', en:'E-Mail'},
   
   FIRSTNAME:{de:'Vorname', en:'Firstname' },
   LASTNAME:{de:'Nachname', en:'Lastname' }
   
   ,NOT_SIGN_TERMS_0:{de:'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen únd Datenschutzerklärung', en:'Please accept the Privacy Policy, General Terms and Conditions'}
   ,NOT_SIGN_TERMS_1:{de:'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen', en:'Please accept the general terms and conditions'}
   ,NOT_SIGN_TERMS_2:{de:'Bitte akzeptieren Sie die Datenschutzerklärung',en:'Please accept the Data protection policy'}
   
   ,COMPANY_NAME:{de:'Arztpraxis',en:'Company Name'}
   ,LEGAL_FORM:{de:'Rechtsform',en:'Legal form'}
   ,STREET_NR:{de:'Strasse und Nr.', en:'Street and House Number'}
   
   ,CONTACT_PERSON: {de:'Kontaktperson',en:'Contact Person'}
   
   ,SAVE: {de:'Speichern',en:'Save'}
   ,DISCARD_CHANGES: {de:'Änderungen Verwerfen',en:'Discard Changes'}
   ,VERIF_EMAIL:{de:'Bestätigen Sie Ihre E-mail Adresse', en:'Verify your email address'}
   ,WE_SENT_VERIF: {de:'Wir haben Ihnen einen Link zur Bestätigung Ihrer E-Mail-Adresse gesendet.',en:'We have sent a link to verify your email.'}

   ,WEBSITE_COMING: {de:'Website in Kürze verfügbar', en:'Website Coming Soon'}
   ,LAUNCHING_SOON: {de:"Unsere Website befindet sich im Aufbau. Wir arbeiten hart daran, unsere Website zu verbessern und bereiten uns darauf vor, sie online zu stellen!", en:"Our website is under construction. We're working hard to improve our website and we'll get ready to launch after!"}
   ,UPDATE_INFO: {de:'Stammdaten ändern', en:'Update Information'}
   ,BASE_INFO: {de:'Stammdaten', en:'Base Information'}
   ,UPDATE_SCCCESFUL: {de:'Update erfolgreich!', en:'Update Successful!'}
   ,CONT_EDIT:{en:'Continue editing', de:'Bearbeiten Sie weiter'}


   ,ARE_YOU_SURE_CANCEL_SUB:{en:'Are you sure you want to cancel subscription?', de:'Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?'}


   ,BASE_INFO: {de:'Stammdaten', en:'Base Information'}
   ,DESC_UPD_1: {de:'Hier erfassen Sie die nötigen Grunddaten wie Firmenadresse, erfassen Person etc.',en:'Manage and Update your Information'}
   ,DOWNLOAD: {de:'Herunterladen',en:'Download'}
   ,DOCUMENT_DOWNLOAD:{de:'Dokumente Download',en:'Document Download'}
   ,PATIENT_STATEMENT: {de:'Patientenerklärung',en:'Patient Statement'}
    ,DATA_PROTECTION: {de:'Datenschutzerklärung',en:'Data Protection'}
    ,WELCOME: {de:'Willkommen',en:'Welcome'},
    DATA_COLLECTION:{de:'Datenerfassung', en:'Data Collection'},
    NO_ACTIVE_SUBS:{de:'Kein aktives Abonnement', en:'No active subscription'},
    YOU_HAVE_NO_ACTIVE_SUBS:{de:'Sie verfügen über kein aktives Abonnement.', en:'You do not have an active subscription.'},
    SUBSCRIPTION:{de:'Abonnement', en:'Subscription'},
    PAY_NOW:{de:'Zahlen Sie jetzt', en:'Pay now'},
    CONT_PAY_OP:{de:'Weiter zu den Zahlungsmöglichkeiten', en:'Continue to payment options'},
    UPDATE_EMAIL:{de:'E-Mail Adresse ändern',en:'Update E-mail'}
    ,OLD_EMAIL:{de:'Alte E-Mail Adresse', en:'Old E-mail'}
    ,NEW_EMAIL:{de:'Neues E-Mail Adresse', en:'New E-Mail'}
    ,NEW_EMAIL_IS_REQUIRED:{de:'Neue E-Mail ist erforderlich', en:'New E-Mail is required'}
    ,CONFIRM_EMAIL:{de:'E-Mail wiederholen', en:'Confirm Email'}
    ,OLD_PASSWORD:{de:'Altes Passwort', en:'Old Password'}
    ,NEW_PASSWORD:{de:'Neues Passwort', en:'New Password'}
    ,CONFIRM_NEW_PASSWORD:{de:'Neues Passwort Wiederholen', en:'Confirm New Password'}
    ,DL_HERE_1:{de:'Laden Sie hier die Patientenerklärung mit Ihren Daten herunter', en:'Download the Patient Declaration with your data here'}
    ,DP_SUB_MSG_1:{de:'In diesem Menü erfassen Sie alle bestehenden Datenverarbeitungen in der Praxis', en:'Record all existing data processing in the practice'}
    ,CHOOSE_DATA_MSG:{de:'Wählen Sie hier aus, welche Daten in Ihrer Praxis erfasst werden', en:'Choose which data is collected in your practice'}
    ,ERROR_MSG_1:{de:'Etwas ist schief gelaufen. Versuchen Sie es später erneut.', en:'Something went wrong. Try again later.'}
    ,ERROR_MSG_2:{de:'Bitte überprüfen Sie, dass Ihre Angaben komplett sind',en:'Please complete all required inputs'}
    ,INCORRECT_OLD_PW:{de:'Altes Passwort ist ungültig', en:'Old password is incorrect'}
    ,NEW_PW_NOT_MATCH:{de:'Neues Passwort stimmt nicht überein', en:'New password does not match'}
    ,OLD_NEW_PW_MATCH:{de:'Alte und neue Passwörter sollten nicht identisch sein', en:'Old and new passwords should not be the same'}
    ,SETUP_YOUR_PROFILE_1:{de:'Richten Sie Ihr Profil ein, um mit der Erstellung von Dokumenten zu beginnen', en:'Setup your profile to start generating documents'}
    ,WELCOME_TO:{de:'Willkommen zu', en:'Welcome to'}
    ,PAY:{de:'Zahlen', en:'Pay'}
    ,GENERATE_DECLARATION:{de:'Patientenerklärung Generieren', en:'Generate Patient Declaration'}
    ,GENERATE_PRIVACY_POLICY:{de:'Datenschutzerklärung Generieren', en:'Generate Privacy Policy'}
    ,GO_TO_LOGIN:{de:'Zum Login', en:'Go to login'}
    ,SUBSCRIBE_TO:{de:'Abonnieren Sie den', en:'Subscribe to'}

    ,CONFIRM_NEW_EMAIL:{de:'Neues Email Adresse Wiederholen', en:'Confirm New Email'}
    ,PW_SHOULD_BE_6:{de:'Das Passwort sollte mindestens 6 Zeichen lang sein', en:'Password should be at least 6 characters'}
    ,PW_UPDATED:{de:'Passwort erfolgreich aktualisiert', en:'Password Successfully Updated'}
    ,SUBSCRIPTION_PERIOD:{de:'Abonnementzeitraum', en:'Subscription Period'}
    ,DATA_COL_INCOMPLETE:{de:'Die Datenerfassung ist unvollständig. ', en:'Data collection is incomplete. '}
    ,UPDATE_NOW:{de:'Jetzt aktualisieren', en:'Update now'}
    ,GOTO_DASHBOARD:{de:'Dashboard', en:'Go to dashboard'}
    ,DASHBOARD:{de:'Dashboard', en:'Dashboard'}
    ,LANGUAGE:{de:'Sprache', en:'Language'}
    ,LANGUAGE_DE:{de:'Sprache / Language ', en:'Language / Sprache'}
    ,SET_LANG:{de:'Sprache einstellen', en:'Set language'}
    ,DATE_TO:{de:'bis', en:'to'}
    ,PRODUCT:{de:'Produkt', en:'Product'}
    ,PRICES:{de:'Preise', en:'Prices'}
    ,CONTACT:{de:'Kontakt', en:'Contact'}
    ,PRACTICE:{de:'Aus der Praxis', en:'Practice'}
    ,NEW_EMAIL_IS_REQUIRED:{de:'Neues E-Mail ist erforderlich', en:'New E-Mail is required'}
    ,CONFIRM_NEW_EMAIL_IS_REQUIRED:{de:'Neues E-Mail bestätigen ist erforderlich', en:'Confirm new E-Mail is required'}
    ,NEW_EMAIL_NOT_MATCH:{de:'Neues E-mail stimmt nicht überein', en:'New E-mail does not match'}
    ,PASSWORD_IS_REQUIRED:{de:'Passwort ist erforderlich', en:'Password is required'}
    ,NEW_EMAIL_USED:{de:'E-Mail bereits verwendet',en:'Email already used'}
    ,PW_INCORRECT:{de:'Das Passwort ist inkorrekt', en:'Password is incorrect'}
    ,EXISTING_EMAIL_UPD_PENDING:{de:'Eine bestehende E-Mail-Aktualisierungsanfrage steht aus.', en:'An existing email update request is pending.'}
    ,CHECK_MAILBOX_1:{de:'Überprüfen Sie das Postfach von ', en:'Check the mailbox of '}
    ,CHECK_MAILBOX_2:{de:', um die E-Mail-Adresse zu bestätigen.', en:' to verify email.'}
    ,EMAIL_CHANGE_USE:{de:'Sie können die neue E-Mail verwenden, nachdem sie überprüft wurde.', en:'You can use the new email after it has been verified.'}
    ,PAYMENT_METHODS:{de:'Zahlungsarten', en:'Payment Methods'}
    ,NOT_IN_INBOX:{de:'Nicht im Posteingang oder Spam-Ordner?', en:'Not in the inbox or spam folder?'}
    ,RESEND: {de:'Erneut senden', en:'Resend'}
    ,NO_PAYMENT_METHOD:{de:'Keine Zahlungsmethode', en:'No payment method'}
    ,CARD_INFORMATION:{de:'Karteninformationen', en:'Card Information'}
    ,ADD_CARD:{de:'Karte hinzufügen', en:'Add Card'}
    ,MANAGE_PROFILE:{de:'Profil verwalten', en:'Manage Profile'}
    ,CANCEL_SUBSCRIPTION: {de:'Abonnement kündigen', en:'Cancel Subscription'}
    ,CANCELLED_AT: {de:'Abgesagt um', en:'Cancelled at'}
    ,SUBS_CANCELLED_NA: {de:'Das Abonnement wurde gekündigt', en:'Subscription has been cancelled'}
    ,RENEW_SUBS: {de:'Abonnement verlängern', en:'Renew Subscription'}
    ,ADD_NEW_CARD:{de:'Neue Karte hinzufügen', en:'Add New Card'}
    ,ADD_PAY_BTN:{de:'Speichern', en:'Save'}
    ,CARD_POSTAL_CODE:{de:'Postleitzahl', en:'Postal code'}
    ,CARD_STATE_PROVINCE:{de:'Staat/Provinz', en:'State / Province'}
    ,CARD_CITY:{de:'Stadt', en:'City'}
    ,CARD_BILLING_ADDRESS:{de:'Rechnungsadresse', en:'Billing address'}
    ,CARD_NAME_HOLDER:{de:'Name des Karteninhabers', en:'Cardholder name'}
    ,CARD_EXPIRY:{de:'Ablauf', en:'Expiry'}
    ,CARD_NUMBER_N:{de:'Kartennummer', en:'Card number'}
    ,CARD_ADDRESS_LINE_1:{de:'Anschrift Zeile 1', en:'Address line 1'}
    ,CARD_ADDRESS_LINE_2:{de:'Anschrift Zeile 2', en:'Address line 2'}
    ,CARD_FULLNAME_ON_CARD:{de:'Vollständiger Name auf der Karte', en:'Full name on card'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    ,XXXX:{de:'xXXX', en:'xxxxx'}
    
});