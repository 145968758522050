
import { getAuth, signInWithEmailAndPassword , createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import {db} from '../firebase/firebase';
import {  ref, update } from "firebase/database";
import { USERS_TABLE } from "../firebase/constants";
const auth = getAuth();
class UserService {
    requestEmailChange=async(currentUid, currentEmail, currentPassword, newEmail, successCallback, errorCallback)=>{
 
        let newUserUid;
        // 1 check password
        signInWithEmailAndPassword(auth, currentEmail, currentPassword).then(function(userCredential){
            console.log('userCredential',userCredential)

        // 2 create user with new email, this will also login the newly created email
            createUserWithEmailAndPassword(auth, newEmail, currentPassword).then((newCreds)=>{
                let newUserPath = USERS_TABLE + '/' + newCreds.user.uid
                const upd = {};
                upd[newUserPath] = {
                    redirectTo: currentUid
                };;
                update(ref(db), upd);
        // 3 send email verification to new email
                sendEmailVerification(newCreds.user).then(()=>{
                    newUserUid = newCreds.user.uid;

        // 4 signout newly created user
                    getAuth().signOut().then(res4=>{

        // 5 re-login original email
                    signInWithEmailAndPassword(auth, currentEmail, currentPassword).then(origUser=>{
                        console.log('success send email verification to new email',origUser);
                        let userPath = USERS_TABLE + '/' + origUser.user.uid
                        const updates = {};
                        updates[userPath+'/requests/emailChange'] = {
                              newEmail:newEmail
                            , newUid:newUserUid
                            , date:(new Date()).toISOString()
                        };
                        
                        update(ref(db), updates).then(res=>{
                            if(successCallback!=null){
                                successCallback();
                            }
                        });

                    }).catch(err5=>{console.log('05 error',err5); errorCallback(err5);});

                    });
                }).catch((err3)=>{console.log('03 error',err3); errorCallback(err3);});
            })
            .catch(err2=>{console.log('02 error',err2); errorCallback(err2);});

        }).catch(err1=>{console.log('01 error',err1); errorCallback(err1); });  
    }

}
export default new UserService()