import React, { Component } from 'react';
import LandingPage from '../LandingPage';
import CloseableModal from '../../components/CloseableModal';
import FirebaseEmailAction from './FirebaseEmailAction';

class EmailActionPage extends Component {
    render() {
        
        return (
            <div>
                <LandingPage exchange={this.props.exchange}/>
                <CloseableModal
                    content={<FirebaseEmailAction {...this.props}/>}
                 />
            </div>
        );
    }
}

export default EmailActionPage;