//https://medium.com/@lkuppers11/processing-payments-in-firebase-with-stripe-e90c816f02d0

import React, { Component } from 'react';
import {FIREBASE_CONFIG} from '../config.js'
import StripeService from '../firebase/StripeService.js'
import {firestoreDb,app} from '../firebase/firebase.js'
import { getAuth } from "firebase/auth"; 
import { Link } from 'react-router-dom';
import { LABELS } from '../Labels';
import LinearProgress from '@mui/material/LinearProgress';

import { query, collection, where, getDocs, addDoc } from 'firebase/firestore';
import { isMobile } from 'react-device-detect';
import { CircularProgress } from '@mui/material';

const auth = getAuth()

class FirstPaymentPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      onLoad:true,
      products: null,
      onPay:false
    }
  }
  async componentDidMount(){
    let pr = await this.getProducts()
    this.setState({products:pr}, ()=>{
      this.setState({onLoad:false})
    });
    window.scrollTo(0, 0);
  } 

  getProducts=async ()=>{

    const q = query(
      collection(firestoreDb, 'products'), 
      where('active', '==', true)
    );
  
  const querySnapshot = await getDocs(q);
  
  // for each product, get the product price info
  const productsPromises = querySnapshot.docs.map(async (productDoc) => {
    // console.log('FIREBASE_CONFIG', FIREBASE_CONFIG.prod)
      let productInfo = productDoc.data();
    
      // fetch prices subcollection per product
      const pricesCollection = collection(productDoc.ref, 'prices');
      const priceQuerySnapshot = await getDocs(pricesCollection);
    
      // assume there is only one price per product
      console.log('no assumption', priceQuerySnapshot.docs)
      const priceDoc = priceQuerySnapshot.docs[0];
      console.log('priceDoc', priceDoc)
      if(priceDoc!=null) {
        productInfo['priceId'] = priceDoc.id;
        productInfo['priceInfo'] = priceDoc.data();
      }
      return productInfo;
    });
    
    const products = await Promise.all(productsPromises);
    return products
  }

  formatUnitAmount=(priceInfo)=>{
    let x = priceInfo.unit_amount/100;
    let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
    return roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +' '+ priceInfo.currency.toUpperCase();
  }

    render() {
      
      let lang = this.props.exchange.language || 'de';
      let prodDiv = [];
      if(!this.state.onLoad){
      this.state.products.forEach((productInfo,idx)=>{
        console.log(productInfo)
        prodDiv.push(
          <div className='product-details' style={{padding:'18px',border:'solid 1px gainsboro',margin:'8px',backgroundColor:'white'}}>
            <h3 style={{fontWeight:'300'}}>{LABELS.SUBSCRIBE_TO[lang]+' '+ productInfo.name}</h3>
            <h2>{this.formatUnitAmount(productInfo.priceInfo)}</h2>
            <br/>
            <button className='btn1' 
              tabIndex={-1}
              style={{pointerEvents:this.state.onPay?'none':''}}
              onClick={()=>{
                this.setState({onPay:true},()=>{StripeService.createCheckoutSession(auth,productInfo, lang)})
              }}>
              {this.state.onPay?
                <CircularProgress size={'24px'} sx={{color:'white'}}/>:
                LABELS.PAY[lang]}
              </button>
          </div>
        )
        // }
        })
      }

        return (
            <div className='first-pay-page page' style={{display:'flex',justifyContent:'center'}}>
                <div style={{minWidth:isMobile?'100%':'380px'}}>
                <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>
                 {/* {prodDiv.length===0&&
                 <div style={{textAlign:'center',border:'solid 1px gainsboro'}}>
                    <h2>No product available</h2>
                    <br/>
                    <Link className='li1' to='/'>Go to Dashboard</Link>
                 </div>} */}
                  {prodDiv}
                </div>
            </div>
        );
    }
}

export default FirstPaymentPage;