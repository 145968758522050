import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { LABELS } from '../Labels';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import {MODAL} from '../main/ModalController'
import { getAuth, signInWithEmailAndPassword, updatePassword } from "firebase/auth";

const auth = getAuth();
class ChangePasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             errFields:''
            ,errorMsg:'' 
            ,onLoad:false
            ,showOldPassword:false
            ,showNewPassword:false
            ,showConfirmNewPassword:false
            ,inputs:this.inputDefaults()
            ,showSuccessMessage:false
        }
    }

    async componentDidMount(){
        let lang = this.props.exchange.language || 'de';
        this.props.exchange.setBrowserTitle(LABELS.CHANGE_PASSWORD[lang] + ' | Medcomply');
        window.scrollTo(0, 0);
    }
    
    inputDefaults=()=>{
        return {
             oldPassword:''
            ,newPassword:''
            ,confirmNewPassword:''
        }
    }
    
    changeInputHandler=(event)=>{
        let inputs = this.state.inputs;
        let errFields = this.state.errFields;
        
        switch(event.target.id){
            case 'old-pw-input': 
                inputs.oldPassword = event.target.value
                errFields=errFields.replace('oldPassword','');
            break;
            case 'new-pw-input': 
                if(inputs.newPassword !== inputs.confirmNewPassword){
                    errFields=errFields.replace('confirmNewPassword','');
                }   
                inputs.newPassword = event.target.value
                errFields=errFields.replace('newPassword','');

            break;
            case 'confirm-new-pw-input':

                if(inputs.newPassword !== inputs.confirmNewPassword){
                    errFields=errFields.replace('newPassword','');
                }
                inputs.confirmNewPassword = event.target.value
                errFields=errFields.replace('confirmNewPassword','');
             break;
        }
        this.setState({inputs:inputs,errFields:errFields,errorMsg:'',showSuccessMessage:false})
    }
    
    changePasswordClicked=async()=>{
        let lang = this.props.exchange.language || 'de';

        // Validate inputs

        let errorMsg = ''
        let errFields = '';
        if(this.state.inputs.oldPassword===''){
            errFields += ' oldPassword';
        }
        if(this.state.inputs.newPassword===''){
            errFields += ' newPassword';
        }
        if(this.state.inputs.confirmNewPassword===''){
            errFields += ' confirmNewPassword';
        }

        if(this.state.inputs.newPassword!=='' && this.state.inputs.confirmNewPassword!==''
            &&this.state.inputs.newPassword !== this.state.inputs.confirmNewPassword){
            errFields += ' newPassword confirmNewPassword';
            errorMsg = LABELS.NEW_PW_NOT_MATCH[lang]
        }
        else if (this.state.inputs.oldPassword!==''
            && this.state.inputs.newPassword === this.state.inputs.oldPassword){
            errFields = 'oldPassword newPassword confirmNewPassword';
            errorMsg = LABELS.OLD_NEW_PW_MATCH[lang]
        }

        if(errFields!=='' && errorMsg===''){
            errorMsg=LABELS.ERROR_MSG_2[lang];
        }

        if(errFields==='' && errorMsg===''){
            this.setState({onLoad:true, errorMsg:'',errFields:''},()=>{
                this.checkAndUpdatePassword();
            })
        }else{
            this.setState({errorMsg:errorMsg,errFields:errFields})
        }
    }

    checkAndUpdatePassword=async()=>{
        let lang = this.props.exchange.language || 'de';

        let email = auth.currentUser.email;
        let oldPassword = this.state.inputs.oldPassword;

        signInWithEmailAndPassword(auth, email, oldPassword).then((res)=>{
           let reloginCheckSuccess = res.user!=null && res.operationType === "signIn"; 
            updatePassword(auth.currentUser,this.state.inputs.newPassword).then(()=>{

                this.props.exchange.setModal(MODAL.PASSWORD_CHANGE_SUCCESS)
                this.setState({onLoad:false, inputs:this.inputDefaults(), showSuccessMessage:true})
            },(errorx) => {
                switch(errorx.code){
                    case "auth/weak-password":
                        if(errorx.message ==='Firebase: Password should be at least 6 characters (auth/weak-password).'){
                            this.setState({onLoad:false, errorMsg:LABELS.PW_SHOULD_BE_6[lang]});
                        }
                    
                    break;
                    default:this.setState({onLoad:false, errorMsg:LABELS.ERROR_MSG_1[lang]});break;
                }
                this.setState({onLoad:false})
            });
        }).catch((error)=>{
            console.log('xxxx2', JSON.stringify(error))
            switch(error.code){
                case "auth/wrong-password": 
                case "auth/invalid-login-credentials": this.setState({onLoad:false, errorMsg:LABELS.INCORRECT_OLD_PW[lang]}); break;
                case "auth/invalid-credential": this.setState({onLoad:false, errorMsg:LABELS.INCORRECT_OLD_PW[lang]}); break;
                case "auth/weak-password": break
                default:this.setState({onLoad:false, errorMsg:LABELS.ERROR_MSG_1[lang]});break;
            }
        }); 
}

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='change-password-page page' style={{pointerEvents:this.state.onLoad?'none':'', justifyContent:'center',display:'flex'}}>
                <div className='page-content page-box-1' style={{width:'500px'}}>
                    <h2>{LABELS.CHANGE_PASSWORD[lang]}</h2>
                    <br/>
                    <div>
                        <p className='ititle'>{LABELS.OLD_PASSWORD[lang]}</p>
                        <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                            <OutlinedInput
                                id="old-pw-input" error={this.state.errFields.includes('oldPassword')}
                                value={this.state.inputs.oldPassword}
                                onChange={this.changeInputHandler} 
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{this.setState({showOldPassword:!this.state.showOldPassword});}}
                                        edge="end">
                                        {this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }/>
                        </FormControl>

                        <p className='ititle'>{LABELS.NEW_PASSWORD[lang]}</p>
                        <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                            <OutlinedInput
                                id="new-pw-input" error={this.state.errFields.includes('newPassword')}
                                value={this.state.inputs.newPassword}
                                onChange={this.changeInputHandler} 
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{this.setState({showNewPassword:!this.state.showNewPassword});}}
                                        edge="end">
                                        {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }/>
                        </FormControl>
                    
                        <p className='ititle'>{LABELS.CONFIRM_NEW_PASSWORD[lang]}</p>
                        <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                            <OutlinedInput
                                id="confirm-new-pw-input" error={this.state.errFields.includes('confirmNewPassword')}
                                value={this.state.inputs.confirmNewPassword}
                                onChange={this.changeInputHandler} 
                                type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{this.setState({showConfirmNewPassword:!this.state.showConfirmNewPassword});}}
                                        edge="end">
                                        {this.state.showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }/>
                        </FormControl>

                        <div>

                        <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>
                        {this.state.showSuccessMessage&&
                        <p style={{color:'green',padding:'10px 10px',textAlign:'center'}}>{LABELS.PW_UPDATED[lang]}</p>}
                        
                        <br/>
                            <button className={'btn1 ' + (this.state.onLoad?' btn1-load':'')} onClick={this.changePasswordClicked} tabIndex={-1}>
                                {this.state.onLoad?
                                    <CircularProgress size={'24px'} sx={{color:'white'}}/>
                                    :LABELS.SAVE[lang]}
                            </button>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePasswordPage;