import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { LABELS } from '../Labels';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ConstructionIcon from '@mui/icons-material/Construction';
import StripeService from '../firebase/StripeService.js'
class CancelSubsciptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onLoad:false
        }
    }
    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='init-master-data-modal' style={{pointerEvents:this.state.onLoad?'none':'', display:'flex',overflow:'hidden', maxHeight:isMobile?'95vh':'90vh', width:isMobile?'95vw':'70vw', maxWidth:'600px', minWidth:'480px'}}>
                <div className='master-data-page-wrap' style={{width:'100%',padding:'30px',overflowY:'scroll'}}>
                    <h3 style={{fontWeight:'300',textAlign:'center'}}>{LABELS.ARE_YOU_SURE_CANCEL_SUB[lang]}</h3>
                    {/* <p style={{fontWeight:'300'}}>{LABELS.PW_UPDATED[lang]}</p> */}
                    <div style={{borderBottom:'solid 1px gainsboro',marginTop:'18px'}}></div>
                <br/>
                    <div style={{display:'flex'}}>
                        <Link className={'btn1 btn1-second'} onClick={()=>{ this.props.exchange.setModal(null)}} style={{marginRight:'4px'}}>
                            {'Nicht stornieren'}
                        </Link>
                        <Link className={'btn1 '} onClick={async()=>{
                            let subscription = this.props.exchange.stripeObject.subscription;
                            let subscriptionId = subscription.items[0].subscription
                            this.setState({onLoad:true}, async()=>{
                                await StripeService.cancelSubscription(subscriptionId).then(res=>{
                                    console.log('cancelSubscription response', res.data)
                                    this.props.exchange.setModal(null)
                                })
                            })

                        }} style={{marginLeft:'4px'}}>
                            {this.state.onLoad?
                            <CircularProgress size={'24px'} sx={{color:'white'}}/>:'Abonnement kündigen'}
                        </Link>
                    </div>

                    
                </div>
            </div>
        );
    }
}

export default CancelSubsciptionModal;