import { query, collection, where, getDocs, getDoc, doc, addDoc, onSnapshot} from 'firebase/firestore'
import { firestoreDb } from "./firebase"
import axios from 'axios'
import {STRIPE_CONFIG} from '../config.js'
const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';

const PUBLIC_KEY = ENV==='prod'? STRIPE_CONFIG.PROD.PUBLIC_KEY : STRIPE_CONFIG.DEV.PUBLIC_KEY
const SECRET_KEY = ENV==='prod'? STRIPE_CONFIG.PROD.SECRET_KEY : STRIPE_CONFIG.DEV.SECRET_KEY

class StripeService {
    createCustomerPortal = async (stripeObj, flow, redirectUrl,lang) =>{
        console.log(stripeObj)
        const params = new URLSearchParams();
        params.append('customer', stripeObj.stripeId);
        params.append('configuration','bpc_1QMZo5054NSlw8ZUGlA18Jm4') 
        params.append('locale',lang) 
        params.append('return_url',  redirectUrl);
        console.log('lang', lang)
        if(flow!=null){
            //payment_method_update
            params.append('flow_data[type]', flow);
        }
        const config = {
            headers: { Authorization: 'Bearer ' + SECRET_KEY}
        };
        return await axios.post('https://api.stripe.com/v1/billing_portal/sessions', params, config);
    }


    getSubscription = async (subscriptionId) =>{
        const config = {
            headers: { Authorization: 'Bearer ' + SECRET_KEY}
        };
        return await axios.get('https://api.stripe.com/v1/subscriptions/'+subscriptionId, config);
    }
    cancelSubscriptionx = async (subscriptionId) =>{
        const config = {
            headers: { Authorization: 'Bearer ' + SECRET_KEY}
        };
        return await axios.delete('https://api.stripe.com/v1/subscriptions/'+subscriptionId, config);
    }

    cancelSubscription = async (stripeObj, subscriptionId) =>{
        console.log('xxstripeObj', stripeObj)
        const params = new URLSearchParams();
        params.append('customer', stripeObj.stripeId);
        params.append('configuration','bpc_1QMZo5054NSlw8ZUGlA18Jm4') 
        params.append('return_url',  window.location.origin+'/admin/subscription-info');
        params.append('flow_data[type]', 'subscription_cancel');
        params.append('flow_data[subscription_cancel][subscription]', stripeObj.subscription.items[0].subscription);
        params.append('flow_data[after_completion][type]', 'redirect');
        params.append('flow_data[after_completion][redirect][return_url]',  window.location.origin+'/admin/subscription-info');

        const config = {
            headers: { Authorization: 'Bearer ' + SECRET_KEY}
        };
        return await axios.post('https://api.stripe.com/v1/billing_portal/sessions', params, config);
    }
    
    retrieveAllCustomerPaymentMethod = async (customerId) =>{
        const config = {
            headers: { Authorization: 'Bearer ' + PUBLIC_KEY}
        };
        return await axios.get('https://api.stripe.com/v1/customers/' + customerId + '/payment_methods',config);
    }
    retrieveCustomerPaymentMethod = async (customerId, paymentMethodId) =>{
        const config = {
            headers: { Authorization: 'Bearer ' + PUBLIC_KEY}
        };
        return await axios.get('https://api.stripe.com/v1/customers/' + customerId + '/payment_methods/' + paymentMethodId, config);
    }
    addNewCard = async (type, cardNum, card_exp_month, card_exp_year, card_cvc) =>{
        const params = new URLSearchParams();
        params.append('type', type);
        params.append('card[number]', cardNum);
        params.append('card[exp_month]', card_exp_month);
        params.append('card[exp_year]', card_exp_year);
        params.append('card[cvc]', card_cvc);
        const config = {
            headers: { Authorization: 'Bearer ' + PUBLIC_KEY}
        };
        return await axios.post('https://api.stripe.com/v1/payment_methods', params, config);
    }

    createNewCard = async (customerId, cardNum, card_exp_month, card_exp_year, card_cvc) =>{
        const config = {
            headers: { Authorization: 'Bearer ' + PUBLIC_KEY}
        };
        const params = new URLSearchParams();
        params.append('card[number]', cardNum);
        params.append('card[exp_month]', card_exp_month);
        params.append('card[exp_year]', card_exp_year);
        params.append('card[cvc]', card_cvc);
        return await axios.post('https://api.stripe.com/v1/tokens', params, config);
    }

    getUserSubscription = async(uuid)=> {
        let subscription = null;
        const q = query(
            collection(firestoreDb, 'customers', uuid, 'subscriptions'), 
            where('status', 'in', ['trialing', 'active'])
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            console.log('User does not have active subscription')
        } else {
            // assuming user only has one active subscription max
            subscription = await querySnapshot.docs[0].data();
            console.log('XXXXX',  querySnapshot.docs[0])
        }
        return subscription;
    }

    getStripeObject = async (uuid, callback) => {
        if(uuid==null){
            console.log('No user loggedin')
            return null
        }
        let stripeObject = {};
        let subscription = null; 
        const docRef = doc(firestoreDb, "customers", uuid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            stripeObject = docSnap.data();
            subscription = await this.getUserSubscription(uuid)
        } 
        stripeObject.subscription = subscription;
        callback(stripeObject);
    }

    createCheckoutSession=async(auth,productInfo,lang)=>{
        let checkoutSessionData = {
            allow_promotion_codes:true,
            price: productInfo.priceId, // price ID from products fetch
            success_url: window.location.origin, // can set this to a custom page
            cancel_url: window.location.origin,  // can set this to a custom page
            locale: lang
        }
    
        const checkoutSessionRef = await addDoc(
        // currentUser is provided by firebase, via getAuth().currentUser
        collection(firestoreDb, `customers/${auth.currentUser.uid}/checkout_sessions`), 
        checkoutSessionData
        );
    
        // The Stripe extension creates a payment link for us
        onSnapshot(checkoutSessionRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                // handle error
            }
            if (url) {
                window.location.assign(url);  // redirect to payment link
            }
        });
    }
}
export default new StripeService()