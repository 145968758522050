

import { getAuth } from "firebase/auth";
import CacheService from './CacheService';
 
class AuthService {
  logout=(callback,redirectHome)=>{
    try{
      if(redirectHome){
        window.location.href='/'
      }
      getAuth().signOut().then(()=> {
        console.log('Signed Out');
        CacheService.removeSession();
        callback();
      }, function(error) {
        console.error('Sign Out Error', error);
      });
      
		}catch(error){
        console.error('logoutClicked',error);
		}
  }

  isLoggedIn=(firebaseUser,session)=>{
    return firebaseUser!=null && session!=null && session.user!=null;
  }

  canAccessPos=(firebaseUser,session)=>{
      return firebaseUser!=null && session!=null && session.user!=null && session.business!=null;
  }

}

export default new AuthService()