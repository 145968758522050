import React, { Component } from 'react';
import { LABELS } from '../Labels';
import { ref, update } from "firebase/database"
import { USERS_TABLE } from '../firebase/constants';

import {db} from '../firebase/firebase';
class LanguageSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
             onLoad:false
        }
    }
    setLanguage=async(lang)=>{
        let settingsPath = USERS_TABLE + '/' + this.props.exchange.user.uuid+'/settings'
        const updates = {};
        updates[settingsPath+'/lang'] = lang;

        update(ref(db), updates).then(res=>{
          this.props.exchange.reloadFirebaseUser();
          this.props.exchange.removeModal();
        }).catch(e=>{
          console.log('err',e)
        });
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='set-lang-page' style={{backgroundColor:'white',padding:'28px 28px'}}>
                <div className='set-lang-content' style={{display:'flex',alignItems:'center',justifyContent:'center', height:'100%', width:'100%',pointerEvents:this.state.onLoad?'none':''}}>
                    <div className='setlang-div' style={{width:'400px',backgroundColor:'white',padding:'20px 10px'}}>
                        <div style={{textAlign:'center'}}>
                            <h2>{LABELS.SET_LANG[lang]}</h2>
                            {/* <p>{LABELS.WE_SENT_CODE_1[lang]}</p> */}
                        </div>
                        <br/>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div className='lang-btn' onClick={()=>this.setLanguage('de')}
                                style={lang==='de'?{border:'solid 2px black'}:{}}>
                                <p>Deutsch / German</p>
                            </div>
                            <div className='lang-btn'  onClick={()=>this.setLanguage('en')}
                                style={lang==='en'?{border:'solid 2px black'}:{}}>
                                <p>Englisch / English</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LanguageSet;