import React, { Component } from 'react';
import { getAuth } from "firebase/auth"
import TextField from '@mui/material/TextField';
import { LABELS } from '../Labels';
import UserService from '../service/UserService';
import { CircularProgress, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

const auth = getAuth();
class ChangeEmailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             onResend:false
             ,showPassword:false
             ,hasPendingRequest:props.exchange.user!=null && props.exchange.user.requests!=null && props.exchange.user.requests.emailChange!=null
             ,onLoad:false
             ,errFields:''
             ,errorMsg:''
             ,inputs:{
                 oldEmail:props.exchange.user.email
                ,password:''
                ,newEmail:''
                ,confirmNewEmail:''
            }
        } 
    }

    async componentDidMount(){
        let lang = this.props.exchange.language || 'de';
        this.props.exchange.setBrowserTitle(LABELS.UPDATE_EMAIL[lang] + ' | Medcomply');
        window.scrollTo(0, 0);
    }


    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onSaveNewEmailClicked =async()=>{
        this.setState({onLoad:true},this.saveNewEmail)
    }

    saveNewEmail=async()=>{ 
        let lang = this.props.exchange.language || 'de';
        let errorMsg = ''
        let errFields = '';
    
            if(this.state.inputs.password===''){
                errorMsg = LABELS.PASSWORD_IS_REQUIRED[lang]
                errFields +=' password-input'
            }
            if(this.state.inputs.newEmail===''){
                errorMsg = LABELS.NEW_EMAIL_IS_REQUIRED[lang]
                errFields +=' new-inputx'
            }
            if(this.state.inputs.confirmNewEmail===''){
                errorMsg = LABELS.CONFIRM_NEW_EMAIL_IS_REQUIRED[lang]
                errFields +=' confirm-new-input'
            } 
            if(errFields.split(" ").length>2){
                errorMsg = LABELS.ERROR_MSG_2[lang]
            }

            if(errorMsg===''){
                if(this.state.inputs.newEmail!=this.state.inputs.confirmNewEmail){
                    errorMsg = LABELS.NEW_EMAIL_NOT_MATCH[lang]
                    errFields +=' new-inputx confirm-new-input'
                }
                else if(this.validateEmail(this.state.inputs.newEmail)===false){
                    errorMsg = LABELS.INVALID_EMAIL[lang]
                }
            }

        if(errorMsg!==''||errFields!==''){
            this.setState({errorMsg:errorMsg, errFields:errFields, onLoad:false})
        } else {
            this.sendVerificationToNew(lang, this.state.inputs.newEmail)
        }
    }

    sendVerificationToNew=(lang, newEmail)=>{
        let errorCallback =(error)=>{
            // console.log('callback err',error)
            // console.log('callback err.code',error.code)
            switch(error.code){
                case "auth/invalid-login-credentials":this.setState({onLoad:false, errFields:'password-input', errorMsg:LABELS.PW_INCORRECT[lang]}); break;
                case "auth/email-already-in-use": this.setState({onLoad:false, errFields:'new-inputx confirm-new-input', errorMsg:LABELS.NEW_EMAIL_USED[lang]}); break;
                default: this.setState({onLoad:false}); break;
            }
        }

        let successCallback=()=>{
            this.props.exchange.reloadFirebaseUser(()=>this.setState({onLoad:false,hasPendingRequest:true}));
        }

        // submit email change
        UserService.requestEmailChange(
              this.props.exchange.user.uuid
            , this.props.exchange.user.email
            , this.state.inputs.password
            , newEmail
            , successCallback
            , errorCallback);
    }
    
    changeInputHandler=(event)=>{
        if(this.state.onLoad){
            return null;
        }
        let inputs = this.state.inputs;
        let errFields = this.state.errFields;
        let lang = this.props.exchange.language || 'de';
        
        switch(event.target.id){
            case 'old-input': 
                inputs.oldEmail = event.target.value
                errFields=errFields.replace('old-input','');
               
            break;
            case 'password-input': 
                inputs.password = event.target.value
                errFields=errFields.replace('password-input','');
            break;
            case 'new-inputx': 
                inputs.newEmail = event.target.value
                errFields=errFields.replace('new-inputx','');
                if(this.state.errorMsg===LABELS.NEW_EMAIL_NOT_MATCH[lang] || this.state.errorMsg===LABELS.NEW_EMAIL_USED[lang] ){
                    errFields=errFields.replace('confirm-new-input','');
                }
            break;
            case 'confirm-new-input':
                inputs.confirmNewEmail = event.target.value
                errFields=errFields.replace('confirm-new-input','');
                if(this.state.errorMsg===LABELS.NEW_EMAIL_NOT_MATCH[lang]){
                    errFields=errFields.replace('new-inputx','');
                }
             break;
        }
        this.setState({inputs:inputs,errFields:errFields,errorMsg:''})
    }
    
    renderPendingRequest=(lang)=>{
        console.log(this.props.exchange.user.requests.emailChange)
        return (
            <div className='change-email-page page' style={{justifyContent:'center',display:'flex',pointerEvents:this.state.onLoad?'none':''}}>
                <div className='page-content page-box-1' style={{width:'500px'}}>
                    <h2>{LABELS.UPDATE_EMAIL[lang]}</h2>
                    <br/>
                    <p>
                        <span>{LABELS.CHECK_MAILBOX_1[lang]}</span>
                        <span style={{fontWeight:'600'}}>{this.props.exchange.user.requests.emailChange.newEmail}</span>
                        <span>{LABELS.CHECK_MAILBOX_2[lang]}</span>
                    </p>
                    <br/>
                    <p>{LABELS.EMAIL_CHANGE_USE[lang]}</p>
                    <br/>
                    <p onClick={this.resendEmail}>
                        <span>{LABELS.NOT_IN_INBOX[lang]} </span>
                        <Link>{LABELS.RESEND[lang]}</Link>
                    </p>
                    <br/>
                    <Link className='btn1' to='/'>{LABELS.GOTO_DASHBOARD[lang]}</Link>
                </div>
            </div>
        )
    }

    resendEmail=()=>{

        let inputsz = this.state.inputs;
        inputsz.newEmail = this.props.exchange.user.requests.emailChange.newEmail
        inputsz.confirmNewEmail = this.props.exchange.user.requests.emailChange.newEmail
        this.setState({hasPendingRequest:false, onResend: true, inputs: inputsz });
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        if(this.state.hasPendingRequest){
            return this.renderPendingRequest(lang);
        }
        return (
            <div className='change-email-page page' style={{justifyContent:'center',display:'flex',pointerEvents:this.state.onLoad?'none':''}}>
                <div className='page-content page-box-1' style={{width:'500px'}}>
                    <h2>{LABELS.UPDATE_EMAIL[lang]}</h2>
                    <br/>
                    <div>
                        <p className='ititle'>{LABELS.OLD_EMAIL[lang]}</p>
                        <TextField 
                            id="old-input" variant="outlined"
                            error={this.state.errFields.includes('old-input')}
                            value={this.state.inputs.oldEmail} onChange={this.changeInputHandler}
                            disabled={true}
                            sx={{marginBottom:'12px',width:'100%'}}/>
                    

                        <p className='ititle'>{LABELS.PASSWORD[lang]}</p>

                        <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                            <OutlinedInput
                                id="password-input" error={this.state.errFields.includes('password-input')}
                                value={this.state.inputs.password}
                                onChange={this.changeInputHandler} 
                                type={this.state.showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                        edge="end">
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }/>
                        </FormControl>

                        <p className='ititle'>{LABELS.NEW_EMAIL[lang]}</p>
                        <TextField 
                            id="new-inputx" variant="outlined"
                            error={this.state.errFields.includes('new-inputx')}
                            disabled={this.state.onResend}
                            value={this.state.inputs.newEmail} onChange={this.changeInputHandler}
                            sx={{marginBottom:'12px',width:'100%'}}/>

                    
                        <p className='ititle'>{LABELS.CONFIRM_NEW_EMAIL[lang]}</p>
                        <TextField 
                            id="confirm-new-input" variant="outlined"
                                disabled={this.state.onResend}
                            error={this.state.errFields.includes('confirm-new-input')}
                            value={this.state.inputs.confirmNewEmail} onChange={this.changeInputHandler}
                            sx={{marginBottom:'12px',width:'100%'}}/>

                        <div>

                        <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>
                        <br/>
                            <button className='btn1' tabIndex={-1} onClick={this.onSaveNewEmailClicked}>{
                                this.state.onLoad?
                                <CircularProgress sx={{color:'white'}} size={'26px'}/>
                                :this.state.onResend?LABELS.RESEND[lang]:LABELS.SAVE[lang]}</button>
                            <br/>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default ChangeEmailPage;