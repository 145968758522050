import React, { Component } from 'react';

import { getAuth } from "firebase/auth";
import {USERS_TABLE} from '../../firebase/constants';
import './LoginSignup.css'
import {db} from '../../firebase/firebase';
import { ref, update } from "firebase/database";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Util from '../../util/Util.js'
import {createUserWithEmailAndPassword,sendEmailVerification } from 'firebase/auth';
import {isMobile} from 'react-device-detect';
import { MODAL } from '../../main/ModalController';
import { LABELS } from '../../Labels';
import ContactExpert from '../../components/ContactExpert';

const auth = getAuth();

class SignupPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             STAGE:0,
             onValidate:false
            ,inputs:{
                 email:''
                ,password:''
                ,confirmPassword:''
                ,acceptTerms:false
                ,acceptDataProtection:false
                ,company:{
                    name:'',
                    legalForm:'',
                    streetNr:'',
                    address:'',
                    zip:'',
                    city:'',
                    phone:'',
                }
                ,contact:{
                    title:'',
                    firstname:'',
                    lastname:'',
                    position:'',
                    phone:'',
                    email:''
                }
            }

            ,firebaseUser:null
            ,onLoad:false
            ,errorMsg:''
            ,errFields:''
            ,showPassword:false
            ,showConfirmPassword:false
            ,width:window.innerWidth
        }
    }

 
    async componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
         this.setState({width:window.innerWidth});
    }

    stage0ChangeInputHandler=(event)=>{
        if(this.state.onValidate){
            return
        }
        let errFields = this.state.errFields;
        let inputs = this.state.inputs;
        let errEmail = this.state.errEmail;
        switch(event.target.id){
            case 'email-input':
                inputs.email = event.target.value;
                errFields=errFields.replace('email','');
                errEmail ='';
            break;
            case 'password-input':
                inputs.password = event.target.value;
                errFields=errFields.replace('passwordx','');
            break;
            case 'confirm-password-input':
                inputs.confirmPassword = event.target.value;
                errFields=errFields.replace('confirmPassword','');
            break;
            default:break;
        }

        this.setState({inputs:inputs,errFields:errFields,errorMsg:'',errEmail:errEmail});

    }

    stage1ChangeInputHandler=(event)=>{
        let company = this.state.inputs.company;
        let contact = this.state.inputs.contact;
        let errFields = this.state.errFields;
        switch(event.target.id){
            case 'company-name-input':
                company.name = event.target.value;
                errFields=errFields.replace('companyName','');
            break;
            case 'company-streetnr-input':
                company.streetNr = event.target.value;
                errFields=errFields.replace('companyStreetNr','');
            break;
            case 'company-address-input':
                company.address = event.target.value;
                errFields=errFields.replace('companyAddress','');
            break;
            case 'company-zip-input':
                company.zip = event.target.value;
                errFields=errFields.replace('companyZip','');
            break;
            case 'company-city-input':
                company.city = event.target.value;
                errFields=errFields.replace('companyCity','');
            break;
            case 'company-phone-input':
                company.phone = event.target.value;
                errFields=errFields.replace('companyPhone','');
            break;


            case 'contact-firstname-input':
                contact.firstname = event.target.value;
                errFields=errFields.replace('contactFirstname','');
            break;
            case 'contact-lastname-input':
                contact.lastname = event.target.value;
                errFields=errFields.replace('contactLastname','');
            break;
            case 'contact-position-input':
                contact.position = event.target.value;
                errFields=errFields.replace('contactPosition','');
            break;
            case 'contact-phone-input':
                contact.phone = event.target.value;
                errFields=errFields.replace('contactPhone','');
            break;
            case 'contact-email-input':
                contact.email = event.target.value;
                errFields=errFields.replace('contactEmail','');
            break;

        } 
        let inputs = this.state.inputs;
        inputs.company = company;
        inputs.contact = contact;
        this.setState({inputs:inputs,errFields:errFields})
    }


    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validInput=async ()=>{
        let lang = this.props.exchange.language || 'de';
        if(this.state.STAGE===0){
            let errFields ='';
            let errorMsg='';
            let errEmail = '';

            if(this.state.inputs.email===''){
                errFields = errFields+' email';
                errorMsg = LABELS.INVALID_EMAIL[lang];
            }
             
            if(this.validateEmail(this.state.inputs.email)===false){
                errFields = errFields+' email';
                errorMsg = LABELS.INVALID_EMAIL[lang];
                errEmail=LABELS.INVALID_EMAIL[lang];
            }

             if(this.state.inputs.password===''){
                errFields = errFields+' passwordx';
                if(errorMsg=='' && errEmail==''){
                    errorMsg = LABELS.ENTER_PASSWORD[lang];
                }
            }
            if(this.state.inputs.confirmPassword===''){
                errFields = errFields+' confirmPassword';
                if(errorMsg==''&&errEmail==''){
                    errorMsg = LABELS.ENTER_CONFIRM_PASSWORD[lang];
                }
            } else if(this.state.inputs.confirmPassword!=this.state.inputs.password){
                if(errEmail==''){
                    errorMsg=LABELS.PASSWORD_NOT_MATCH[lang];
                    errFields = 'confirmPassword passwordx';
                }
            }

            if(errorMsg==''){
                if(this.state.inputs.acceptTerms===false && this.state.inputs.acceptDataProtection===false){
                    errorMsg = LABELS.NOT_SIGN_TERMS_0[lang];
                } else{
                    if(this.state.inputs.acceptTerms===false){
                        errorMsg = LABELS.NOT_SIGN_TERMS_1[lang];
                    }
                    if(this.state.inputs.acceptDataProtection===false){
                        errorMsg = LABELS.NOT_SIGN_TERMS_2[lang];
                    }
                }
            }
            
            if(errorMsg!='' || errFields!='' || errEmail!=''){
                this.setState({errorMsg:errorMsg,errFields:errFields,errEmail:errEmail, onValidate:false});
            }   
            else {
                this.setState({errorMsg:'',errFields:'',errEmail:''},()=>{
                    this.createAccount();
                })
            }
            
        }else if(this.state.STAGE===1){
            let errFields ='';
            let errorMsg = '';
            if(this.state.inputs.company.name==''){
                errFields = errFields+' companyName';
            }
            if(this.state.inputs.company.streetNr==''){
                errFields = errFields+' companyStreetNr';
            }
            if(this.state.inputs.company.city==''){
                errFields = errFields+' companyCity';
            }
            // if(this.state.inputs.company.address==''){
            //     errFields = errFields+' companyAddress';
            // }
            if(this.state.inputs.company.zip==''){
                errFields = errFields+' companyZip';
            }
            if(this.state.inputs.company.phone==''){
                errFields = errFields+' companyPhone';
            }
            if(this.state.inputs.company.legalForm==''){
                errFields = errFields+' companyLegalForm';
            }
                
            if(this.state.inputs.contact.title==''){
                errFields = errFields+' contactTitle';
            }
            if(this.state.inputs.contact.firstname==''){
                errFields = errFields+' contactFirstname';
            }
            if(this.state.inputs.contact.lastname==''){
                errFields = errFields+' contactLastname';
            }
            if(this.state.inputs.contact.phone==''){
                errFields = errFields+' contactPhone';
            }
            if(this.state.inputs.contact.position==''){
                errFields = errFields+' contactPosition';
            }
            if(this.state.inputs.contact.email==''){
                errFields = errFields+' contactEmail';
            }else if(this.validateEmail(this.state.inputs.contact.email)===false){
                errFields = errFields+' contactEmail';
                errorMsg=LABELS.INVALID_EMAIL[lang];
            }


            let isComplete = errFields==='';
            if(!isComplete && errorMsg===''){
                errorMsg=LABELS.ERROR_MSG_2[lang];
            }

            this.setState({errFields:errFields,onValidate:isComplete,errorMsg:errorMsg},()=>{
                if(isComplete){
                   console.log('kumpletos rekados')
                }
            });

        }
    }

    createAccount=async()=>{

        let lang = this.props.exchange.language || 'de';
        createUserWithEmailAndPassword(auth, this.state.inputs.email, this.state.inputs.password)
        .then((response)=>{
            this.signUpFirebaseUser(response.user).then(()=>{
                sendEmailVerification(response.user).then(()=>{
                    getAuth().signOut();
                    this.props.exchange.setModal(MODAL.SIGNUP_SUCCESS)
                }).catch((verErr)=>{
                    this.setState({onValidate:false,errorMsg:'Something went wrong'});  
                });
            });
            
        }).catch((error)=>{
            console.log('SP0001 error:' +error.code,JSON.stringify(error))
            switch(error.code){
                case "auth/email-already-in-use": this.setState({errorMsg:LABELS.EMAIL_EXIST[lang],onValidate:false}); break; 
                case "auth/weak-password": this.setState({onValidate:false,errorMsg:'Password does not meet requirements'}); break; 
                case "auth/network-request-failed": this.setState({onValidate:false,errorMsg:'Network error. Check your connection and try again.'}); break; 
                default: this.setState({onValidate:false,errorMsg:error.code}); break; 
            }
        });
    }

    signUpFirebaseUser=async(firebaseUser)=>{

        let user = {}
        user.username = firebaseUser.uid
        user.uuid     = firebaseUser.uid
        user.email    = firebaseUser.email
        user.providerName = 'password'
        user.company = this.state.inputs.company;
        user.contact = this.state.inputs.contact;

        let dateNow = (new Date()).toISOString()
        user.company.lastUpdated = dateNow
        user.contact.lastUpdated = dateNow
        user.dateCreated = dateNow
        
        let userPath = USERS_TABLE + '/' + firebaseUser.uid
        const updates = {};
        updates[userPath] = user;

        return update(ref(db), updates);
    }

    signUpClicked=async ()=>{
        if(this.state.onValidate){
            return null
        }
        this.setState({onValidate:true},this.validInput);
    }

    readTerms1=(e)=>{
        Util.openInNewTab('/terms/agb')
    }
    readTerms2=(e)=>{
        Util.openInNewTab('/terms/dse')
    }
    toggleAcceptTerms=(e)=>{
        let inputss = this.state.inputs;
        if(e.target.type==='checkbox'){
            inputss.acceptTerms = e.target.checked;
        }else{
            inputss.acceptTerms= !inputss.acceptTerms;
        }
        this.setState({inputs:inputss});
    }

    toggleDataProtection=(e)=>{
        if(this.state.onValidate){
            return null;
        }
        let inputss = this.state.inputs;
        if(e.target.type==='checkbox'){
            inputss.acceptDataProtection = e.target.checked;
        }else{
            inputss.acceptDataProtection= !inputss.acceptDataProtection;
        }
        this.setState({inputs:inputss});
    }

    
    renderStage1=(lang)=>{

        let twoInputs =(id1,label1,errorId1,val1,  id2,label2,errorId2,val2)=>{
            return(
                <div style={{display:'flex'}}>
                    <div style={{width:'100%',marginRight:'18px'}}>
                        {id1!=null&&
                        <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{label1}</p>}
                        {id1!=null&&
                        <TextField id={id1} value={val1}  error={this.state.errFields.includes(errorId1)}
                            variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>}
                    </div>

                    <div style={{width:'100%'}}>
                        <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{label2}</p>
                        <TextField id={id2} value={val2}  error={this.state.errFields.includes(errorId2)}
                            variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                    </div>
                </div>
            )
        }

        return (
            <div className='stage-1'>
                <div className='company-inputs'>
                    <h2>{LABELS.PRACTICE_DETAILS[lang]}</h2>
                    <br/> 
                    <div style={{display:'flex'}}>
                        <div style={{width:'100%',marginRight:'18px',minWidth:'50%'}}>
                            <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{LABELS.COMPANY_NAME[lang]}</p>
                            <TextField id="company-name-input" value={this.state.inputs.company.name}  error={this.state.errFields.includes('companyName')}
                                variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                        </div>

                        <div style={{width:'100%',maxWidth:'50%',overflow:'hidden'}}>
                            <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{LABELS.LEGAL_FORM[lang]}</p>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    error={this.state.errFields.includes('companyLegalForm')}
                                    value={this.state.inputs.company.legalForm}
                                    onChange={(e)=>{
                                        let inputs = this.state.inputs;
                                        let company = inputs.company;
                                        company.legalForm = e.target.value;
                                        inputs.company = company;
                                        let errFields = this.state.errFields;
                                        errFields=errFields.replace('companyLegalForm','');
                                        this.setState({inputs:inputs, errFields:errFields})
                                    }}
                                >
                                    <MenuItem value={'AG'}>AG</MenuItem>
                                    <MenuItem value={'GmbH'}>GmbH</MenuItem>
                                    <MenuItem value={'Einzelfirma'}>Einzelfirma</MenuItem>
                                    <MenuItem value={'Einfache Gesellschaft'}>Einfache Gesellschaft</MenuItem>
                                    <MenuItem value={'Andere'}>Andere</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {twoInputs("company-streetnr-input", LABELS.STREET_NR[lang], "companyStreetNr",  this.state.inputs.company.streetNr,
                                "company-address-input", LABELS.ADDRESS[lang],   "companyAddress",  this.state.inputs.company.address)}

                    {twoInputs("company-zip-input",   LABELS.ZIP[lang],   "companyZip",  this.state.inputs.company.zip,
                                "company-city-input", LABELS.CITY[lang],  "companyCity", this.state.inputs.company.city)}

                    {twoInputs(null, null,null,null,
                                "company-phone-input", LABELS.DOCTOR_PHONE[lang],  "companyPhone", this.state.inputs.company.phone)}
                </div>

                <div className='contact-person-inputs'>
                    <h2>{LABELS.CONTACT_PERSON[lang]}</h2>
                    <br/> 

                    <div style={{display:'flex'}}>
                        <div style={{width:'100%',marginRight:'18px'}}>
                            <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{LABELS.TITLE[lang]}</p>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.inputs.contact.title}
                                    error={this.state.errFields.includes('contactTitle')}
                                    onChange={(e)=>{
                                        let inputs = this.state.inputs;
                                        let contact = inputs.contact;
                                        contact.title = e.target.value;
                                        inputs.contact = contact;
                                        let errFields = this.state.errFields;
                                        errFields=errFields.replace('contactTitle','');
                                        this.setState({inputs:inputs, errFields:errFields})
                                    }}
                                >
                                    <MenuItem value={'Frau'}>Frau</MenuItem>
                                    <MenuItem value={'Herr'}>Herr</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    
                        <div style={{width:'100%'}}>
                            <p style={{marginBottom:'6px',fontSize:'0.85rem'}}>{LABELS.FIRSTNAME[lang]}</p>
                            <TextField id="contact-firstname-input" value={this.state.inputs.contact.firstname}  error={this.state.errFields.includes('contactFirstname')}
                                variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                        </div>
                    </div>

                    {twoInputs("contact-lastname-input", LABELS.LASTNAME[lang],  "contactLastname",  this.state.inputs.contact.lastname,
                                "contact-position-input", LABELS.POSITION[lang], "contactPosition",  this.state.inputs.contact.position)}

                    {twoInputs("contact-phone-input", LABELS.TELEPHONE_QUERIES[lang],  "contactPhone",  this.state.inputs.contact.phone,
                                "contact-email-input", LABELS.CONTACT_EMAIL[lang],     "contactEmail",  this.state.inputs.contact.email)}

                </div>
            </div>

        )
    }

    renderStage0=(lang)=>{
        return (
            <div className='stage-0' style={{pointerEvents:this.state.onValidate?'none':''}}>
            <h2>{LABELS.DATA_ACCESS[lang]}</h2>
             <br/> 
            <p className='ititle'>{LABELS.EMAIL[lang]}</p>
            <TextField 
                id="email-input" variant="outlined"
                autoComplete='off'
                helperText={this.state.errEmail} error={this.state.errFields.includes('email')}
                value={this.state.inputs.email} onChange={this.stage0ChangeInputHandler}
                sx={{marginBottom:'20px',width:'100%'}}/>

            <p className='ititle'>{LABELS.PASSWORD[lang]}</p>
            <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                <OutlinedInput
                    id="password-input" helperText={this.state.errFields.includes('passwordx')?LABELS.INPUT_REQ[lang]:''} error={this.state.errFields.includes('passwordx')}
                    value={this.state.inputs.password}
                    onChange={this.stage0ChangeInputHandler} autoComplete='off'
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                            edge="end">
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }/>
            </FormControl>

            <p className='ititle'>{LABELS.CONFIRM_PASSWORD[lang]}</p>
            <FormControl sx={{ m: 1, width: '100%',margin:'0px' }} variant="outlined">
                <OutlinedInput
                    id="confirm-password-input" helperText={this.state.errFields.includes('confirmPassword')?LABELS.INPUT_REQ[lang]:''} error={this.state.errFields.includes('confirmPassword')}
                    value={this.state.inputs.confirmPassword}
                    onChange={this.stage0ChangeInputHandler} 
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>{this.setState({showConfirmPassword:!this.state.showConfirmPassword});}}
                            edge="end">
                            {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }/>
            </FormControl>
            
            <div style={{fontSize:'0.9rem',width:'80%',marginTop:'2em'}}>
                <div className='terms-cb-div' style={{display:'flex',alignItems:'center'}}>
                    <Checkbox  checked={this.state.inputs.acceptTerms} onChange={this.toggleAcceptTerms} sx={{fontSize:'0.5rem'}}/>
                    <div>
                        <span onClick={this.toggleAcceptTerms} style={{cursor:'pointer'}}>
                            {LABELS.ACCEPT_TERMS_1[lang]}
                        </span>
                        <span onClick={this.readTerms1} style={{cursor:'pointer', fontWeight:'700'}}>
                            {LABELS.ACCEPT_TERMS_2[lang]}
                        </span>
                    </div>
                </div>


                <div className='data-protect-cb-dib' style={{display:'flex',alignItems:'center'}}>
                    <Checkbox checked={this.state.inputs.acceptDataProtection} onChange={this.toggleDataProtection} sx={{fontSize:'0.5rem'}}/>
                    <div>
                        <span onClick={this.toggleDataProtection} style={{cursor:'pointer'}}>
                            {LABELS.ACCEPT_DATA_PROTECTION_1[lang]}
                        </span>
                        <span onClick={this.readTerms2} style={{cursor:'pointer', fontWeight:'700'}}>
                            {LABELS.ACCEPT_DATA_PROTECTION_2[lang]}
                        </span>
                    </div>
                </div>

            </div>
                
        </div>
        )
    }

    renderSignUpDiv=(lang)=>{
        let isSmall = isMobile || this.state.width<700;
        return (
            <div className={'ls-component ' +(isSmall?'ls-component-mobile':'')}
            style={{width:isSmall?'100vw':'40vw',maxWidth:'600px', minWidth:isSmall?'':'450px',pointerEvents:this.state.onLoad?'none':''}}>
                <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>
                {this.state.STAGE===0&&this.renderStage0(lang)}
               
                {this.state.STAGE===1&& this.renderStage1(lang)}
                <br/>
                <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>
                <br/>
                <button className='btn1' onClick={this.signUpClicked} tabIndex={-1}>
                    {this.state.onValidate?<CircularProgress size='26px' sx={{color:'white'}}/>:
                    <p>{this.state.STAGE===0?
                        LABELS.PROCEED[lang]:
                        LABELS.SAVE_AND_PROCEED[lang]}</p>}
                </button>
            </div>
        );
    }

    render() { 
        let lang = this.props.exchange.language || 'de';
        let isSmall = isMobile || this.state.width<700;
        let content = null;
 
        content = this.renderSignUpDiv(lang); 

        return (
            <div className={isMobile?'signup-page-mobile':'signup-page'}>
                <div className='signup-page-content' style={{display:isSmall?'':'flex',alignItems:'center',backgroundColor:'white',justifyContent:'center',  width:'100%',pointerEvents:this.state.onLoad?'none':''}}>
                    {content}
                    
                    <div className='right-dddvi' style={{width:isSmall?'100%':'fit-content', padding:isSmall?'10px':'10px 30px 0px 0px'}}>
                        <ContactExpert isSmall={isSmall}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignupPage;