import React, { Component } from 'react';

class DSTerms extends Component {
    render() {
        return (
            <div style={{padding:'6vh 10vw'}}>
            <h3>Datenschutzerklärung</h3>
            <p>vom 25. Juni 2024</p>

            <br/>
            <h4>1. Grundsatz</h4>
            <p>Wenn Sie die Webapp medcomply (nachfolgend «Webapp») nutzen oder die Website www.medcomply.ch (nachfolgend «Website») besuchen, werden die Daten im Europäischen Raum gehostet. Wir profitieren damit von einem hohen Datenschutzniveau bei unserem Auftragsverarbeiter.</p>

            <br/>
            <p>Unser erklärtes Ziel ist es, die von uns gesammelten Personendaten (nachfolgen auch "Daten") auf ein Minimum zu reduzieren. Soweit wir Personendaten bearbeiten, tun wir dies zu den nachfolgenden Zwecken und befolgen die Grundsätze der Rechtmässigkeit, der Bearbeitung nach Treu und Glaube und der Transparenz. </p>
            <br/>

            <h4>2. Name und Anschrift der Verantwortlichen Person</h4>  
            <p>transcendis GmbH</p>
            <p>Zentralstrasse 15</p>
            <p>8003 Zürich</p>

<br/>

<h4>3. Datenerfassung und Verwendungszweck</h4>
    <div style={{marginLeft:'6px'}}>
    <p>3.1 Besuch der Website – Hosting Provider</p>
    <p>Wir bearbeiten und erfassen über unseren Provider die IP Adressen der Website-Besucher, gegebenenfalls die Art des Endgerätes, Browserversion, Betriebssystem, technisch notwendige Logdaten (Cookies) sowie weitere technische Daten zum Endgerät der Website-Besucher, damit wir die Website betreiben können und den Inhalt ausliefern können. Diese Daten gehen an den Hosting-Provider. Soweit die personenbezogenen Daten in Logfiles gespeichert werden, werden diese Dateien nur so lange aufbewahrt, wie dies für die jeweilige Datenverarbeitung erforderlich ist. Soweit ein Transfer ins Ausland erfolgt, erfolgt dies im Raum der EU.</p>
    <br/>

    <p>3.2 Besuch der Website – Google Analytics</p>
    <p>Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“) sowie weitere Produkte von Google (so insb. Google Ads). Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Für weitere Informationen zur Verwendung der durch Google erhobenen Personendaten verweisen wir auf https://policies.google.com/privacy?hl=de  
    </p>
    <p>Um die Erfassung durch Google Analytics geräteübergreifend zu verhindern, können Sie sodann über https://tools.google.com/dlpage/gaoptout?hl=de ein Browser Add-on installieren.
    </p>

    <br/>
    <p>3.3 Bei der Registrierung (Konto-Eröffnung)</p>
    <p>Wenn Sie sich registrieren, um ein Konto zu eröffnen, speichern wir die von Ihnen erfassten Daten gemäss Formular in der Eingabe-Maske, so insb. die Firma der Praxis, und den Vornamen, den Namen, PLZ, Ort, Land und die E-Mail Adresse der Kontaktperson. Diese Daten verwenden wir für die Abwicklung des Vertrages und zur Bereitstellung des Dienstes. Sodann erlauben wir uns, Sie über die angegebene E-Mail Adresse über neue Angebote von uns zu informieren. Es besteht die Möglichkeit, sich von diesem Dienst abzumelden. 
    </p>
    <br/>
    <p>3.4 Zahlungsabwicklung</p>
    <p>Wir verwenden den Zahlungsdienst von Stripe Payments Europe, Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland, (nachfolgend stripe) für die Abwicklung der Zahlung. Für die Daten, welche stripe von Ihrem Kreditkartenanbieter erhält, verweisen wir auf die Datenschutzerklärung von stripe unter https://stripe.com/en-ch/privacy.
    </p>
    <p>Wir erhalten von stripe Zugang zu folgenden Daten der Nutzer: Datum der erfolgreiche Zahlung, letzten 4 Ziffern der Kreditkarte, verwendete Kreditkarte, Herkunft der Zahlung, verwendetes Betriebssystem und verwendeter Browser bei der Registrierung</p>
</div>

<br/>

<h4>4. Rechte als betroffene Person</h4>
<p>Werden personenbezogene Daten von Ihnen verarbeitet, haben Sie - sofern betroffen im Sinne der EU-DSGVO - folgende Rechte gegenüber dem Verantwortlichen: Auskunftsrecht, Recht auf Berichtigung, Recht auf Einschränkung der Verarbeitung, Recht auf Löschung, Recht auf Benachrichtigung, Widerspruchsrecht und Recht auf Datenübertragbarkeit (Art. 15ff DSGVO). 
</p>
<p>Analog in der Schweiz ein Auskunftsrecht über Ihre Personendaten, Datenübertragbarkeit, Berichtigung Ihrer Personendaten, Widerspruch zu einer Bearbeitung, Recht auf Löschung, Recht auf Einschränkung einer Bearbeitung, auf Bestreitungsvermerk, sowie Berichtigung, Löschung oder Vernichtung etc. (Art. 25, 28, 32, 30 Abs. 2, 32 Abs. 3 sowie Art. 32 Abs. 4 DSG)
</p>

<p>Kontaktieren Sie uns über info@medcomply.ch , wenn Sie ein Anliegen oder eine Frage zur Bearbeitung durch transcendis GmbH haben. Gerne helfen wir Ihnen weiter. 
</p>

<br/>

<h4>5. Dauer der Datenerfassung
</h4>
<p>Wir bewahren die unter Ziffer 3 aufgeführten erhobenen Personendaten nur solange auf, als dies zur angegebenen Zweckverfolgung nötig ist. Vorbehalten sind längere gesetzliche Aufbewahrungspflichten. Dies betrifft insbesondere Rechnungs- und Zahlungsdaten des Kunden.
</p>

<br/>

<h4>6. Auftragsbearbeitung</h4>
<p>transcendis GmbH verarbeitet Personendaten von Nutzern von medcomply nur für eigene Zwecke, wie in Ziffer 3 dargelegt. transcendis GmbH verarbeitet keine Personendaten oder Patientendaten von Nutzern bzw. Arztpraxen.</p>
            </div>
        );
    }
}

export default DSTerms;