import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { LABELS } from '../Labels';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import LoginPage from './loginsignup/LoginPage'
import { getAuth, signInWithEmailAndPassword, updatePassword } from "firebase/auth";

const auth = getAuth();
class LoginBypass extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount(){
        let lang = this.props.exchange.language || 'de';
        this.props.exchange.setBrowserTitle('Bypass Login | Medcomply');
        window.scrollTo(0, 0);
    }
    
    inputDefaults=()=>{
        return {
             oldPassword:''
            ,newPassword:''
            ,confirmNewPassword:''
        }
    }
       

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='change-password-page page'>
                 <div style={{margin:'16px',textAlign:'center',padding:'4px',color:'rgb(4, 139, 173)',fontSize:'1.5rem',backgroundColor:'gainsboro',borderRadius:'10px'}}>
                    {'Bypass Login'}
                    </div>
                <LoginPage exchange={this.props.exchange}/>
            </div>
        );
    }
}

export default LoginBypass;