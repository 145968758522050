import React, { Component } from 'react';
import MasterDataPage from '../MasterDataPage';
import { isMobile } from 'react-device-detect';
import { LABELS } from '../../Labels';

class InitialMasterDataSetup extends Component {
    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='init-master-data-modal' style={{display:'flex',overflow:'hidden', maxHeight:isMobile?'95vh':'90vh', width:isMobile?'95vw':'70vw', maxWidth:'600px', minWidth:'480px'}}>
                <div className='master-data-page-wrap' style={{width:'100%',padding:'30px',overflowY:'scroll'}}>
                    <h2 style={{fontWeight:'300'}}>{LABELS.WELCOME_TO[lang] + ' ' + 'Medcomply'}</h2>
                    <p style={{fontWeight:'300'}}>{LABELS.SETUP_YOUR_PROFILE_1[lang]}</p>
                    <div style={{borderBottom:'solid 1px gainsboro',marginTop:'12px'}}></div>
                    <MasterDataPage exchange={this.props.exchange} hasDiscard={false} onSaveSuccessCallback={this.props.onSaveSuccessCallback} isFullScreen={false}/>
                </div>
            </div>
        );
    }
}

export default InitialMasterDataSetup;