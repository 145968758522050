import { jsPDF } from "jspdf";
import allgemein from './allgemein.txt'
import moment from "moment";
const arialNormal =  './fonts/Arial-normal.js'
//https://github.com/parallax/jsPDF

export const datenschutzerklärungPatient = async(props,callback) => {
    props= {
     arztpraxis:props.user.company.name
    ,rechtsform:props.user.company.legalForm
    ,strasseNr:props.user.company.streetNr
    ,plz:props.user.company.zip
    ,stadt:props.user.company.city

    ,kontakt:{
            anrede:props.user.contact.title
        ,nachname:props.user.contact.lastname
        ,email:props.user.contact.email
    }
    ,inputs:props.inputs 
    }
    const doc = new jsPDF();
    console.log('Show all font in jsPDF',doc.getFontList());
//Courier ,Helvetica, 
// Times
// "Roman"
// "Bold"
// "Italic"
    // doc.addFileToVFS("Arial-normal.ttf", arialNormal);
    // doc.addFont("Arial-normal.ttf", "ArialNormal", "normal");

    let y=14;
    let textMiddle=(txt,yyy)=>{
        doc.text(txt,100-doc.getCharWidthsArray(txt,null).reduce((partialSum, a) => partialSum + a, 0)*2, yyy)
    }

    doc.setFontSize(12); doc.setFont('Times','Roman');
    textMiddle(props.arztpraxis + ", " + props.strasseNr +" "+ props.plz + " " + props.stadt,  y);y+=5;

    doc.line(20, y, 190, y, null);y+=15;

    doc.setFontSize(14); doc.setFont('Times','Bold');
    textMiddle("Datenschutzerklärung",y);y+=10;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("A. Grundsatz", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Times','')
    doc.text('Ihre Personendaten (nachfolgen auch "Daten") erheben und bearbeiten wir nur zu den in der die', 20, y); y+=5;
    doc.text('Datenschutzerklärung beschriebenen Zwecken und im dafür notwendigen Umfang. Wir befolgen', 20, y); y+=5;
    doc.text('Grundsätze der Rechtmässigkeit, der Bearbeitung nach Treu und Glaube und der Transparenz.', 20, y); y+=5;
    doc.text('Wir bewahren Ihre Personendaten nur soweit und solange auf, als dies zum kommunizierten Zweck', 20, y); y+=5;
    doc.text('nötig ist oder wir von Gesetzes wegen dazu verpflichtet sind. Inhaltlich werden die Daten sachlich', 20, y); y+=5;
    doc.text('richtig und auf dem neusten Stand gespeichert. Sodann schützen wir Ihre Personendaten durch ', 20, y); y+=5;
    doc.text('technische und organisatorische Sicherheitsvorschriften.', 20, y); y+=16; 

    //------------------------------------------------------------------------------------------
    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("B. Name und Anschrift des Verantwortlichen", 20, y); y+=7;

    let fullAddress =  props.arztpraxis + " " + props.rechtsform + ", " +  props.strasseNr + " " +  props.plz + " " + props.stadt + " " + props.kontakt.email;
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text(fullAddress, 20, y); y+=16;



    // //------------------------------------------------------------------------------------------

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("C. Bearbeitungen", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Eröffnung Patientendossier", 20, y); y+=6
    
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Alter, Versicherten-Nr. sowie weitere nötige Stammdaten", 20, y); y+=5; 
    doc.text("für folgende/n Zweck: Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Software", 20, y); y+=5; 
    doc.text("Anbieter. Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von 20", 20, y); y+=5; 
    doc.text("Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y); y+=12;
    
    
    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Führung der Krankengeschichte (elektronisch oder auf Papier)", 20, y); y+=6
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr , Krankengeschichte für folgende/n Zweck:", 20, y); y+=5; 
    doc.text('Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Software Anbieter, Spezialist (Dritte),', 20, y); y+=5;
    doc.text('Spital (Dritte), Weitere med. Leistungserbringer (Dritte). Ohne anderslautende individuelle Vereinbarung', 20, y); y+=5;
    doc.text('löschen wir die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer', 20, y); y+=5;
    doc.text('ins Ausland.', 20, y); y+=12;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Leistungsabrechnung", 20, y);  y+=6
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten medizinische / administrative Leistung für folgende/n Zweck: Behandlung Patient.", 20, y); y+=5;
    doc.text("Diese Daten gehen an folgende/n Empfänger: Software Anbieter. Ohne anderslautende individuelle ", 20, y); y+=5;
    doc.text("Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es erfolgt", 20, y); y+=5;
    doc.text("kein Transfer ins Ausland. ", 20, y); y+=12;
    
    
    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Fakturierung an Versicherung (tier garant) plus Kopie an Patienten ", 20, y); y+=6
    doc.setFontSize(12); doc.setFont('Times','');
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., Medizinische / Administrative Leistung,", 20, y); y+=5;
    doc.text("Minimal Clinical Dataset (z.H. Versicherer) für folgende/n Zweck: Behandlung Patient. Diese Daten", 20, y); y+=5;
    doc.text("gehen an folgende/n Empfänger: Software Anbieter, Zertifizierte Datenannahmestelle (Dritte),", 20, y); y+=5;
    doc.text("Versicherer (Dritte). Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb", 20, y); y+=5;
    doc.text("von 10 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y); y+=12;


    // FOOTER
    doc.setFontSize(10); doc.setFont('Times','')
    doc.text("Praxis "+ props.arztpraxis +", Patientenerklärung" , 20, 280); 
    doc.text("Seite 1 | 4 ", 180, 280); y+=5;
    // FOOTER

    doc.insertPage();
    y=14;

    doc.setFontSize(12); doc.setFont('Times','');
    textMiddle(props.arztpraxis + ", " + props.strasseNr +" "+ props.plz + " " + props.stadt,  y);y+=5;

    doc.line(20, y, 190, y, null);y+=15;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Fakturierung an Patient (tiers payant)", 20, y); y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., Medizinische / Administrative Leistung,", 20, y); y+=5;  
    doc.text("Minimal Clinical Dataset (z.H. Versicherer) für folgende/n Zweck: Behandlung Patient. Diese Daten", 20, y); y+=5;  
    doc.text("gehen an folgende/n Empfänger: Dienstleister , Software Anbieter, Versicherer. Ohne anderslautende", 20, y); y+=5;  
    doc.text("individuelle Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung.", 20, y); y+=5;  
    doc.text("Es erfolgt kein Transfer ins Ausland.", 20, y); y+=10;


    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Bonitätsprüfung", 20, y); y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname für folgende/n Zweck: Sicherstellung Forderung. Diese Daten gehen an", 20, y); y+=5;
    doc.text("folgende/n Empfänger: Bonitäts-Unternehmen (Dritte). Ohne anderslautende individuelle Vereinbarung", 20, y); y+=5;
    doc.text("löschen wir die Daten innerhalb von 3 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y); y+=5;
    doc.text("Ausland. ", 20, y); y+=10;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Kommunikation mit Patienten /Leistungsträger ", 20, y);  y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname sowie die weiteren nötigen Stammdaten, Krankengeschichte für", 20, y); y+=5;
    doc.text("folgende/n Zweck: Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Kommunikation", 20, y); y+=5;
    doc.text("-splatform (Microsoft, HIN). Ohne anderslautende individuelle Vereinbarung löschen wir die Daten", 20, y); y+=5;
    doc.text("innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y); y+=10;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Einsatz von externen Peripheriegeräten (z.B. Langzeit EKG)", 20, y);  y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., Bilder, Auswertungsdaten für folgende/n", 20, y); y+=5;
    doc.text("Zweck: Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Geräte Hersteller (Wartung,", 20, y); y+=5;
    doc.text("Onlinezugriff). Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von", 20, y); y+=5;
    doc.text("20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=8;

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Führung Buchhaltung", 20, y); y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Rechnungsdaten für folgende/n Zweck: Jahresabschluss, Controlling.", 20, y); y+=5;
    doc.text("Diese Daten gehen an folgende/n Empfänger: Treuhänder, Software-Anbieter. Ohne anderslautende", 20, y); y+=5;
    doc.text("individuelle Vereinbarung löschen wir die Daten innerhalb von 10 Jahr nach der letzten Bearbeitung. ", 20, y); y+=5;
    doc.text("Es erfolgt kein Transfer ins Ausland.", 20, y); y+=8;


    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Case Studies", 20, y);  y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Sozialer Status, Hintergrundinformationen, Minimal Clinical Dataset für folgende/n", 20, y); y+=5;
    doc.text("Zweck: Weitergabe von Wissen, Förderung der Forschung im Rahmen von spezifischer Fachliteratur.", 20, y);  y+=5;
    doc.text("Eine De-Anonymisierung kann nicht ausgeschlossen werden. Diese Daten gehen an folgende/n", 20, y);  y+=5;
    doc.text("Empfänger: Forschungswebseite, Ärztekollegen, Qualitätszirkel. Ohne anderslautende individuelle", 20, y);  y+=5;
    doc.text("Vereinbarung löschen wir die Daten innerhalb von 5 Jahre nach der letzten Bearbeitung. Es erfolgt", 20, y);  y+=5;
    doc.text("kein Transfer ins Ausland. ", 20, y);  y+=8

    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Beizug von externen Labors ", 20, y);  y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., Bilder für folgende/n Zweck: Erfüllung", 20, y); y+=5; 
    doc.text("Behandlungsvertrag, Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Labor", 20, y); y+=5; 
    doc.text("Dienstleister und deren Mitarbeiter. Ohne anderslautende individuelle Vereinbarung löschen wir die", 20, y); y+=5; 
    doc.text("Daten innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland. ", 20, y); y+=8; 

    // 
    
    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Überweisung an Spezialisten (Spezialisten, Spital)", 20, y);  y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr. und Status, medizinische / administrative", 20, y); y+=5; 
    doc.text("Leistung, Minimal Clinical Dataset z.H. Spital für folgende/n Zweck: Behandlung Patient.", 20, y); y+=5; 
   

    // FOOTER
    doc.setFontSize(10); doc.setFont('Times','')
    doc.text("Praxis "+ props.arztpraxis +", Patientenerklärung" , 20, 280); 
    doc.text("Seite 2 | 4 ", 180, 280); y+=5;
    // FOOTER

    doc.insertPage();
    y=14;
    doc.setFontSize(12); doc.setFont('Times','');
    textMiddle(props.arztpraxis + ", " + props.strasseNr +" "+ props.plz + " " + props.stadt,  y);y+=5;

    doc.line(20, y, 190, y, null);y+=15;

    doc.text("Diese Daten gehen an folgende/n Empfänger: Spezialist, Spital, Spitalmitarbeiter (Dritte). Ohne", 20, y); y+=5; 
    doc.text("anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach der ", 20, y); y+=5; 
    doc.text("letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y); y+=10; 


    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Übermittlung an Datensammelstelle / Trustcenter (Übergabe an Dritte)", 20, y); y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Minimal Clinical Dataset, Rechnungsdaten anonymisiert für folgende/n Zweck:", 20, y); y+=5; 
    doc.text("Gesetzliche Pflicht gem. KVG. Diese Daten gehen an folgende/n Empfänger: Trustcenter und danach", 20, y); y+=5; 
    doc.text("Krankenkassen. Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von", 20, y); y+=5; 
    doc.text("20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland. ", 20, y); y+=10
   
   
    doc.setFontSize(12); doc.setFont('Times','Bold')
    doc.text("Übermittlung an Behörden", 20, y); y+=5
    doc.setFontSize(12); doc.setFont('Times','')
    doc.text("Wir bearbeiten Daten gemäss den gesetzlicher Informationsvorgaben für  folgende/n Zweck: ", 20, y); y+=5; 
    doc.text("Gesetzliche Informationspflicht gegenüber Behörden (Beispiel: Meldung übertragbarer Krankheiten).", 20, y); y+=5;
    doc.text("Diese Daten gehen an folgende/n Empfänger: Nationale und kantonale Behörden. Ohne anderslautende", 20, y); y+=5;
    doc.text("individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung.", 20, y); y+=5;
    doc.text("Es erfolgt kein Transfer ins Ausland.", 20, y); y+=10;

    let currentPage = 2;
    let putText=(txt,xxx,yy)=>{
        doc.text(txt,xxx,yy);
        if(yy>255){
            currentPage=currentPage+1;
            y+=20;
            doc.setFontSize(10); doc.setFont('Times','')
            doc.text("Praxis "+ props.arztpraxis +", Patientenerklärung" , 20, y); 
            doc.text("Seite "+ currentPage+ " | 4 ", 170, y); y+=5;
            doc.insertPage(); 
            y=14;
            doc.setFontSize(12); doc.setFont('Times','');
            let textMid = props.arztpraxis
            if(props.strasseNr!=null && props.strasseNr!='') {
                textMid += ', ' + props.strasseNr
            }
            if(props.plz!=null && props.plz!='') {
                textMid += ', ' + props.plz
            }
            if(props.stadt!=null && props.stadt!='') {
                textMid += ' ' + props.stadt
            }
            
            textMiddle(textMid,  y);y+=5;

            doc.line(20, y, 190, y, null);y+=15;
        }
    }

    if(props.inputs['factoring']!=null && props.inputs['factoring']){
        doc.setFontSize(12); doc.setFont('Times','Bold')
        putText("Factoring", 20, y);y+=5;
        doc.setFontSize(12); doc.setFont('Times','')
        putText("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., medizinische / administrative Leistung,", 20, y);y+=5;
        putText("Minimal Clinical Dataset (z.H. Versicherer) für folgende/n Zweck: Forderungseinzug. Diese Daten ", 20, y);y+=5;
        putText("gehen an folgende/n Empfänger: Factoring-Unternehmen (Dritte), Versicherer. Ohne anderslautende", 20, y);y+=5;
        putText("individuelle Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung.", 20, y);y+=5;
        putText("Es erfolgt kein Transfer ins Ausland. ", 20, y);y+=10;
    }
    if(props.inputs['inkasso']!=null && props.inputs['inkasso']){
        doc.setFontSize(12); doc.setFont('Times','Bold')
        putText("Inkasso", 20, y);y+=5;
        doc.setFontSize(12); doc.setFont('Times','')
        putText("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., medizinische / administrative Leistung,", 20, y);y+=5;
        putText("Minimal Clinical Dataset (z.H. Versicherer) für folgende/n Zweck: Durchsetzung Forderung. Diese", 20, y);y+=5;
        putText("Daten gehen an folgende/n Empfänger: Inkasso-Unternehmen (Dritte). Ohne anderslautende individuelle", 20, y);y+=5;
        putText("Vereinbarung löschen wir die Daten innerhalb von 3 Jahre nach der letzten Bearbeitung. Es erfolgt ", 20, y);y+=5;
        putText("kein Transfer ins Ausland. ", 20, y);y+=10;
    }
    if(props.inputs['nutzung']!=null && props.inputs['nutzung']){
        doc.setFontSize(12); doc.setFont('Times','Bold')
        putText("Nutzung Terminplattform", 20, y);y+=5;
        doc.setFontSize(12); doc.setFont('Times','')
        putText("Wir bearbeiten Name, Vorname, sowie weitere von Ihnen eingegebenen Daten für folgende/n Zweck:", 20, y);y+=5;
        putText("Behandlung Patient, Terminvereinbarung. Diese Daten gehen an folgende/n Empfänger: Software", 20, y);y+=5;
        putText("-Anbieter, Anbieter Terminbuchungsplattform. Ohne anderslautende individuelle Vereinbarung löschen", 20, y);y+=5;
        putText("wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland. ", 20, y);y+=10;
    }
    

    if(props.inputs['belegarzt']!=null && props.inputs['belegarzt']){ 
        doc.setFontSize(12); doc.setFont('Times','Bold')
        putText("Durchführung Operationen (als Belegarzt)", 20, y);y+=5;
        doc.setFontSize(12); doc.setFont('Times','')
        putText("Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr. und Status, meedizinische/administrative,", 20, y);y+=5;
        putText("Leistung Minimal Clinical Dataset für folgende/n Zweck: Behandlung Patient. Diese Daten gehen an", 20, y);y+=5;
        putText("folgende/n Empfänger: Spital, Spitalmitarbeiter, Mitarbeiter Medizinalgeräte. Ohne anderslautende", 20, y);y+=5;
        putText("individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung.", 20, y);y+=5;
        putText("Es erfolgt kein Transfer ins Ausland.", 20, y);y+=10;

    }
    

    if(props.inputs['administration']!=null && props.inputs['administration']){ 
        doc.setFontSize(12); doc.setFont('Times','Bold')
        putText("Zusammenarbeit mit einer externen Administration", 20, y);y+=5;
        doc.setFontSize(12); doc.setFont('Times','')
        putText("Wir bearbeiten Name, Vorname, Wohnort, Alter, Versicherten-Nr. sowie weitere nötige Stammdaten", 20, y);y+=5;
        putText("für folgende/n Zweck: Behandlung Patient, Abrechnung Leistung. Diese Daten gehen an folgende/n", 20, y);y+=5;
        putText("Empfänger: Externe Administration. Ohne anderslautende individuelle Vereinbarung löschen wir ", 20, y);y+=5;
        putText("die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=10;
    }

    doc.setFontSize(12); doc.setFont('Times','Bold')
    y+=4
    doc.text("D. Rechte als betroffene Person", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Times','')
    putText("-  ein Auskunftsrecht über Ihre Personendaten (Art. 25 DSG)", 20, y);y+=10;
   
    putText("-  Recht auf Datenübertragbarkeit (Art. 28 DSG): Sie haben das Recht, Daten, die wir automatisiert", 20, y);y+=5;
    putText("   bzw. digital verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format", 20, y);y+=5;
    putText("   aushändigen zu lassen. Dies gilt insbesondere auch bei der Weitergabe von medizinischen Daten an ", 20, y);y+=5;
    putText("   eine von Ihnen gewünschte Gesundheitsfachperson. Sofern Sie die direkte Übertragung der Daten an ", 20, y);y+=5;
    putText("   einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist", 20, y);y+=10;
   
    putText("-  das Recht auf Berichtigung Ihrer Personendaten (Art. 32 DSG)", 20, y);y+=10;

    putText("-  Recht auf Widerspruch zu einer Bearbeitung, Recht auf Löschung (soweit gesetzlich keine", 20, y);y+=5;
    putText("   Aufbewahrungspflicht besteht), Recht auf Einschränkung einer Bearbeitung (Art. 30 Abs. 2 DSG) ", 20, y);y+=10;
    
    putText("-  das Recht, dass die Berichtigung, die Löschung, die Vernichtung, das Verbot der Bearbeitung oder", 20, y);y+=5;
    putText("   das Verbot der Bekanntgabe an Dritte bekanntgeben oder veröffentlicht wird (Art. 32 Abs. 4 DSG)", 20, y);y+=12;

    putText("Diese Rechte können allenfalls von gesetzlichen Pflichten eingeschränkt werden, welche uns als", 20, y);y+=5; 
    putText("Praxis verpflichten, Bearbeitungen, Meldungen oder Aufbewahrungen vorzunehmen. Solche Pflichten", 20, y);y+=5; 
    putText("sind beispielsweise die buchhalterische Dokumentationspflicht, oder die Pflicht, eine Krankengeschichte", 20, y);y+=5; 
    putText("zu führen. ", 20, y);y+=8; 

    putText("Das Auskunftsrecht zur Krankengeschichte beschränkt sich auf Kopien der Krankengeschichte, und in", 20, y);y+=5; 
    putText("einem Umfang, in welchem Drittrechte nicht beeinträchtigt werden.", 20, y);y+=9;

    putText("Bei Fragen stehen wir Ihnen gerne zur Verfügung. Bitte kontaktieren Sie ", 20, y);y+=5; 
   
    doc.text(props.kontakt.anrede + " "+ props.kontakt.nachname +", "+ props.kontakt.email , 20, y);y+=5;
    
    y=276;
    currentPage++;
    doc.setFontSize(10); doc.setFont('Times','')
    doc.text("Praxis "+ props.arztpraxis +", Patientenerklärung" , 20, y); 
    doc.text("Seite "+ currentPage+ " | 4 ", 170, y); y+=5;


    doc.save("DatenschutzerklärungPatient.pdf");
    if(callback!=null){
        callback();
    }
}
export const datenschutzerklärungWebsite = async(props,callback) => {

            props= {
             arztpraxis:props.user.company.name
            ,rechtsform:props.user.company.legalForm
            ,strasseNr:props.user.company.streetNr
            ,plz:props.user.company.zip
            ,stadt:props.user.company.city

            ,kontakt:{
                    anrede:props.user.contact.title
                ,nachname:props.user.contact.lastname
                ,email:props.user.contact.email
            }
            ,inputs:props.inputs 
        }
        const doc = new jsPDF();
        let y=10;
        let textMiddle=(txt,yyy)=>{
            doc.text(txt,100-doc.getCharWidthsArray(txt,null).reduce((partialSum, a) => partialSum + a, 0)*2, yyy)
        }
        doc.setFontSize(14); doc.setFont('Times','Bold');
        textMiddle("Datenschutzerklärung",y);y+=10;

        doc.setFontSize(12); doc.setFont('Times','Bold');
        doc.text("Datenschutzerklärung der " + props.arztpraxis +",",66,y); y+=7;
        
        doc.setFont('Times','');
        textMiddle(moment().format('LL'),y);y+=11;


        doc.setFontSize(12); doc.setFont('Times','Bold')
        doc.text("A. Grundsatz", 20, y); y+=7;

        doc.setFont('Times','')
        doc.text('Ihre Personendaten (nachfolgen auch "Daten") erheben und bearbeiten wir nur zu den in der', 20, y); y+=5;
        doc.text('Datenschutzerklärung beschriebenen Zwecken und im dafür notwendigen Umfang. Wir befolgen',20, y); y+=5;
        doc.text('die Grundsätze der Rechtmässigkeit, der Bearbeitung nach Treu und Glaube und der Transparenz.', 20, y); y+=5;
        doc.text('Wir bewahren Ihre Personendaten solange auf, als dies zum kommunizierten Zweck nötig ist oder', 20, y); y+=5;
        doc.text('wir von Gesetzes wegen dazu verpflichtet sind. Inhaltlich werden die Daten sachlich richtig und', 20, y); y+=5;
        doc.text('auf dem neusten Stand gespeichert. Sodann schützen wir Ihre Personendaten durch technische und', 20, y); y+=5;
        doc.text('organisatorische Sicherheitsvorschriften.', 20, y); y+=12;

        //------------------------------------------------------------------------------------------
        doc.setFont('Times','Bold')
        doc.text("B. Name und Anschrift des Verantwortlichen", 20, y); y+=7;

        let fullAddress =  props.arztpraxis + " " + props.rechtsform + ", " +  props.strasseNr + " " +  props.plz + " " + props.stadt;
        
        doc.setFont('Times','')
        doc.text(fullAddress, 20, y); y+=12;



        //------------------------------------------------------------------------------------------

        
        doc.setFont('Times','Bold')
        doc.text("C. Bearbeitungen", 20, y); y+=7;

        
        doc.setFont('Times','Bold')
        doc.text('Betrieb Website (Provider in der Schweiz)', 20, y);
        
        doc.setFont('Times','')
        doc.text('Wir bearbeiten IP Adressen der Website-Besucher, Art', 97, y); y+=5;
        doc.text('des Endgerätes, gegebenenfalls Browserversion, Betriebssystem, technisch notwendige Logdaten ', 20, y); y+=5;
        doc.text('(Cookies) sowie weitere technische Daten zum Endgerät der Website-Besucher für folgende/n Zweck:', 20, y);y+=5;
        doc.text('Technischer Betrieb der Website. Diese Daten gehen an folgende/n Empfänger: Hosting-Provider.', 20, y);y+=5;
        doc.text('Soweit die personenbezogenen Daten in Logfiles gespeichert werden, werden diese Dateien nur so', 20, y);y+=5;
        doc.text('lange aufbewahrt, wie dies für die jeweilige Datenverarbeitung erforderlich ist. Es erfolgt kein', 20, y);y+=5;
        doc.text('Transfer ins Ausland.', 20, y);y+=12;
 

        //------------------------------------------------------------------------------------------

        
        doc.setFont('Times','Bold')
        doc.text('Nutzung Terminplattform (Anbieter in der Schweiz)', 20, y);

        doc.setFont('Times','')
        doc.text('Wir bearbeiten Name, Vorname, sowie weitere', 117, y);y+=5;
        doc.text('von Ihnen eingegebenen Daten für folgende/n Zweck: Behandlung Patient, Terminvereinbarung. Diese ', 20, y);y+=5;
        doc.text('Daten gehen an folgende/n Empfänger: Anbieter der Terminbuchungsplattform. Ohne anderslautende', 20, y);y+=5;
        doc.text('individuelle Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung.', 20, y);y+=5;
        doc.text('Es erfolgt kein Transfer ins Ausland. ', 20, y);y+=12; 
        //------------------------------------------------------------------------------------------

        doc.setFont('Times','Bold')
        doc.text('Nutzung von Social Media auf Website (mit Nutzer-Einwilligung)', 20, y);

        doc.setFont('Times','')
        doc.text('Wir bearbeiten IP Adresse, ggf', 140, y);y+=5; 
        doc.text('Historie der Websitebesuche und Nutzungsverhalten auf der Website für folgende/n Zweck: Interaktion', 20, y);y+=5; 
        doc.text('mit unseren Besuchern über Social Media, Kundenpflege und Erhöhung des Bekanntheitsgrades. Diese', 20, y);y+=5; 
        doc.text('Daten gehen an folgende/n Empfänger: Social Media Dienstleister wie z.B. Facebook, Instagram und', 20, y);y+=5; 
        doc.text('Linkedin. Ohne anderslautende individuelle Vereinbarung löschen wir die Daten, sobald die fortgesetzte', 20, y);y+=5; 
        doc.text('Zweckerreichung (Interaktion mit unseren Besuchern) nicht mehr gewünscht wird. Für die Bearbeitungen', 20, y);y+=5; 
        doc.text('durch die Social Media Dienstleister verweisen wir auf die Datenschutzerklärung des betreffenden', 20, y);y+=5; 
        doc.text('Anbieters, auf die im Rahmen der Nutzer-Einwilligung hingewiesen wird.', 20, y);y+=12; 

        //------------------------------------------------------------------------------------------

        doc.setFont('Times','Bold')
        doc.text('Anmeldung für Newsletter (Anbieter in der Schweiz)', 20, y);

        doc.setFont('Times','')
        doc.text('Wir bearbeiten Name, Vorname, E-Mail', 117, y);y+=5; 
        doc.text('Adresse sowie die weiteren von Ihnen erfassten Daten bei der Anmeldung zum Newsletter für folgende/n', 20, y);y+=5; 
        doc.text('Zweck: Information über Praxis, Erhalt der Kundentreue. Diese Daten gehen an folgende/n Empfänger: ', 20, y);y+=5; 
        doc.text('Software-Anbieter. Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von', 20, y);y+=5; 
        doc.text('nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.', 20, y);y+=12;


        //------------------------------------------------------------------------------------------
        

        doc.setFont('Times','Bold')
        doc.text("D. Rechte als betroffene Person", 20, y); y+=7;

        doc.setFont('Times','')
        doc.text('-  ein Auskunftsrecht über Ihre Personendaten (Art. 25 DSG)', 20, y);y+=8;

        doc.insertPage();y=26;

        doc.text('-  Recht auf Datenübertragbarkeit (Art. 28 DSG): Sie haben das Recht, Daten, die wir automatisiert', 20, y);y+=5;
        doc.text('   bzw. digital verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren', 20, y);y+=5;
        doc.text('   Format aushändigen zu lassen. Dies gilt insbesondere auch bei der Weitergabe von medizinischen', 20, y);y+=5;
        doc.text('   Daten an eine von Ihnen gewünschte Gesundheitsfachperson. Sofern Sie die direkte Übertragung der', 20, y);y+=5;
        doc.text('   Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar', 20, y);y+=5;
        doc.text('   ist.', 20, y);y+=8;

        doc.text('-  das Recht auf Berichtigung Ihrer Personendaten (Art. 32 DSG)', 20, y);y+=8;

        doc.text('-  Recht auf Widerspruch zu einer Bearbeitung, Recht auf Löschung, Recht auf Einschränkung einer', 20, y);y+=5;
        doc.text('   Bearbeitung (Art. 30 Abs. 2 DSG) ', 20, y);y+=8;


        doc.text('-  das Recht, dass die Berichtigung, die Löschung, die Vernichtung, das Verbot der Bearbeitung oder', 20, y);y+=5;
        doc.text('   das Verbot der Bekanntgabe an Dritte an Dritte bekanntgeben oder veröffentlicht wird', 20, y);y+=5;
        doc.text('   (Art. 32 Abs. 4 DSG)', 20, y);y+=12;

        doc.text('Bei Fragen stehen wir Ihnen gerne zur Verfügung.', 20, y);y+=12;
        doc.text('Bitte kontaktieren Sie ' + props.kontakt.anrede + " "+ props.kontakt.nachname +", "+ props.kontakt.email , 20, y);y+=5;

        doc.save("DatenschutzerklarungWebsite.pdf");
        if(callback!=null){
            callback();
        }
}


//!!! DEPRECATED !!!
export const generatePatientDatenschutz = async(props,callback) => {

    props= {
        arztpraxis:props.user.company.name
       ,rechtsform:props.user.company.legalForm
       ,strasseNr:props.user.company.streetNr
       ,plz:props.user.company.zip
       ,stadt:props.user.company.city

       ,kontakt:{
            anrede:props.user.contact.title
           ,nachname:props.user.contact.lastname
           ,email:props.user.contact.email
       }
       ,inputs:props.inputs 
   }
    const doc = new jsPDF();
    let y=10;
    let textMiddle=(txt,yyy)=>{
        doc.text(txt,100-doc.getCharWidthsArray(txt,null).reduce((partialSum, a) => partialSum + a, 0)*2, yyy)
    }

    let fullAddress =  props.arztpraxis + " " + props.rechtsform + ", " +  props.strasseNr + " " +  props.plz + " " + props.stadt;
    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    textMiddle(fullAddress, y); y+=9;

    doc.line(20, y, 190, y, null);y+=15;

    doc.setFontSize(14); doc.setFont('Arial','Bold',600);
    textMiddle("Patientenformular mit Datenschutz-Erklärung",y);y+=15;
    
    
    doc.setFontSize(12); doc.setFont('Arial','Regular',300)

    doc.text("Vorname", 20, y);
    doc.text("Nachname", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Geschlecht", 20, y);
    doc.text("Geburtsdatum", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Strasse und Nr.", 20, y);
    doc.text("PLZ und Wohnort", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Tel./Mobil", 20, y);
    doc.text("E-Mail", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Beruf", 20, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;
    
    doc.text("Arbeitgeber", 20, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Notfall-Kontaktadresse und –Telefon", 20, y);y+=4;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Zuweisende/r Hausärztin/arzt", 20, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Krankenversicherung", 20, y);
    doc.text("Versicherten-Karten-Nr.", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=12;

    doc.setFontSize(14); doc.setFont('Arial','Bold',900);
    doc.text('Gesetzliche Vertretung', 20, y);
    doc.setFontSize(14); doc.setFont('Arial','Regular',300);
    doc.text('(sofern anwendbar)', 67, y);y+=6;
    
    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    doc.text("Institution", 20, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Vorname", 20, y);
    doc.text("Nachname", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Strasse und Nr.", 20, y);
    doc.text("PLZ und Wohnort", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=5;

    doc.text("Tel./Mobil", 20, y);
    doc.text("E-Mail", 100, y);y+=2;
    doc.line(20, y, 190, y, null);y+=12;


    doc.setFontSize(12); doc.setFont('Arial','Italic',300)

    doc.text("Allgemeine Hinweise:", 20, y);y+=8;
    doc.text("Für sensible Personendaten wird zwischen den medizinischen Leistungserbringern ein ", 20, y);y+=5;
    doc.text("verschlüsselter Datentransfer verwendet (HIN Netzwerk). Die Verwendung von E-Mail ist ", 20, y);y+=5;
    doc.text("hingegen ohne weitere Massnahmen unverschlüsselt. ", 20, y);y+=8;
    doc.text("Das Bundesgesetz über die Krankenversicherung (KVG) sieht vor, dass Patientinnen und ", 20, y);y+=5;
    doc.text("Patienten eine Kopie der Arztrechnung erhalten.", 20, y);y+=12;

    doc.setFontSize(14); doc.setFont('Arial','Bold',900)
    doc.text("Einwilligung", 20, y);y+=5;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    doc.text("Ich bin einverstanden, dass meine Personendaten gemäss der folgenden ", 20, y);y+=5;
    doc.text("Datenschutzerklärung bearbeitet und an die dort erwähnten Personen und Institutionen", 20, y);y+=5;
    doc.text("weitergegeben wird. Dazu zählen z.B. Spezialisten, weitere med. Leistungserbringer,", 20, y);y+=5;
    doc.text("Spitäler, im Rahmen der Abrechnung die zertifizierte Datenannahmestelle, Versicherer,", 20, y);y+=5;
    doc.text("Factoring- oder Inkasso Unternehmen. Gleichermassen bin ich einverstanden, dass", 20, y);y+=5;
    doc.text("administrative Themen (z.B. Terminverschiebungen) auch auf unverschlüsseltem Weg", 20, y);y+=5;
    doc.text("kommuniziert werden dürfen (z.B. via E-Mail Microsoft Exchange).", 20, y);y+=12;

    doc.text("Unterschrift Patient:", 80, y);y+=10;
    doc.line(80, y, 190, y, null);y+=7;
    doc.line(80, y, 190, y, null);y+=5;
    doc.setFontSize(9); doc.setFont('Arial','Regular',300)
    doc.text("Bitte Namen in Blockschrift", 80, y);y+=5;


    doc.insertPage(); y=20;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    textMiddle(fullAddress, y); y+=9;
    doc.line(20, y, 190, y, null);y+=15;

    textMiddle("Datenschutzerklärung", y);y+=10;

    
    doc.setFontSize(14); doc.setFont('Arial','Bold',600)
    doc.text("A. Grundsatz", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    doc.text('Ihre Personendaten (nachfolgen auch "Daten") erheben und bearbeiten wir nur zu den in der', 20, y); y+=5;
    doc.text('Datenschutzerklärung beschriebenen Zwecken und im dafür notwendigen Umfang. Wir befolgen',20, y); y+=5;
    doc.text('die Grundsätze der Rechtmässigkeit, der Bearbeitung nach Treu und Glaube und der Transparenz.', 20, y); y+=5;
    doc.text('Wir bewahren Ihre Personendaten solange auf, als dies zum kommunizierten Zweck nötig ist oder', 20, y); y+=5;
    doc.text('wir von Gesetzes wegen dazu verpflichtet sind. Inhaltlich werden die Daten sachlich richtig und', 20, y); y+=5;
    doc.text('auf dem neusten Stand gespeichert. Sodann schützen wir Ihre Personendaten durch technische und', 20, y); y+=5;
    doc.text('organisatorische Sicherheitsvorschriften.', 20, y); y+=12;

    doc.setFontSize(14); doc.setFont('Arial','Bold',600)
    doc.text("B. Name und Anschrift des Verantwortlichen", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    doc.text(fullAddress, 20, y); y+=12;

    doc.setFontSize(14); doc.setFont('Arial','Bold',600)
    doc.text("C. Bearbeitungen", 20, y); y+=7;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    doc.text("1 Eröffnung Patientendossier Wir bearbeiten Name, Vorname, Wohnort, Alter,", 20, y);y+=5;
    doc.text("Geburtsdatum, Versicherten-Karten-Nr. sowie weitere nötige Stammdaten für folgende/n", 20, y);y+=5;
    doc.text("Zweck: Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Software", 20, y);y+=5;
    doc.text("Anbieter. Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb", 20, y);y+=5;
    doc.text("von 20 Jahren nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
  
    doc.text("2 Führung der Krankengeschichte (elektronisch oder auf Papier) Wir bearbeiten Name,", 20, y);y+=5;
    doc.text("Vorname, Wohnort, Geburtsdatum, Versicherten-Karten-Nr., Krankengeschichte (resp.", 20, y);y+=5;
    doc.text("Diagnose, Laborwerte, med. Bilder) für folgende/n Zweck: Behandlung Patient. Diese Daten", 20, y);y+=5;
    doc.text("gehen an folgende/n Empfänger: Software Anbieter, Spezialist (Dritte), Spital (Dritte), weitere", 20, y);y+=5;
    doc.text("med. Leistungserbringer (Dritte). Ohne anderslautende individuelle Vereinbarung löschen wir", 20, y);y+=5;
    doc.text("die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y);y+=5;
    doc.text("Ausland.", 20, y);y+=12;

    doc.text("3 Leistungsabrechnung Wir bearbeiten Medizinische / Administrative Leistungen (für", 20, y);y+=5;
    doc.text("Rechnung) für folgende/n Zweck: Behandlung Patient. Diese Daten gehen an folgende/n", 20, y);y+=5;
    doc.text("Empfänger: Software Anbieter. Ohne anderslautende individuelle Vereinbarung löschen wir", 20, y);y+=5;
    doc.text("die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y);y+=5;
    doc.text("Ausland.", 20, y);y+=12;


    doc.text("4 Fakturierung an Versicherung (tier gerant) plus Kopie an Patienten Wir bearbeiten", 20, y);y+=5;
    doc.text("Name, Vorname, Wohnort, Versicherten-Karten-Nr., medizinische / administrative Leistung,", 20, y);y+=5;
    doc.text("Minimal Clinical Dataset (z.H. Versicherer) für folgende/n Zweck: Behandlung Patient. Diese", 20, y);y+=5;
    
    doc.text("Daten gehen an folgende/n Empfänger: Software Anbieter, Zertifizierte Datenannahmestelle", 20, y);y+=5;
    doc.text("(Dritte), Versicherer (Dritte). Ohne anderslautende individuelle Vereinbarung löschen wir die", 20, y);y+=5;
    doc.text("Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y);y+=5;
    doc.text("Ausland.", 20, y);


    doc.insertPage(); y=20;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    textMiddle(fullAddress, y); y+=9;
    doc.line(20, y, 190, y, null);y+=15;
    
    
    doc.text("5 Fakturierung an Patient (tiers payant) Wir bearbeiten Name, Vorname, Wohnort,", 20, y);y+=5;
    doc.text("Versicherten-Nr., Medizinische / Administrative Leistung, Minimal Clinical Dataset (z.H.", 20, y);y+=5;
    doc.text("Versicherer) für folgende/n Zweck: Behandlung Patient. Diese Daten gehen an folgende/n", 20, y);y+=5;
    doc.text("Empfänger: Dienstleister , Software Anbieter, Versicherer. Ohne anderslautende individuelle", 20, y);y+=5;
    doc.text("Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es", 20, y);y+=5;
    doc.text("erfolgt kein Transfer ins Ausland.", 20, y);y+=12;


    let secNr = 6;
    //---- dynamics

    let putText=(txt,xxx,yy)=>{
        doc.text(txt,xxx,yy);
        if(yy>250){
            doc.insertPage(); y=20;
            doc.setFontSize(12); doc.setFont('Arial','Regular',300)
            textMiddle(fullAddress, y); y+=9;
            doc.line(20, y, 190, y, null);y+=15;
        }
    }
    
    if(props.inputs['factoring']!=null && props.inputs['factoring']){
        putText(secNr+ " Factoring Wir bearbeiten Name, Vorname, Wohnort, Versicherten-Nr.,", 20, y);y+=5;
        putText("Medizinische / Administrative Leistung, Minimal Clinical Dataset (z.H. Versicherer) für", 20, y);y+=5;
        putText("folgende/n Zweck: Behandlung Patient. Diese Daten gehen an folgende/n Empfänger:", 20, y);y+=5;
        putText("Factoring-Unternehmen (Dritte), Versicherer. Ohne anderslautende individuelle", 20, y);y+=5;
        putText("Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es", 20, y);y+=5;
        putText("erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }
    if(props.inputs['inkasso']!=null && props.inputs['inkasso']){
        putText(secNr+ " Inkasso Wir bearbeiten Name, Vorname Wohnort, Versicherten-Nr.,", 20, y);y+=5;
        putText("Medizinische / Administrative Leistung, Minimal Clinical Dataset (z.H. Versicherer) für", 20, y);y+=5;
        putText("folgende/n Zweck: Durchsetzung Forderung. Diese Daten gehen an folgende/n Empfänger:", 20, y);y+=5;
        putText("Inkasso-Unternehmen (Dritte). Ohne anderslautende individuelle Vereinbarung löschen wir", 20, y);y+=5;
        putText("die Daten innerhalb von 3 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y);y+=5;
        putText("Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ " Bonitätsprüfung Wir bearbeiten Name, Vorname, Wohnort, Gebutsdatum für", 20, y);y+=5;
        putText("folgende/n Zweck: Sicherstellung Forderung. Diese Daten gehen an folgende/n Empfänger:", 20, y);y+=5;
        putText("Bonitäts-Unternehmen (Dritte). Ohne anderslautende individuelle Vereinbarung löschen wir", 20, y);y+=5;
        putText("die Daten innerhalb von 3 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins", 20, y);y+=5;
        putText("Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(props.inputs['website']!=null && props.inputs['website']){
        putText(secNr+ " Betrieb Website Wir bearbeiten IP-Adressen der Website-Besucher für", 20, y);y+=5;
        putText("folgende/n Zweck: Technischer Betrieb der Website. Diese Daten gehen an folgende/n", 20, y);y+=5;
        putText("Empfänger: Hosting-Provider, Ext. Host. Ohne anderslautende individuelle Vereinbarung", 20, y);y+=5;
        putText("löschen wir die Daten innerhalb von 1 Jahr nach der letzten Bearbeitung. Es erfolgt kein", 20, y);y+=5;
        putText("Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(props.inputs['nutzung']!=null && props.inputs['nutzung']){
        putText(secNr+" Nutzung Terminbuchungsplattform Wir bearbeiten Name, Vorname,", 20, y);y+=5;
        putText("Behandlungsgrund und weitere eingegebene Daten auf der Website für folgende/n Zweck:", 20, y);y+=5;
        putText("Behandlung Patient. Diese Daten gehen an folgende/n Empfänger: Software-Anbieter,", 20, y);y+=5;
        putText("externer Betreiber Terminbuchungsplattform. Ohne anderslautende individuelle", 20, y);y+=5;
        putText("Vereinbarung löschen wir die Daten innerhalb von 10 Jahre nach der letzten Bearbeitung. Es", 20, y);y+=5;
        putText("erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ " Kommunikation mit Patienten /Leistungsträger Wir bearbeiten Name, Vorname sowie", 20, y);y+=5;
        putText("die weiteren Stammdaten sowie die Krankengeschichte für folgende/n Zweck: Behandlung", 20, y);y+=5;
        putText("Patient. Diese Daten gehen an folgende/n Empfänger: Kommunikationsplatform (Microsoft,", 20, y);y+=5;
        putText("HIN). Ohne anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von", 20, y);y+=5;
        putText("20 Jahre nach der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ " Einsatz von externen Periferiegeräten (Langzeit EKG, Ultraschall, Röntgen) Wir", 20, y);y+=5;
        putText("bearbeiten Name, Vorname, Wohnort, Versicherten-Nr., Bilder, Auswertungsdaten für", 20, y);y+=5;
        putText("folgende/n Zweck: Behandlung Pateint. Diese Daten gehen an folgende/n Empfänger:", 20, y);y+=5;
        putText("Geräte Hersteller (Wartung, Onlinezugriff ). Ohne anderslautende individuelle Vereinbarung", 20, y);y+=5;
        putText("löschen wir die Daten innerhalb von 20 Jahre nach der letzten Bearbeitung. Es erfolgt kein", 20, y);y+=5;
        putText("Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ "  Führung Buchhaltung Wir bearbeiten Name, Vornamen, Rechnungsdaten für", 20, y);y+=5;
        putText("folgende/n Zweck: Jahresabschluss, Controlling. Diese Daten gehen an folgende/n", 20, y);y+=5;
        putText("Empfänger: Treuhänder, Software-Anbieter. Ohne anderslautende individuelle Vereinbarung", 20, y);y+=5;
        putText("löschen wir die Daten innerhalb von 10 Jahr nach der letzten Bearbeitung. Es erfolgt kein", 20, y);y+=5;
        putText("Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ " Durchführung Operationen (als Belegarzt) Wir bearbeiten Name, Vorname Wohnort,", 20, y);y+=5;
        putText("Geburtsdatum, Versicherten-Karten-Nr.,und Status, Medizinische / Administrative Leistung,", 20, y);y+=5;
        putText("Minimal Clinical Dataset für folgende/n Zweck: Behandlung Patient. Diese Daten gehen an", 20, y);y+=5;
        putText("folgende/n Empfänger: Spital, Spitalmitarbeiter, Mitarbeiter Medizinalgeräte. Ohne", 20, y);y+=5;
        putText("anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach", 20, y);y+=5;
        putText("der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }

    if(false){
        putText(secNr+ " Beizug von externen Labors Wir bearbeiten Name, Vorname, Wohnort, Geburtsdatum,", 20, y);y+=5;
        putText("Versicherten-Karten-Nr. für folgende/n Zweck: Erfüllung Vertrag, Behandlung Patient. Diese", 20, y);y+=5;
        putText("Daten gehen an folgende/n Empfänger: Labor, Laborangestellte. Ohne anderslautende", 20, y);y+=5;
        putText("individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach der letzten", 20, y);y+=5;
        putText("Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=12;
        secNr++;
    }


    if(false){
        putText(secNr+ " Überweisung an Spezialisten (Spezialisten, Spital) Wir bearbeiten Name, Vorname", 20, y);y+=5;
        putText("Wohnort, Versicherten-Karten-Nr.,und Status, Medizinische / Administrative Leistung,", 20, y);y+=5;
        putText("Minimal Clinical Dataset z.H. Spital für folgende/n Zweck: Behandlung Patient. Diese Daten", 20, y);y+=5;
        putText("gehen an folgende/n Empfänger: Spezialist, Spital, Spitalmitarbeiter (Dritte). Ohne", 20, y);y+=5;
        putText("anderslautende individuelle Vereinbarung löschen wir die Daten innerhalb von 20 Jahre nach", 20, y);y+=5;
        putText("der letzten Bearbeitung. Es erfolgt kein Transfer ins Ausland.", 20, y);y+=5;
    }

    doc.setFontSize(14); doc.setFont('Arial','Bold',900)
    y+=5;
    putText("D. Rechte als betroffene Person", 20, y);y+=14;

    doc.setFontSize(12); doc.setFont('Arial','Regular',300)
    putText("-  ein Auskunftsrecht über Ihre Personendaten (Art. 25 DSG)", 20, y);y+=10;
   
    putText("-  Recht auf Datenübertragbarkeit (Art. 28 DSG): Sie haben das Recht, Daten, die wir automatisiert", 20, y);y+=5;
    putText("bzw. digital verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format", 20, y);y+=5;
    putText("aushändigen zu lassen. Dies gilt insbesondere auch bei der Weitergabe von medizinischen Daten an", 20, y);y+=5;
    putText("eine von Ihnen gewünschte Gesundheitsfachperson. Sofern Sie die direkte Übertragung der Daten an", 20, y);y+=5;
    putText("einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist", 20, y);y+=10;
   
    putText("-  das Recht auf Berichtigung Ihrer Personendaten (Art. 32 DSG)", 20, y);y+=10;

    putText("-  Recht auf Widerspruch zu einer Bearbeitung, Recht auf Löschung, Recht auf", 20, y);y+=5;
    putText("Einschränkung einer Bearbeitung (Art. 30 Abs. 2 DSG)", 20, y);y+=10;

    putText("-  das Recht, einen Bestreitungsvermerk zu verlangen (Art. 32 Abs. 3)", 20, y);y+=10;

    putText("-  das Recht, dass die Berichtigung, die Löschung, die Vernichtung, das Verbot der", 20, y);y+=5;
    putText("Bearbeitung oder das Verbot der Bekanntgabe an Dritte bekanntgeben oder veröffentlicht", 20, y);y+=5;
    putText("wird (Art. 32 Abs. 4 DSG)", 20, y);y+=20; 


    doc.text('Bei Fragen stehen wir Ihnen gerne zur Verfügung. Bitte kontaktieren Sie', 20, y);y+=5;
    doc.text(props.kontakt.anrede + " "+ props.kontakt.nachname +", "+ props.kontakt.email , 20, y);y+=5;

    // putText("xxxxxxxx", 20, y);y+=5;
    // putText("xxxxxxxx", 20, y);y+=5;

    doc.save("PatiententDatenschutz.pdf"); 
    if(callback!=null){
        callback();
    }
}
