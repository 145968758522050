import React, { Component } from 'react';
import DocQuestionaireService from './DocQuestionaireService.js';
import { CircularProgress } from '@mui/material';
import {isMobile} from 'react-device-detect';
import './doc.css'
import {  ref, update } from "firebase/database";
import {db} from '../../firebase/firebase';
import HtmlToDoc from '../../util/HtmlToDoc.js';

import { datenschutzerklärungPatient, datenschutzerklärungWebsite } from './pdf_generator';
import { Helmet } from 'react-helmet';
import { USERS_TABLE } from '../../firebase/constants';

class DocumentQuestionaire extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputs:[],
            onLoad:true
            ,width:window.innerWidth
        }
        
        if(this.props.data!=null){
            this.props.data.segments.forEach((segment,idx) => {
                if(segment.default!=null){
                    this.state.inputs[segment.id] = segment.default;
                }
            });
        }

    } 
    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
        DocQuestionaireService.getQuestionaireAnswer(this.props.exchange, this.props.data).then((snapshot)=>{
            if (snapshot.exists()) {
                let docData = snapshot.val();
                let answers = docData.answers;
                let inputsx = this.state.inputs;
                for (var key in answers) {
                    if (answers.hasOwnProperty(key)) {
                      inputsx[key] = answers[key];
                    }
                }
                this.setState({onLoad:false,inputs:inputsx})
            }else{
                this.setState({onLoad:false})
            }
        }).catch(e=>{
            this.setState({onLoad:false})
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
         this.setState({width:window.innerWidth, height:window.innerHeight});
    }

    renderAllSegments=()=>{
        let segmentDiv=[];
        this.props.data.segments.forEach((segment,idx) => {
            segmentDiv.push(this.buildSegmentDiv(segment,idx))
        });

        return segmentDiv;
    }

    renderActionButtons=()=>{
        let isSmall = isMobile || this.state.width<750;
        let actionButtons=[];
        this.props.data.actionButtons.forEach((btns,idx) => { 
            actionButtons.push(
                <button className={'btn1 action-btn'+idx} onClick={(e)=>this.handleEvent(btns.eventId)} style={{padding:isSmall?'10px':'', marginRight:'8px',marginBottom:isSmall?'10px':''}}>
                    {btns.text}
                </button>
            )
        });

        return (<div className='action-btn-div' style={{display:isSmall?'':'flex'}}>
                    {actionButtons}
                </div>);
    }

    handleEvent=(eventId)=>{
        switch(eventId){
            case '0': HtmlToDoc.convert(this.props.exchange.user); break;
            case '1': this.generateDatenschutzerklärungPatient(); break;
            case '2': this.generateDatenschutzerklärungWebsite(); break;
        }
    }

    generateDatenschutzerklärungWebsite=()=>{
        let docuProps={
            user:this.props.exchange.user,
            inputs:this.state.inputs
        }
        datenschutzerklärungWebsite(docuProps)
    }

    generateDatenschutzerklärungPatient=()=>{
        let docuProps={
            user:this.props.exchange.user,
            inputs:this.state.inputs
        }
        datenschutzerklärungPatient(docuProps);
    }

    buildSegmentDiv=(segment,idx)=>{
        let segmentId = segment.id;
        let isSmall = isMobile || this.state.width<750;
        switch(segment.type){
            case 'title':
                return (
                    <div className={segmentId + ' doc-segment-title'}>
                        <h2 style={{whiteSpace: 'pre-wrap',fontSize:isSmall?'1.25rem':''}}>{segment.text}</h2>
                    </div>)
            case 'text':
                return (
                    <div className={segmentId + ' doc-segment-txt'}>
                        <p style={{whiteSpace: 'pre-wrap',fontSize:isSmall?'0.8rem':''}}>{segment.text}</p>
                    </div>)
            case 'boolean':
                let isChecked = this.state.inputs[segmentId];
                return (
                    <div className={segmentId + ' doc-segment-boolean'} style={{display:'flex', alignItems:'center  '}}>
                        <input type="checkbox" id="vehicle1" name="vehicle1" checked={isChecked} onChange={(e)=>this.toggleCheckbox(e,segmentId)}/>
                        <p style={{marginBottom:'4px',marginTop:'4px',fontSize:isSmall?'0.8rem':''}}>{segment.text}</p>
                    </div>)
        }
    }

    updateQuestionAnswer=async(segmentId,answer)=>{
        
        let firebaseUser = this.props.exchange.user;
        let docId = this.props.data.id;
        let userPath = USERS_TABLE + '/' + firebaseUser.uuid;
        let userDocPath =userPath+'/docs/'+docId;
        const updates = {};
        updates[userDocPath+'/answers/'+segmentId] = answer;

        update(ref(db), updates).then(res=>{
            this.setState({onLoad:false})
        }).catch(e=>{
            this.setState({onLoad:false})
        });
    }

    toggleCheckbox=(e,segmentId)=>{
        let inputss = this.state.inputs;
        if(e.target.type==='checkbox'){
            inputss[segmentId] = e.target.checked;
        }
        this.setState({onLoad:true,inputs:inputss},()=>this.updateQuestionAnswer(segmentId,e.target.checked));
    }

    render() {
        let isSmall = isMobile || this.state.width<750;
        if(this.props.data==null){
            return (
                <div className='doc-q-page page'>
                    <div className='page-box-2' style={{textAlign:'center'}}>
                        <h3>Document not found</h3>
                    </div>
                </div>
            )
        }
        return (
            <div className='doc-q-page page' style={this.state.onLoad?{pointerEvents:'none'}:{}}>
                {this.state.onLoad&&
                <div style={{position:'absolute',display:'flex',justifyContent:'center',width:'100vw',padding:'25vh'}}>
                    <CircularProgress sx={{color:'black'}}/>
                </div>}
                {this.props.data!=null&&
                <Helmet>
                    <title>{this.props.data.title}</title>
                </Helmet>}
                <div className='page-box-2'>
                    {this.renderAllSegments()}
                    <br/>
                    {this.renderActionButtons()}
                </div>
              
            </div>
        );
    }
}

export default DocumentQuestionaire;