import React, { Component } from 'react';
import MasterDataPage from '../MasterDataPage';
import { isMobile } from 'react-device-detect';
import { LABELS } from '../../Labels';
import { Link } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';

class PasswordChangeSuccess extends Component {
    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='init-master-data-modal' style={{display:'flex',overflow:'hidden', maxHeight:isMobile?'95vh':'90vh', width:isMobile?'95vw':'70vw', maxWidth:'600px', minWidth:'480px'}}>
                <div className='master-data-page-wrap' style={{width:'100%',padding:'30px',overflowY:'scroll'}}>
                    <h2 style={{fontWeight:'300',textAlign:'center'}}>{LABELS.PW_UPDATED[lang]}</h2>
                    {/* <p style={{fontWeight:'300'}}>{LABELS.PW_UPDATED[lang]}</p> */}
                    <div style={{borderBottom:'solid 1px gainsboro',marginTop:'18px'}}></div>
                    <Link className={'btn1 '} onClick={()=>{
                        this.props.exchange.setModal(null)
                    }} to='/'>
                        {LABELS.GOTO_DASHBOARD[lang]}
                    </Link>
                </div>
            </div>
        );
    }
}

export default PasswordChangeSuccess;