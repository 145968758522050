import {FIREBASE_CONFIG} from '../config.js'
import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';
 
export const app = initializeApp(ENV==='prod'?FIREBASE_CONFIG.prod:FIREBASE_CONFIG.dev);
export const db = getDatabase();
export const firestoreDb = getFirestore(app);

const analytics = getAnalytics(app);