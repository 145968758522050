
import { getDatabase, ref, update, get, child } from "firebase/database";
import { USERS_TABLE } from "../../firebase/constants";

const dbRef = ref(getDatabase());
class DocQuestionaireService {

    getQuestionaireAnswer=async(exchange,docData)=>{
        let firebaseUser = exchange.user;
        let docId = docData.id;
        let userPath = USERS_TABLE + '/' + firebaseUser.uuid;
        let userDocPath =userPath+'/docs/'+docId;
        return await get(child(dbRef, userDocPath));
    }
}
export default new DocQuestionaireService()