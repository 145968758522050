import React, { Component } from 'react';

class AGBTerms extends Component {
    render() {
        return (
            <div style={{padding:'6vh 10vw'}}>
            <h3>Allgemeine Geschäftsbedingungen für die Nutzung von Medcomply</h3>
            <p>Datum 25. Juni 2024</p>
            <br/>

            <h4>1. Anwendungsbereich</h4>
            <p>Diese allgemeinen Geschäftsbedingungen (nachfolgend «AGB») gelten für alle Geschäftsbeziehungen zwischen Nutzerinnen und Nutzer (nachfolgend «Kunde») der Web-Applikation Medcomply (nachfolgend «Webapp») und transcendis GmbH (nachfolgend die Gesellschaft). Für die einfachere Lesbarkeit wird nachfolgend jeweils die männliche Form verwendet. </p>
            <br/>

            <h4>2. Vertragsabschluss</h4>
            <p>Mit erfolgreicher Registrierung über die Webapp kommt das Vertragsverhältnis zwischen dem Kunden und der Gesellschaft zustande. </p>
            <br/>
            <p>Die Zahlung der Nutzungsgebühr berechtigt den Kunden zur Nutzung der Webapp für die vom Kunden gewählte Dauer (nachfolgend das Abonnement). Umfang des Angebotes, die Dauer sowie die Höhe der Nutzungsgebühr richten sich nach den Angaben auf der Website im Zeitpunkt der Registrierung.  </p>
            
            <br/>
            <h4>3. Angebot</h4>
            <p>Mit Registrierung und Zahlung der Nutzungsgebühr erhält der Kunde für die gewählte Dauer Zugang zur Webapp. Die Webapp bietet einen vordefinierten Prozess zur Verwaltung des Datenschutzes sowie weiterer Dienstleistungen (nachfolgend das Angebot). </p>
            <p>Der Umfang des Angebots richtet sich nach dem Angebot auf der Website im Zeitpunkt der Registrierung, oder im Zeitpunkt einer allfälligen Vertragsanpassung (Downgrade oder Upgrade). </p>
            <p>Das Angebot beinhaltet weder den jederzeitigen Zugriff auf die Webapp, noch den störungsfreien oder fehlerfreien Zugriff auf die Webapp. So ist beispielsweise der Zugriff auf die Webapp bei Wartungsarbeiten beschränkt, gleichermassen kann der Zugriff auf die Webapp infolge Störungen oder Mängeln von Infrastrukturanbieter (Netzbetreiber, Serveranbieter etc.) eingeschränkt oder ausgeschlossen sein (siehe Haftungsausschluss).</p>

            <br/>
            <h4>4. Nutzungsgebühr</h4>
            <p>Die Nutzungsgebühr richtet sich nach den auf der Website im Zeitpunkt der Registrierung aufgeführten Angaben.</p>
            <br/>
            <p>Die Nutzungsgebühr wird mit Registrierung des Kunden fällig. Die Gesellschaft ist berechtigt, bei Zahlungsverzug des Kunden den Zugang zur Webapp ohne weitere Ankündigung zu sperren und das Angebot frist- und entschädigungslos zu beenden. </p>

            <br/>

            <h4>5. Nutzungsdauer</h4>
                <div style={{marginLeft: '6px'}}>
                    <p>5.1 Nutzungsdauer</p>
                    <p>Die Nutzungsdauer richtet sich nach den auf der Website im Zeitpunkt der Registrierung aufgeführten Angaben. </p>

                    <br/>
                    <p>5.2 Ordentliche Kündigung </p>
                    <p>Der Kunde kann das Abonnement jederzeit auf das Ende der Nutzungsdauer kündigen. </p>


                    <br/>
                    <p>5.3 Automatische Verlängerung des Abonnements</p>
                    <p>Ohne Kündigung vor Ablauf der Nutzungsdauer verlängert sich das Abonnement automatisch um die ursprüngliche Laufzeit.</p>

                    <br/>
                    <p>5.4 Anpassung des Abonnements</p>
                    <p>Die Gesellschaft ist berechtigt, das laufende Angebot nach Ablauf der Laufzeit anzupassen oder mit einer Ankündigungsfrist von 1 Monat einzustellen. </p>
                    
                
                    <br/>
                    <p>5.5 Beendigung</p>
                    <p>Die Gesellschaft ist berechtigt, das Abonnement frist- und entschädigungslos zu beenden, wenn sie begründete Anhaltspunkte hat, dass der Kunde seine Pflichten gemäss Ziffer 6.2 verletzt. In einen solchen Fall verfällt die Restgebühr, eine Rückerstattung ist ausgeschlossen. </p>
                    <p>Die Gesellschaft ist sodann berechtigt, das Abonnement jederzeit gegen volle Erstattung der Restgebühr zu beenden und das Angebot einzustellen. Eine weitergehende Entschädigung des Kunden oder eine Ersatzpflicht der Gesellschaft wird ausgeschlossen. </p>
                
                    <br/>
                    <p>5.6 Löschen des Accounts</p>
                    <p>Löscht der Kunde den Account, werden alle mit diesem Account verbundenen Dateien gelöscht. Entsprechend ist der Kunde nicht mehr in der Lage, auf Dateien zuzugreifen. Es wird deshalb empfohlen, dass die Daten soweit möglich mit neu generierten Berichten gesichert und exportiert werden. </p>
                </div>

            <br/>
            

            <h4>6. Pflichten Kunden </h4>
            <div style={{marginLeft: '6px'}}>
                <p>6.1 Bezahlung der Nutzungsgebühr</p>
                <p>Der Kunde verpflichtet sich zur Bezahlung der Nutzungsgebühr, die im Zeitpunkt der Registrierung für das vom Kunden gewählte Angebot gilt. </p>
                

                <br/>
                <p>6.2 Einhaltung der Rechtsvorschriften</p>
                <p>Der Kunde verpflichtet sich zur Nutzung des Angebots im Einklang mit den Vorschriften an seinem Wohn- und Aufenthaltsort sowie den Vorschriften der Schweiz. Die Gesellschaft lehnt jede Verantwortung für die Nutzung der Webapp durch den Kunden oder Drittpersonen, insbesondere für den Inhalt der Daten, die über die Webapp empfangen oder versendet werden, ab. </p>
                <p>Soweit durch die unrechtmässige Nutzung der Webapp durch den Kunden Ansprüche Dritter gegenüber der Gesellschaft geltend gemacht werden, verpflichtet sich der Kunde, die Gesellschaft von solchen Ansprüchen freizuhalten und für Kosten, die infolge Abwehr solcher Ansprüche entstehen (insb. Abwehrkosten), aufzukommen. </p>
                <br/>
            </div>
            <h4>7. Sichere Verwahrung der Zugangsdaten und Passwort</h4>
            <p>Der Kunde ist verantwortlich für die sichere Verwahrung seiner Zugangsdaten, eine Bekanntgabe seiner Zugangsdaten an Drittpersonen und die Nutzung der Webapp durch Drittpersonen ist nicht zulässig. Der Kunde ist verantwortlich für die sorgfältige und sichere Verwahrung seines Passwortes. Eine Haftung der Gesellschaft infolge Verlusts seines Passwortes und Verlust der damit verschlüsselten Daten wird ausgeschlossen. </p>
            <br/>
            
            <h4>8. Haftungsausschluss</h4>
            <p style={{fontWeight:600}}>Es wird jede Haftung für mittlere und leichte Fahrlässigkeit ausgeschlossen. Insbesondere wird die Webapp in dem Zustand angeboten, wie sie sich befindet. Die Struktur des Datenschutzmanagement-Systems ist nach bestem Wissen erstellt, aufgrund der Komplexität der Materie und der Notwendigkeit von Verallgemeinerung sind Fehler allerdings nicht ausgeschlossen. Um Ungenauigkeiten bei Verallgemeinerungen zu vermeiden, ist eine individuelle Beratung nötig. Die Gesellschaft schliesst sodann jede Haftung für beigezogene Hilfspersonen aus, insbesondere eine Haftung aufgrund der zugrundeliegenden Systeme und der Infrastruktur (Server, Kommunikation). Es kann nicht gewährleistet werden, dass die Webapp jederzeit verfügbar, störungsfrei, oder fehlerfrei verfügbar ist.</p>
            <br/>
            
            <h4>9. Geistiges Eigentum </h4>
            <p>Sämtlicher Programm-Code sowie das Erscheinungsbild und Inhalt der Website sind urheberrechtlich geschützt. Jede Verwendung (Kopie, Modifikation, Publikation etc.) ohne schriftliche Einwilligung der Gesellschaft und des betroffenen Urhebers ist untersagt.</p>
            <br/>
            
            <h4>10. Vertragsübertragung</h4>
            <p>Der Kunde ist einverstanden, dass seine vertragliche Beziehung zur Gesellschaft im Falle einer Umstrukturierung der Gesellschaft auf eine andere Gesellschaft oder eine andere Rechtsperson übertragen wird. </p>
            <br/>
            
            <h4>11. Technische Cookies</h4>
            <p>Der Kunde wird darauf hingewiesen, dass für die Nutzung der Webapp das Setzen von technischen Cookies nötig ist. Eine Nutzung der Webapp ohne technische Cookies ist nicht möglich. Der Kunde erklärt sich mit dem Setzen von technischen Cookies einverstanden. </p>
            <br/>
            
            <h4>12. Schlussbestimmungen</h4>
            <br/>

            <div style={{marginLeft: '6px'}}>

                <p>12.1 Salvatorische Klausel</p>
                <p>Sollten eine oder mehrere Bestimmungen des Vertragsverhältnisses nichtig oder unwirksam sein oder werden, so werden die übrigen Teile dieses Vertragsverhältnisses davon nicht berührt. Diese Bestimmung ist im mindestmöglichen Masse abzuändern, damit sie gültig und durchsetzbar wird. Im Falle der Nichtigkeit oder Unwirksamkeit einer Klausel ist diese durch eine solche zu ersetzen, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommt. </p>
           

                <br/>
                <p>12.2 Änderungen</p>
                <p>Die Gesellschaft ist berechtigt, diese AGBs jederzeit zu ändern. Mit Publikation der Änderung auf der Website treten die Änderungen in Kraft. </p>
                <br/>
                <p>12.3 Zustellung</p>
                <p>Der Kunde erklärt sein Einverständnis, dass Mitteilungen der Gesellschaft an ihn rechtsgültig (1) im Administrations-Bereich des Kunden hinterlegt werden dürfen oder (2) an die vom Kunden bezeichnete E-Mail Adresse gesandt werden dürfen. </p>
                <br/>

                <p>12.4 Abtretungsverbot</p>
                <p>Eine Abtretung ohne schriftliche Zustimmung der Gesellschaft ist untersagt. Werden Rechte und/oder Pflichten aus dem Vertragsverhältnis übertragen, ist die Gesellschaft berechtigt, den Zugang zum Angebot ohne weiteres und entschädigungslos zu beenden.</p>
            </div>

            <br/>

            <h4>13. Anwendbares Recht und Gerichtsstand </h4>
            <p>Dieser Vertrag sowie sämtliche Streitigkeiten im Zusammenhang mit diesem Vertrag unterliegen schweizerischem materiellem Recht unter Ausschluss allfälliger Kollisionsnormen, insbesondere dem Wiener Kaufrecht. </p>
            <br/>
            <p>Für Streitigkeiten zwischen Kunde und Gesellschaft sind die für die Stadt Zürich (Kreis 4) zuständigen Gerichte ausschliesslich zuständig.</p>
           

            </div>
        );
    }
}

export default AGBTerms;