import React, { Component } from 'react';
import history from '../history';
import Util from '../util/Util';
import { LABELS } from '../Labels';
import { getAuth } from "firebase/auth";
import TextField from '@mui/material/TextField';
import { MODAL } from '../main/ModalController';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import {sendPasswordResetEmail} from 'firebase/auth';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const auth = getAuth();

class VerificationEmailSent extends Component {

    constructor(props) {
        super(props);
        this.state = {
             code:'',
             password:'',
             confirmPassword:'',
             onLoad:false,
             errorMsg:'',

             errInputs:'',
             showPassword:false,
             showConfirmPassword:false
            }
    }

    onChangePasswordClicked=()=>{

        this.setState({onLoad:true},
            ()=>{
                let lang =this.props.exchange.language || 'de';
                let errInputs = '';
                let errorMsg='';
                if(this.state.code===''){
                    errInputs=errInputs+' code';
                }

                if(this.state.password===''){
                    errInputs=errInputs+' passwordx';
                }

                if(this.state.confirmPassword===''){
                    errInputs=errInputs+' confirmPassword';
                }

                if(this.state.password!==''&&this.state.confirmPassword!=='' && this.state.password!=this.state.confirmPassword){

                    errInputs=errInputs+' passwordx confirmPassword';
                    errorMsg=LABELS.PASSWORD_NOT_MATCH[lang]
                }

                if(errInputs!=='' || errorMsg!==''){
                    this.setState({errInputs:errInputs,errorMsg:errorMsg,onLoad:false});
                }
                
                else{
                    let successChangeCallback=(res)=>{
                        history.push('/login');
                        history.go();
                    }
                    let errorChangeCallback=(error)=>{
                        // console.log('CognitoService.resetPassword errorChangeCallback',error);
                        // console.log('error.message',error.message);
                        // console.log('error.code',error.code);
                        // let errorMsg = error.message;
                        // if(lang==='de'){
                        //     if(error.message==='ExpiredCodeException'){
                        //         if(error.message==='Invalid code provided, please request a code again.'){
                        //             errorMsg='Ungültiger Code angegeben. Bitte fordern Sie erneut einen Code an.';      
                        //         }
                        //     }
                        // }

                        this.setState({onLoad:false,errorMsg:error.message});
                    }
                }
            });
            
    }

    changeInputHandler=(event)=>{
        let errInputs= this.state.errInputs;
        switch(event.target.id){
            case 'code-input': 
                errInputs = errInputs.replace(' code','');
                this.setState({code:event.target.value, errInputs:errInputs});
            break;
            case 'password-input':
                errInputs = errInputs.replace(' passwordx','');
                this.setState({password:event.target.value, errInputs:errInputs});
            break;
            case 'confirm-password-input':
                errInputs = errInputs.replace(' confirmPassword','');
                this.setState({confirmPassword:event.target.value, errInputs:errInputs});
            break;
            default:break;
        }
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='verf-email-div' style={{width:'400px',backgroundColor:'white',padding:'20px 10px',pointerEvents:this.state.onLoad?'none':'' }}>
               
               <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>
                <div style={{textAlign:'center'}}>
                    <h2>Password reset requested</h2>
                    <p>{LABELS.WE_SENT_CODE_1[lang] + this.props.email + LABELS.WE_SENT_CODE_2[lang]}</p>
                </div>
                <br/>

                <p>Code</p>
                <TextField 
                    id="code-input" variant="outlined"
                    error={this.state.errInputs.includes('code')}
                    value={this.state.code} onChange={this.changeInputHandler}
                    sx={{marginBottom:'12px',width:'100%'}}/>
            
                <p>{LABELS.NEW_PASSWORD[lang]}</p>
                <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                    <OutlinedInput
                        id="password-input"
                        error={this.state.errInputs.includes('passwordx')}
                        value={this.state.password}
                        onChange={this.changeInputHandler} 
                        type={this.state.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                    edge="end">
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>}/>
                 </FormControl>
 
            
                <p>{LABELS.CONFIRM_PASSWORD[lang]}</p>
                <FormControl sx={{ m: 1, width: '100%',margin:'0px',marginBottom:'20px' }} variant="outlined">
                    <OutlinedInput
                        id="confirm-password-input"
                        error={this.state.errInputs.includes('confirmPassword')}
                        value={this.state.confirmPassword}
                        onChange={this.changeInputHandler} 
                        type={this.state.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                    edge="end">
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>}/>
                 </FormControl>
            

                <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>

                <button className='btn1' 
                    onClick={this.onChangePasswordClicked}>
                    {LABELS.CHANGE_PASSWORD_SUBMIT[lang]}
                </button>
            </div>
        );
    }
}


class PasswordResetLinkSent extends Component {

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='verf-email-div' style={{width:'400px',backgroundColor:'white',padding:'20px 10px'}}>
                <div style={{textAlign:'center'}}>
                    <h2>{LABELS.CHECK_MAIL[lang]}</h2>
                    <p>{LABELS.WE_SENT_CODE_1[lang]}</p>
                </div>
                <br/>
                <button className='btn1' onClick={()=>this.props.exchange.setModal(MODAL.LOGIN)}>
                    {LABELS.LOGIN[lang]}
                </button>
            </div>
        );
    }
}

class ForgotPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             email:''
            ,first_name:''
            ,last_name:''
            ,password:''

            ,onLoad:false
            ,successReset:false
            ,errorMsg:''
            ,errorEmail:''
        }
    }

    changeInputHandler=(event)=>{
        if(this.state.onLoad){
            return;
        }
        switch(event.target.id){
            case 'email-input': this.setState({email:event.target.value,errorEmail:''}); break;
            default:break;
        }
    }

    resetPasswordClicked=()=>{

        if(this.state.onLoad){
            return;
        }
        let lang = this.props.exchange.language || 'de';
        if(this.state.email===''){
            this.setState({onLoad:false,errorEmail:LABELS.EMAIL_REQUIRED[lang]});  
        }else if(this.validateEmail(this.state.email)===false){
            this.setState({onLoad:false,errorEmail:LABELS.INVALID_EMAIL[lang]});  
        }
        // else{  

        //     // check if user exists
        //     fetchSignInMethodsForEmail(auth, this.state.email).then((res)=>{
        //         console.log('fetchSignInMethodsForEmail '+this.state.email,res)
        //     })
        //     .catch((error) => {
        //         console.log('fetchSignInMethodsForEmail err', error)
        //     });
        // }
        else{

            this.setState({onLoad:true},()=>{
            sendPasswordResetEmail(auth, this.state.email)
                .then(() => {
                    this.setState({successReset:true,onLoad:false})
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('errorCode',errorCode)
                    console.log('errorMessage',errorMessage)
                    switch(error.code){
                        case "auth/user-not-found": this.setState({onLoad:false,errorEmail:LABELS.NOT_FOUND[lang]});  break;
                        default:  this.setState({onLoading:false,errorMessage:errorMessage});break;
                    }
                });
            });
        }
 
    } 

    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderSendCodeDiv=()=>{
        let lang = this.props.exchange.language || 'de';
        return (
            <div className='signup-div' style={{width:'400px'}}>
                <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>

                <h2>{LABELS.FORGOT_PASS[lang]}</h2>
                <p>{LABELS.INSTRUCTION[lang]}</p>
                <br/>
                <p className='ititle'>Email</p>
                <TextField 
                    id="email-input" variant="outlined"
                    tabIndex={-1}
                    helperText={this.state.errorEmail} error={this.state.errorEmail!=''}
                    value={this.state.email} onChange={this.changeInputHandler}
                    sx={{marginBottom:'12px',width:'100%'}}/>
            
                <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>

                <button className='btn1' onClick={this.resetPasswordClicked} tabIndex={-1}>
                    {this.state.onLoad?
                    <CircularProgress size={'24px'} sx={{color:'white'}}/>:
                    <p>{LABELS.RESET[lang]} </p>}
                </button>
                <div style={{width:'100%',textAlign:'center',marginTop:'16px'}}>
                    <p className='li1' onClick={()=>this.props.exchange.setModal(MODAL.LOGIN)}>
                        {LABELS.CANCEL_2[lang]}
                    </p>
                </div>
            </div>
        );
    }

    render() { 
        let content = null; 
        let searchEmail = Util.getURLParameter('email');
        // if(searchEmail!=null){
        //     content = <VerificationEmailSent email={searchEmail} {...this.props}/>;
        // } else
        console.log('',this.props)
        if(this.state.successReset){
            content = <PasswordResetLinkSent  {...this.props}/>
        }
        else{
            content = this.renderSendCodeDiv();
        }

        return (
            <div className='forgot-pw-page' style={{backgroundColor:'white',padding:'28px 28px'}}>
                <div className='forgot-pw-content' style={{display:'flex',alignItems:'center',justifyContent:'center', height:'100%', width:'100%',pointerEvents:this.state.onLoad?'none':''}}>
                    {content}
                </div>
            </div>
        );
    }
}

export default ForgotPasswordPage;