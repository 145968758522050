import React, { Component } from 'react';

import { getAuth } from "firebase/auth";
import {USERS_TABLE} from '../firebase/constants';
import './loginsignup/LoginSignup.css'
import {db} from '../firebase/firebase';
import {MODAL} from '../main/ModalController'

import {  ref, update } from "firebase/database";
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl'; 
import { LABELS } from '../Labels';
import { isMobile } from 'react-device-detect';

const auth = getAuth();
class MasterDataPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             onLoad:false
            ,onAuth:true
            ,onSave:false
            ,errFields:''
            ,errorMsg:''
            ,inputs:{
                company:{
                    name:'',
                    legalForm:'',
                    streetNr:'',
                    address:'',
                    zip:'',
                    city:'',
                    phone:'',
                },
                contact:{
                    title:'',
                    firstname:'',
                    lastname:'',
                    position:'',
                    phone:'',
                    email:''
                }
            }
        }
        this.state.inputs.company = JSON.parse(JSON.stringify(this.props.exchange.user.company))
        this.state.inputs.contact = JSON.parse(JSON.stringify(this.props.exchange.user.contact))
    }
    
    componentDidMount(){ 
        let lang = this.props.exchange.language || 'de';
        this.props.exchange.setBrowserTitle(LABELS.UPDATE_INFO[lang] + ' | Medcomply');
        window.scrollTo(0, 0);
    }

    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validInput=async()=>{
        let lang = this.props.exchange.language || 'de';
        let onSave = true;
        let onLoad = true;
            let errFields ='';
            let errorMsg = '';
            if(this.state.inputs.company.name==''){
                errFields = errFields+' companyName';
            }
            if(this.state.inputs.company.streetNr==''){
                errFields = errFields+' companyStreetNr';
            }
            if(this.state.inputs.company.city==''){
                errFields = errFields+' companyCity';
            }
            // if(this.state.inputs.company.address==''){
            //     errFields = errFields+' companyAddress';
            // }
            if(this.state.inputs.company.zip==''){
                errFields = errFields+' companyZip';
            }
            if(this.state.inputs.company.phone==''){
                errFields = errFields+' companyPhone';
            }
            if(this.state.inputs.company.legalForm==''){
                errFields = errFields+' companyLegalForm';
            }
                
            if(this.state.inputs.contact.title==''){
                errFields = errFields+' contactTitle';
            }
            if(this.state.inputs.contact.firstname==''){
                errFields = errFields+' contactFirstname';
            }
            if(this.state.inputs.contact.lastname==''){
                errFields = errFields+' contactLastname';
            }
            if(this.state.inputs.contact.phone==''){
                errFields = errFields+' contactPhone';
            }
            if(this.state.inputs.contact.position==''){
                errFields = errFields+' contactPosition';
            }
            if(this.state.inputs.contact.email==''){
                errFields = errFields+' contactEmail';
            }else if(this.validateEmail(this.state.inputs.contact.email)===false){
                errFields = errFields+' contactEmail';
                errorMsg=LABELS.INVALID_EMAIL[lang];
            }


            let isComplete = errFields==='';
            if(!isComplete && errorMsg===''){
                errorMsg=LABELS.ERROR_MSG_2[lang];
            }
            
            if(errFields!=='' || errorMsg!==''){
                onSave = false;
                onLoad = false;
            }

            this.setState({onLoad:onLoad, onSave:onSave, errFields:errFields,errorMsg:errorMsg},()=>{
                if(isComplete){
                   this.updateMasterData().then(res=>{
                      this.props.exchange.reloadFirebaseUser(
                        ()=>{
                            this.setState({onLoad:false,onSave:false},()=>{

                                this.props.exchange.setModal(MODAL.MASTER_DATA_UPDATED)
                                if(this.props.onSaveSuccessCallback!=null){
                                    this.props.onSaveSuccessCallback()
                                }
                            })
                        }
                      );
                   });
                }
            });

    }

    saveChanges=async()=>{
        this.validInput();
    }

    discardChanges=()=>{
        let inputs = {
             company:JSON.parse(JSON.stringify(this.props.exchange.user.company))
            ,contact:JSON.parse(JSON.stringify(this.props.exchange.user.contact))
        }
        this.setState({inputs:inputs,errFields:'',errorMsg:''})
    }
  
    stage1ChangeInputHandler=(event)=>{
        let company = this.state.inputs.company;
        let contact = this.state.inputs.contact;
        let errFields = this.state.errFields;
        switch(event.target.id){
            case 'company-name-input':
                company.name = event.target.value;
                errFields=errFields.replace('companyName','');
            break;
            case 'company-streetnr-input':
                company.streetNr = event.target.value;
                errFields=errFields.replace('companyStreetNr','');
            break;
            case 'company-address-input':
                company.address = event.target.value;
                errFields=errFields.replace('companyAddress','');
            break;
            case 'company-zip-input':
                company.zip = event.target.value;
                errFields=errFields.replace('companyZip','');
            break;
            case 'company-city-input':
                company.city = event.target.value;
                errFields=errFields.replace('companyCity','');
            break;
            case 'company-phone-input':
                company.phone = event.target.value;
                errFields=errFields.replace('companyPhone','');
            break;


            case 'contact-firstname-input':
                contact.firstname = event.target.value;
                errFields=errFields.replace('contactFirstname','');
            break;
            case 'contact-lastname-input':
                contact.lastname = event.target.value;
                errFields=errFields.replace('contactLastname','');
            break;
            case 'contact-position-input':
                contact.position = event.target.value;
                errFields=errFields.replace('contactPosition','');
            break;
            case 'contact-phone-input':
                contact.phone = event.target.value;
                errFields=errFields.replace('contactPhone','');
            break;
            case 'contact-email-input':
                contact.email = event.target.value;
                errFields=errFields.replace('contactEmail','');
            break;

        } 
        let inputs = this.state.inputs;
        inputs.company = company;
        inputs.contact = contact;
        this.setState({inputs:inputs,errFields:errFields})
    }

    updateMasterData=async()=>{
        let firebaseUser = this.props.exchange.user;

        let user = JSON.parse(JSON.stringify(this.props.exchange.user)); 

        let dateNow = (new Date()).toISOString()

        if(JSON.stringify(this.state.inputs.contact)!=JSON.stringify(this.props.exchange.user.contact)){
            user.contact = this.state.inputs.contact;
            user.contact.lastUpdated = dateNow
        }
        if(JSON.stringify(this.state.inputs.company)!=JSON.stringify(this.props.exchange.user.company)){
            user.company = this.state.inputs.company;
            user.company.lastUpdated = dateNow
        }

        user.lastUpdated = dateNow
        
        let userPath = USERS_TABLE + '/' + firebaseUser.uuid
        const updates = {};
        updates[userPath] = user;

        return update(ref(db), updates);
    }
    render() {
        let isFullScreen = this.props.isFullScreen || false;
        let lang = this.props.exchange.language || 'de';
        let hasChanges =
           JSON.stringify(this.state.inputs.company) != JSON.stringify(this.props.exchange.user.company)
        || JSON.stringify(this.state.inputs.contact) != JSON.stringify(this.props.exchange.user.contact)

        let twoInputs =(id1,label1,errorId1,val1,  id2,label2,errorId2,val2)=>{
            return(
                <div style={{display:'flex'}}>
                    <div style={{width:'100%',marginRight:'18px'}}>
                        {id1!=null&&
                        <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{label1}</p>}
                        {id1!=null&&
                        <TextField id={id1} value={val1}  error={this.state.errFields.includes(errorId1)}
                            variant="outlined"  onChange={this.stage1ChangeInputHandler} 
                            inputProps={{style: {padding:'10px 14px'} }}
                            sx={{marginBottom:'12px',width:'100%'}}/>}
                    </div>

                    <div style={{width:'100%'}}>
                        <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{label2}</p>
                        <TextField id={id2} value={val2}  error={this.state.errFields.includes(errorId2)}
                            inputProps={{style: {padding:'10px 14px'} }}
                            variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                    </div>
                </div>
            )
        }

        return (
            <div className={this.props.isFullScreen?'master-data-page':'master-data-page'}>
                <div className='master-page-content' style={{alignItems:'center', justifyContent:'center',  width:'100%',pointerEvents:this.state.onLoad?'none':''}}>
                    <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>
                    <div className={isFullScreen?(isMobile ?'stage-1 page-box-1-mobile':'stage-1 page-box-1'):'stage-1'}>
                        <div className='company-inputs'>
                            <h2>{LABELS.PRACTICE_DETAILS[lang]}</h2>
                            <br/> 
                            <div style={{display:'flex'}}>
                                <div style={{width:'100%',marginRight:'18px'}}>
                                    <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{LABELS.COMPANY_NAME[lang]}</p>
                                    <TextField id="company-name-input" value={this.state.inputs.company.name} 
                            inputProps={{style: {padding:'10px 14px'} }}error={this.state.errFields.includes('companyName')}
                                        variant="outlined"  onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                                </div>

                                <div style={{width:'100%',overflow:'hidden'}}>
                                    <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{LABELS.LEGAL_FORM[lang]}</p>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            error={this.state.errFields.includes('companyLegalForm')}
                                            value={this.state.inputs.company.legalForm}
                                            onChange={(e)=>{
                                                let inputs = this.state.inputs;
                                                let company = inputs.company;
                                                company.legalForm = e.target.value;
                                                inputs.company = company;
                                                let errFields = this.state.errFields;
                                                errFields=errFields.replace('companyLegalForm','');
                                                this.setState({inputs:inputs, errFields:errFields})
                                            }}
                                        >
                                            <MenuItem value={'AG'}>AG</MenuItem>
                                            <MenuItem value={'GmbH'}>GmbH</MenuItem>
                                            <MenuItem value={'Einzelfirma'}>Einzelfirma</MenuItem>
                                            <MenuItem value={'Einfache Gesellschaft'}>Einfache Gesellschaft</MenuItem>
                                            <MenuItem value={'Andere'}>Andere</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {twoInputs("company-streetnr-input", LABELS.STREET_NR[lang], "companyStreetNr",  this.state.inputs.company.streetNr,
                                        "company-address-input", LABELS.ADDRESS[lang],   "companyAddress",  this.state.inputs.company.address)}

                            {twoInputs("company-zip-input",   LABELS.ZIP[lang],   "companyZip",  this.state.inputs.company.zip,
                                        "company-city-input", LABELS.CITY[lang],  "companyCity", this.state.inputs.company.city)}

                            {twoInputs(null, null,null,null,
                                        "company-phone-input", LABELS.DOCTOR_PHONE[lang],  "companyPhone", this.state.inputs.company.phone)}
                        </div>

                        <div className='contact-person-inputs'>
                            <h2>{LABELS.CONTACT_PERSON[lang]}</h2>
                            <br/> 

                            <div style={{display:'flex'}}>
                                <div style={{width:'100%',marginRight:'18px'}}>
                                    <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{LABELS.TITLE[lang]}</p>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.inputs.contact.title}
                                            error={this.state.errFields.includes('contactTitle')}
                                            inputProps={{style: {padding:'10px 14px'} }}
                                            onChange={(e)=>{
                                                let inputs = this.state.inputs;
                                                let contact = inputs.contact;
                                                contact.title = e.target.value;
                                                inputs.contact = contact;
                                                let errFields = this.state.errFields;
                                                errFields=errFields.replace('contactTitle','');
                                                this.setState({inputs:inputs, errFields:errFields})
                                            }}
                                        >
                                            <MenuItem value={'Frau'}>Frau</MenuItem>
                                            <MenuItem value={'Herr'}>Herr</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            
                                <div style={{width:'100%'}}>
                                    <p style={{marginBottom:'4px',fontSize:'0.8rem',color:'rgb(80,80,80)'}}>{LABELS.FIRSTNAME[lang]}</p>
                                    <TextField id="contact-firstname-input" value={this.state.inputs.contact.firstname}  error={this.state.errFields.includes('contactFirstname')}
                                        variant="outlined" 
                                            inputProps={{style: {padding:'10px 14px'} }} onChange={this.stage1ChangeInputHandler} sx={{marginBottom:'12px',width:'100%'}}/>
                                </div>
                            </div>

                            {twoInputs("contact-lastname-input", LABELS.LASTNAME[lang],  "contactLastname",  this.state.inputs.contact.lastname,
                                        "contact-position-input", LABELS.POSITION[lang], "contactPosition",  this.state.inputs.contact.position)}

                            {twoInputs("contact-phone-input", LABELS.TELEPHONE_QUERIES[lang],  "contactPhone",  this.state.inputs.contact.phone,
                                        "contact-email-input", LABELS.CONTACT_EMAIL[lang],     "contactEmail",  this.state.inputs.contact.email)}

                        </div>
                    
             
                        <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>
                        <br/>
                        <div className='action-div' style={{display:'flex',padding:'0.5em 0px'}}>
                            {(this.props.hasDiscard==null || this.props.hasDiscard) &&
                            <button 
                                className='btn1 btn1-second'
                                style={{marginRight:'4px'}}
                                onClick={this.discardChanges}
                                >
                              {LABELS.DISCARD_CHANGES[lang]}
                            </button>}
                            <button 
                                className={'btn1' +(hasChanges?'':' btn1-disabled') }
                                style={{marginLeft:'4px'}}
                                onClick={this.saveChanges}
                                tabIndex={-1}
                                >
                                  {this.state.onSave?
                                    <CircularProgress/>:LABELS.SAVE[lang]}
                            </button>
                        </div>
                    </div>
                    
                </div>
                <br/>
            </div>
        );
    }
}

export default MasterDataPage;